import {Component, OnInit, ViewChild} from '@angular/core';
import {Util} from '../../_utils/util';
import {Loader} from '../../_models/loader';
import {Vendor} from '../../_models/vendor';
import {AuthService} from '../../_services/auth.service';
import {MatPaginator, MatSort, MatTableDataSource, Sort} from '@angular/material';
import {Router} from '@angular/router';
import {InsuranceCommissionService} from '../../_services/insurance-commission-service';
import {InsuranceCommission} from '../../_models/insuranceCommission';

@Component({
	selector: 'app-vendor-edit',
	templateUrl: './insurance-commission.component.html',
	styleUrls: ['./insurance-commission.scss'],
})

export class InsuranceCommissionComponent implements OnInit {
	noFilter = 'All';
	private _insurance: InsuranceCommission[];
	public loader: Loader;
	public errorMsg: string;
	public isAdmin: boolean;
	tableData;
	displayedHeader: string[] = ['Insurance Provider', 'Effective Comission Rate', 'Action'];
	displayedColumns: string[] = ['name', 'commission_rate', 'action'];
	@ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
	@ViewChild(MatSort, {static: false}) sort: MatSort;


	constructor(private _insuranceService: InsuranceCommissionService, private _util: Util, private authService: AuthService, private router: Router) {
		this.isAdmin = false;
	}

	ngOnInit() {
		this.isAdmin = this.authService.isAdmin();
		this.loader = new Loader();
		this.getAllInsurance();
	}

	public getAllInsurance(): void {
		this.loader.isLoading = true;
		this._insuranceService.getAll()
			.subscribe(
				res => {
					this._insurance = res;
					this.tableData = new MatTableDataSource(this._insurance);
					this.loader.isLoading = false;
				},
				err => {
					this.errorMsg = err;
					this.loader.isLoading = false;
				});
	}

	public edit(v: InsuranceCommission) {
		this.router.navigate([`/admin/insuranceAdjustment/edit/${v.id}`]);
	}

	sortData(sort: Sort) {
		const data = this._insurance.slice();
		if (!sort.active || sort.direction == '') {
			this.tableData = data;
			return;
		}

		this.tableData = data.sort((a, b) => {
			let isAsc = sort.direction == 'asc';
			switch (sort.active) {
				case 'name':
					return this.compare(a.name, b.name, isAsc);
				case 'commission_rate':
					return this.compare(+a.commission_rate, +b.commission_rate, isAsc);
				default:
					return 0;
			}
		});
	}


	compare(a, b, isAsc) {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}
}
