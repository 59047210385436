import {Component, OnInit} from '@angular/core';
import {AdPlacementService} from '../../_services/ad-placement-service';
import {Util} from '../../_utils/util';
import {Loader} from '../../_models/loader';
import {AuthService} from '../../_services/auth.service';

@Component({
	selector: 'app-ad-placement-tool',
	templateUrl: './ad-placement-tool.component.html',
	styleUrls: ['./ad-placement-tool.component.scss'],
})

export class AdPlacementComponent implements OnInit {
	public state: any;
	public loader: Loader;
	public errorMsg: string;
	public adPlacements: any;
	isAdmin: boolean;
	public visible = true;

	// mapping display name/business name to db prop
	public adPlacementMap = [
		{name: 'Id', prop: 'id', checkbox: false},
		{name: 'Name', prop: 'name', checkbox: false},
		{name: 'Type', prop: 'type', checkbox: false},
		{name: 'Page', prop: 'page', checkbox: false},
		{name: 'Mobile', prop: 'is_mobile', checkbox: true},
		{name: 'Provider', prop: 'provider', checkbox: false},
	];

	constructor(private _adPlacementService: AdPlacementService, private _util: Util, private authService: AuthService) {
	}

	ngOnInit() {
		this.isAdmin = this.authService.isAdmin();
		this.state = {};
		this.loader = new Loader();
		this.getAllPlacements();
		this.errorMsg = '';
	}

	getAllPlacements(): void {
		this.loader.isLoading = true;
		this._adPlacementService.getAll()
			.subscribe(
				res => {
					console.log('res', res);
					this.adPlacements = res.map(i => this.processPlacements(i));
					this.loader.isLoading = false;
				},
				err => {
					this.errorMsg = err;
					this.loader.isLoading = false;
				});
	}

	toggleActive(p: any, active: boolean): void {
		if (this.isAdmin) {
			p.active = active;
		} else {
			window.alert('Only Admin users can make changes');
		}
	}

	updatePlacement(p): void {
		const placement = {
			id: p.id,
			active: p.active,
			cpc: p.cpc
		};
		if (!isNaN(parseFloat(placement.cpc)) && isFinite(placement.cpc)) {
			this._adPlacementService.updatePlacement(placement)
				.subscribe(
					res => {
						this.ngOnInit();
					},
					err => {
						this.errorMsg = err;
						this.loader.isLoading = false;
					});
		} else {
			this.errorMsg = 'Please enter numbers and decimal only for CPC';
		}
	}

	processPlacements(p): any {
		let placement;
		placement = {
			id: p.id,
			active: p.active,
			name: p.name,
			type: p.type.name,
			page: p.type.page_name,
			is_mobile: p.type.mobile,
			provider: p.provider.name,
			cpc: p.cpc_cost,
		};

		return placement;
	}
}
