import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from './app-config.service';
import {MarketingLandingPage} from '../_models/marketingLandingPage';

@Injectable()
export class MarketingLandingPageService {
	private baseUrl: string;
	private baseTemplateUrl: string;
	private refreshCacheUrl: string;

	constructor(private http: HttpClient, private authService: AuthService, private environment: AppConfigService) {
		this.baseUrl = environment.config.apiBaseUrl + '/api/v1/seo_landing_pages';
		this.baseTemplateUrl = environment.config.apiBaseUrl + '/api/v1/seo_landing_page_templates';
	}

	getAll(): Observable<any> {
		return this.http.get<any>(`${this.baseUrl}?page=0&size=1000`, this.authService.getOptions());
	}

	getAllTemplates(): Observable<any> {
		return this.http.get<any>(`${this.baseTemplateUrl}`, this.authService.getOptions());
	}

	updateMarketingLandingPage(marketingLandingPage: MarketingLandingPage): Observable<MarketingLandingPage> {
		return this.http.put<MarketingLandingPage>(`${this.baseUrl}/${marketingLandingPage.id}`, JSON.stringify(marketingLandingPage),
			this.authService.getOptions());
	}

	createMarketingLandingPage(marketingLandingPage: MarketingLandingPage): Observable<MarketingLandingPage> {
		return this.http.post<MarketingLandingPage>(`${this.baseUrl}`, JSON.stringify(marketingLandingPage),
			this.authService.getOptions());
	}

	getMarketingLandingPageById(id: number) {
		return this.http.get<any>(`${this.baseUrl}/${id}`, this.authService.getOptions());
	}

	publishChanges(marketingLandingPage: MarketingLandingPage): Observable<MarketingLandingPage> {
		return this.http.put<MarketingLandingPage>(`${this.baseUrl}/sync/${marketingLandingPage.id}`, JSON.stringify(marketingLandingPage),
			this.authService.getOptions());
	}

	discardChanges(marketingLandingPage: MarketingLandingPage): Observable<MarketingLandingPage> {
		return this.http.put<MarketingLandingPage>(`${this.baseUrl}/discard/${marketingLandingPage.id}`, JSON.stringify(marketingLandingPage),
			this.authService.getOptions());
	}

	activatePage(marketingLandingPage: MarketingLandingPage): Observable<MarketingLandingPage> {
		return this.http.put<MarketingLandingPage>(`${this.baseUrl}/activate/${marketingLandingPage.id}`, JSON.stringify(marketingLandingPage),
			this.authService.getOptions());
	}

	deactivatePage(marketingLandingPage: MarketingLandingPage): Observable<MarketingLandingPage> {
		return this.http.put<MarketingLandingPage>(`${this.baseUrl}/deactivate/${marketingLandingPage.id}`, JSON.stringify(marketingLandingPage),
			this.authService.getOptions());
	}
}
