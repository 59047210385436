import {VendorXml} from '../_models/vendorXml';
import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AppConfigService} from './app-config.service';

@Injectable()
export class SessionDebugService {
	private token = this.authService.getToken();

	private baseUrl: string;

	constructor(private http: HttpClient, private authService: AuthService, private environment: AppConfigService) {
		this.baseUrl = environment.config.apiBaseUrl + '/api/session_debug';
	}

	getVendorXmls(sessionId: string): Observable<VendorXml[]> {
		return this.http.get<VendorXml[]>(`${this.baseUrl}/vendor_xmls/${sessionId}`, this.authService.getOptions());
	}

	getSingleVendorRequest(xmlId: number, datasource: string): Observable<any> {
		return this.http.get(`${this.baseUrl}/vendor_xml/${xmlId}?datasource=${datasource}`, this.authService.getOptions());
	}

	getSingleVendorResponse(xmlId: number, datasource: string) {
		return this.http.get(`${this.baseUrl}/vendor_response/${xmlId}?datasource=${datasource}`, {
			headers:
				new HttpHeaders({'content-type': 'application/json', 'Authorization': this.token}), responseType: 'text'
		});
	}

	getSessionResponse(sessionId: string) {
		return this.http.get<any>(`${this.baseUrl}/${sessionId}`, this.authService.getOptions());
	}

	getSessionDetailsFromJsessionId(jsessionId: string) {
		return this.http.get<any>(`${this.baseUrl}/jsessionId/${jsessionId}`, this.authService.getOptions());
	}
}

