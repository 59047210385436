import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SeoFaqService} from '../../_services/seo-faq-service';

@Component({
	selector: 'app-seo-faq-tab',
	templateUrl: './seo-tool-faq-tab.component.html',
	styleUrls: ['./seo-tool-faq-tab.component.scss']
})
export class SeoToolFaqTabComponent implements OnInit {

	@Input() locationId: any;
	@Input() locationFaqs: any[];
	@Input() faqElements: any;
	@Input() showTab: boolean;
	@Input() location: any;
	@Input() popUpShowed: any;
	@Output() faqUnsavedChanges: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() faqEditMode: EventEmitter<boolean> = new EventEmitter<boolean>();
	showHide: boolean;
	hasChanges: boolean;
	faq: any;
	faqCreated: any;
	isCreate: boolean;
	faqService: SeoFaqService;

	constructor(faqService: SeoFaqService) {
		this.showHide = false;
		this.isCreate = false;
		this.faqService = faqService;
	}

	ngOnInit() {
		this.faq = {};
		this.faqCreated = {};
	}

	showFaqAnswer(faq: any) {
		faq.showAnswer = !faq.showAnswer;
	}

	showEditableTabs(faq: any) {
		faq.canEdit = true;
		this.faqEditMode.emit(faq.canEdit);
	}

	faqUnsaved(faq: any) {
		this.faqUnsavedChanges.emit(faq.canEdit);
		this.hasChanges = true;
		this.faq.isChanged = true;
	}

	createFaq() {
		this.isCreate = true;
	}

	saveNewFaq() {
		this.faqCreated.location_id = this.locationId;
		this.faqService.createFaq(this.faqCreated).subscribe(
			res => {
				this.locationFaqs.push(res);
				this.faqCreated = {};
			}
		)
		this.isCreate = false;
	}

	cancelNewFaq() {
		this.isCreate = false;
		this.faqCreated = {};
	}

	deleteFaq(faq: any) {
		this.faqService.deleteFaq(faq).subscribe(
			res => {
				this.faqService.getLocationFaqsBylocationId(this.locationId).subscribe(res => {
					this.locationFaqs = res.content;
					this.faqElements = res.total_elements;
				})
			}
		)
	}
}

