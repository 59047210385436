import {Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {Loader} from '../../_models/loader';
import {Subscription} from 'rxjs/Rx';
import {MatSnackBar} from '@angular/material';
import {InsuranceCommission} from '../../_models/insuranceCommission';
import {InsuranceCommissionService} from '../../_services/insurance-commission-service';

@Component({
	selector: 'app-insurance-commission-edit',
	templateUrl: './insurance-commission-edit.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class InsuranceCommissionEditComponent implements OnInit {

	errorMsg: string;
	insuranceCommissionForm: FormGroup;
	hasFormErrors = false;
	loader: Loader;
	id: string;
	insuranceCommission: InsuranceCommission;
	formReady: Boolean = false;
	backlink = '/admin/insuranceAdjustment';
	inputsFields = [
		{prop: 'name', label: 'Insurance Provider'},
		{prop: 'commission_rate', label: 'Effective Commission Rate'}
	];

	constructor(private insuranceService: InsuranceCommissionService,
				private fb: FormBuilder,
				private cdRef: ChangeDetectorRef,
				public snackBar: MatSnackBar,
				private route: ActivatedRoute) {
	}

	ngOnInit() {
		this.loader = new Loader();
		this.id = this.route.snapshot.paramMap.get('id');
		this.loader.isLoading = true;
		this.fetchDetail();
	}

	fetchDetail(): Subscription {
		return this.insuranceService.getInsuranceById(this.id).subscribe({
			next: res => {
				this.loader.isLoading = false;
				this.insuranceCommission = res;
				this.createForm();
				this.cdRef.detectChanges();
			}, error: err => {
				this.loader.isLoading = false;
				this.openSnackBar('unknown server error occured', 'error');
				console.log('err ', err);
			}
		});
	}

	createForm() {
		this.insuranceCommissionForm = this.fb.group({
			name: [this.insuranceCommission.name],
			commission_rate: [this.insuranceCommission.commission_rate, Validators.required]
		});
		this.formReady = true;
	}

	onSubmit() {
		this.hasFormErrors = false;
		const controls = this.insuranceCommissionForm.controls;

		if (this.insuranceCommissionForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			this.hasFormErrors = true;
			return;
		}
		const v = this.prepareForm();

		this.insuranceService.update(v).subscribe({
			next: res => {
				this.insuranceCommission = res;
				this.openSnackBar('Updated', 'success');
			}, error: err => {
				this.openSnackBar('Server Error', 'error');
				console.log('test', err);
			}
		});
	}

	prepareForm(): InsuranceCommission {
		const controls = this.insuranceCommissionForm.controls;
		this.inputsFields.forEach(i => {
			this.insuranceCommission[i.prop] = controls[i.prop].value
		});
		this.insuranceCommission['commission_rate'] = controls['commission_rate'].value;
		return this.insuranceCommission;
	}

	openSnackBar(message: string, type) {
		this.snackBar.open(message, type, {
			duration: 10000,
		});
	}

}
