import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../_services/auth.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	model: any = {};
	loading = false;
	returnUrl: string;

	constructor(private authService: AuthService,
				private router: Router,
				private route: ActivatedRoute) {
	}

	ngOnInit() {
		this.authService.logoutUser();
		this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
	}

	login() {
		this.loading = true;
		this.authService.loginUser(this.model.username, this.model.password)
			.subscribe(
				data => {
					this.router.navigate([this.returnUrl]);
				},
				error => {
					// this.alertService.error(error);
					this.loading = false;
				});
	}
}
