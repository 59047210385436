import {Component, Input, OnInit} from '@angular/core';
import {CampaignService} from '../../../_services/campaign-service';
import {Campaign} from '../../../_models/campaign';

@Component({
	selector: 'app-expense-adjustment-history',
	templateUrl: './expense-adjustment-history.component.html',
	styleUrls: ['./expense-adjustment-history.component.scss']
})

export class ExpenseAdjustmentHistoryComponent implements OnInit {
	_campaignId: number;
	campaignLogs: any[];
	errorMsg: string;

	@Input()
	set campaignId(value: number) {
		this._campaignId = value;
		this.campaignService.getExpenseAdjustments(value).subscribe(
			res => {
				this.campaignLogs = res.reverse();
			},
			err => {
				console.log('error ', err);
				this.errorMsg = 'No Expense History Found';
			})
	}

	get campaignId(): number {
		return this._campaignId;
	}

	constructor(private campaignService: CampaignService) {
	}

	ngOnInit() {
		this.errorMsg = '';
	}
}
