import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';
import {Brand} from '../_models/brand';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from './app-config.service';

@Injectable()
export class BrandService {
	private baseUrl: string;
	private refreshCacheUrl: string;

	constructor(private http: HttpClient, private authService: AuthService, private environment: AppConfigService) {
		this.baseUrl = environment.config.apiBaseUrl + '/api/v1/brands';
		this.refreshCacheUrl = environment.config.apiBaseUrl + '/api/cache/reload';
	}

	getAll(): Observable<any> {
		return this.http.get(`${this.baseUrl}?size=1000`, this.authService.getOptions()).pipe(
			map(res => res)
		);
	}

	updateBrand(brand: Brand): Observable<Brand> {
		var link = this.baseUrl + '/' + brand.id;
		return this.http.put<any>(link, JSON.stringify(brand), this.authService.getOptions()).pipe(
			map(res => res));
	}

	restartServerCache(): any {
		return this.http.get<any>(this.refreshCacheUrl, this.authService.getOptions()).pipe(
			map(res => res));
	}

	getAllBrandCpc(): Observable<any> {
		return this.http.get(`${this.environment.config.apiBaseUrl}/api/v1/brand_base_cpc`, this.authService.getOptions()).pipe(
			map(res => res));
	}

	getBrandCpcById(id: string): Observable<any> {
		return this.http.get(`${this.environment.config.apiBaseUrl}/api/v1/brand_base_cpc/${id}`, this.authService.getOptions()).pipe(
			map(res => res));
	}

	updateBrandBaseCpc(brandCpc, id): Observable<Brand> {
		// return this.http.put(`${brand._links.self.href}`, JSON.stringify(brand), this.getOptions())
		return this.http.put<Brand>(`${this.environment.config.apiBaseUrl}/api/v1/brand_base_cpc/${id}`,
			JSON.stringify(brandCpc),
			this.authService.getOptions()).pipe(map(res => res));
	}

	getLocationBatches(): Observable<any> {
		return this.http.get<any>(`${this.environment.config.apiBaseUrl}/api/location/update/unprocessed_batches`, this.authService.getOptions()).pipe(
			map(res => res));
	}

	updateLocation(location): Observable<any> {
		return this.http.get<any>(
			`${this.environment.config.apiBaseUrl}/api/location/update/run/phase_two/${location.vendor_id}/${location.brand_id}/${location.batch_id}`,
			this.authService.getOptions()).pipe(map(res => res));
	}

	getBrandById(id: number) {
		return this.http.get<any>(`${this.baseUrl}/${id}`, this.authService.getOptions()).pipe(
			map(res => res));
	}
}

