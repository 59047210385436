import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';
import {AppConfigService} from './app-config.service';

@Injectable()
export class KlaviyoService {
	private baseUrl: string;

	constructor(private http: HttpClient, private authService: AuthService, private environment: AppConfigService) {
		this.baseUrl = environment.config.apiBaseUrl + '/api/v1/datafeeds_json_data';
	}

	getAll(): Observable<any[]> {
		return this.http.get(`${this.baseUrl}?size=1000`, this.authService.getOptions()).pipe(
			map(res => (<any>res))
		);
	}

	createKlaviyo(data: any) {
		return this.http.post(this.baseUrl, JSON.stringify(data), this.authService.getOptions())
			.pipe(res => res);
	}

	updateDataFeed(data: any, id: string) {
		return this.http.put(`${this.baseUrl}/${id}`, JSON.stringify(data), this.authService.getOptions())
			.pipe(res => res);
	}
}
