import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from './app-config.service';

@Injectable()
export class MarketingGroupService {
	private baseUrl: string;
	private refreshCacheUrl: string;

	constructor(private http: HttpClient, private authService: AuthService, private environment: AppConfigService) {
		this.baseUrl = environment.config.apiBaseUrl + '/api/v1/marketing-group';
		this.refreshCacheUrl = environment.config.apiBaseUrl + '/api/cache/reload';
	}

	getAll() {
		return this.http.get<any>(this.baseUrl, this.authService.getOptions());
	}
}
