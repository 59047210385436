import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthService} from '../../_services/auth.service';
import {AppConfigService} from '../../_services/app-config.service';

@Component({
	selector: 'app-brand-onboarding-service',
	templateUrl: './brand-onboarding-request.component.html',
	styleUrls: ['./brand-onboarding-request.component.scss'],
})

export class BrandOnboardingRequestComponent implements OnInit {

	private token = this.authService.getToken();
	form: FormGroup;
	brandName: FormControl = new FormControl(null);
	brandShortName: FormControl = new FormControl(null);
	supplierMap: {[id: number]: string};
	suppliers: string[];
	phoneNumber: FormControl = new FormControl(null);
	supplier: FormControl = new FormControl(null);
	apiProvider: FormControl = new FormControl(null);
	revenueType: FormControl = new FormControl(null);
	locationBasedSearch: FormControl = new FormControl(false);
	automaticLocationUpdate: FormControl = new FormControl(false);
	commissionRate: FormControl = new FormControl(null);
	bookingFee: FormControl = new FormControl(null);
	payOnEverything: FormControl = new FormControl(false);
	isExternalRedirect: FormControl = new FormControl(false);
	memberRatesAvailable: FormControl = new FormControl(false);
	insInclRatesAvailable = new FormControl(false);
	isOTA: FormControl = new FormControl(false);
	cpcDesktop: FormControl = new FormControl(null);
	cpcMobile: FormControl = new FormControl(null);
	notes: FormControl = new FormControl(null);
	businessRules: FormControl = new FormControl(null);
	logo: FormControl = new FormControl(null, Validators.required);
	brandShortNames: string[]; // Use actual values
	revenueTypes = ['BOOKING', 'NET_RATE_MARKUP', 'OPV_CPC']; // Use actual values
	selectedFiles: File[] = [];
	apiProviderMap: {[key: string]: string};
	apiProviders: string[];
	public jiraIssueUrl = '';
	public responseMessage = '';
	isLogoRequired = false;
	public loading = false;


	constructor(private http: HttpClient, private authService: AuthService, private environment: AppConfigService) {}

	ngOnInit() {
		this.form = new FormGroup({
			brandName: this.brandName,
			logo: this.logo,
			brandShortName: this.brandShortName,
			apiProvider: this.apiProvider,
			phoneNumber: this.phoneNumber,
			supplier: this.supplier,
			revenueType: this.revenueType,
			locationBasedSearch: this.locationBasedSearch,
			autoLocationUpdate: this.automaticLocationUpdate,
			commissionRate: this.commissionRate,
			bookingFee: this.bookingFee,
			payOnEverything: this.payOnEverything,
			isExternalRedirect: this.isExternalRedirect,
			memberRatesAvailable: this.memberRatesAvailable,
			insuranceInclusiveRates: this.insInclRatesAvailable,
			isOTA: this.isOTA,
			cpcDesktop: this.cpcDesktop,
			cpcMobile: this.cpcMobile,
			otherNotes: this.notes,
			businessRules: this.businessRules
		});

		this.form.get('revenueType').valueChanges.subscribe(val => {
			if (val === 'OPV_CPC') {
				this.form.patchValue({
					commissionRate: null,
					bookingFee: null
				});
			} else {
				this.form.patchValue({
					cpcDesktop: null,
					cpcMobile: null
				});
			}
		})

		this.apiProvider.setValue(null);
		this.brandShortName.setValue(null);

		this.http.get(`${this.environment.config.apiBaseUrl}/api/v1/brands/short-name`, {
			headers: new HttpHeaders({'Authorization': this.token})
		}).subscribe((response: any) => {
			this.brandShortNames = response.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
		});

		this.http.get(`${this.environment.config.apiBaseUrl}/api/v1/api-providers`, {
			headers: new HttpHeaders({'Authorization': this.token})
		}).subscribe((response: any) => {
			this.apiProviderMap = response;
			this.apiProviders = Object.keys(response).map(key => response[key]).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
		});

		this.http.get(`${this.environment.config.apiBaseUrl}/api/v1/supplier`, {
			headers: new HttpHeaders({'Authorization': this.token})
		}).subscribe((response: any[]) => {
			this.supplierMap = response.reduce((map, obj) => ({...map, [obj.id]: obj.supplier_name}), {});
			this.suppliers = Object.keys(this.supplierMap).map(key => this.supplierMap[key])
			.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
		});
	}

	getApiProviderIdFromName(name: string): string {
		// Find the entry in the apiProviderMap where the value equals the given name, and return the key
		for (const id in this.apiProviderMap) {
			if (this.apiProviderMap[id] === name) {
				return id;
			}
		}
		return null;
	}
	getSupplierIdFromName(name: string): number {
		return parseInt(Object.keys(this.supplierMap).find(key => this.supplierMap[key] === name), 10);
	}

	submitForm() {

		this.loading = true;

		if (!this.logo) {
			this.isLogoRequired = true;
			this.loading = false;
			return;
		}

		const formData = new FormData();

		// Attach body json payload
		formData.append('body', new Blob([JSON.stringify({
			'brand_name': this.brandName.value,
			'brand_short_name': this.brandShortName.value,
			'api_provider_id': parseInt(this.getApiProviderIdFromName(this.apiProvider.value), 10),
			'phone_number': this.phoneNumber.value,
			'supplier_id': parseInt(this.supplier.value, 10),
			'revenue_type': this.revenueType.value,
			'location_based_search': this.locationBasedSearch.value,
			'automatic_location_update': this.automaticLocationUpdate.value,
			'commission_rate': this.commissionRate.value,
			'booking_fee': this.bookingFee.value,
			'pay_on_everything': this.payOnEverything.value,
			'is_external_redirect': this.isExternalRedirect.value,
			'member_rates_available': this.memberRatesAvailable.value,
			'ins_incl_rates_available': this.insInclRatesAvailable.value,
			'is_ota': this.isOTA.value,
			'cpc_desktop': this.cpcDesktop.value,
			'cpc_mobile': this.cpcMobile.value,
			'notes': this.notes.value,
			'business_rules': this.businessRules.value,
		})], {
			type: 'application/json'
		}));

		// Attach documentation files
		this.selectedFiles.forEach((file, index) => {
			formData.append('doc', file, file.name);
		});

		// Attach logo
		if (this.logo) {
			formData.append('logo', this.logo.value, this.logo.value.name);
		}

		this.http.post<any>(`${this.environment.config.apiBaseUrl}/api/v1/onboarding-request`, formData, {
			headers: new HttpHeaders({'Authorization': this.token})
		}).subscribe(response => {
			this.jiraIssueUrl = 'https://arentals.atlassian.net/browse/' + response.jira_issue_key;
			this.responseMessage = 'Form submitted successfully, jira issue created: ';
			this.loading = false;
		}, error => {
			if (error.status !== 500 && error.status !== 400) {
				this.responseMessage = error.message
			} else if (error.status === 400) {
				let errorMsg = 'Validation failed: ';
				for (const key in error.error) {
					if (error.error.hasOwnProperty(key)) {
						errorMsg += `${key}: ${error.error[key]}, `;
					}
				}
				errorMsg = errorMsg.slice(0, -2);
				this.responseMessage = errorMsg;
			} else {
				this.responseMessage = 'Could not submit the form: ' + error.error;
			}
			this.loading = false;
		});
	}

	onDocumentationFileSelect(event) {
		if (event.target.files.length > 0) {
			this.selectedFiles = Array.from(event.target.files);
			console.log(this.selectedFiles);
		}
	}

	onLogoFileSelect(event) {
		if (event.target.files.length > 0) {
			this.logo.setValue(event.target.files[0]);
			this.isLogoRequired = false;
		}
	}

	get isRevenueTypeCPC(): boolean {
		// if 'revenueType' is the variable that holds the revenue type
		return this.revenueType.value === 'OPV_CPC';
	}

	closeEdit() {
		// TODO: close edit operation
	}

	clearMessage() {
		this.responseMessage = '';
		this.jiraIssueUrl = '';
	}



}
