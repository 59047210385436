import {Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Loader} from '../../_models/loader';
import {Subscription} from 'rxjs/Rx';
import {MatSnackBar} from '@angular/material';
import {MarketingLandingPageService} from '../../_services/seo-landing-page-service';
import {MarketingLandingPage} from '../../_models/marketingLandingPage';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {DomSanitizer} from '@angular/platform-browser';
import {AppConfigService} from '../../_services/app-config.service';

@Component({
	selector: 'app-seo-landing-page-detail-edit',
	templateUrl: './marketing-landing-page-details-edit.component.html',
	styleUrls: ['./marketing-landing-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class MarketingLandingPageDetailsEditComponent implements OnInit {

	errorMsg: string;
	marketingLandingPageForm: FormGroup;
	hasFormErrors = false;
	loader: Loader;
	id: number;
	marketingLandingPage: MarketingLandingPage;
	templates: any[];
	url = '';
	baseUrl = 'https://www.autorentals.com';
	urlSafe: any;
	formReady: Boolean = false;
	backlink = '/admin/landingPage';
	hasChanges: Boolean = false;
	config: AngularEditorConfig = {
		editable: true,
		spellcheck: true,
		height: 'auto',
		minHeight: '300px',
		placeholder: 'Free form text block',
		translate: 'no',
		defaultParagraphSeparator: 'p',
		defaultFontName: 'Roboto',
		fonts: [
			{class: 'roboto', name: 'Roboto'},
			{class: 'open-sans', name: 'Open Sans'},
			{class: 'montserrat', name: 'Montserrat'},
		],
		toolbarHiddenButtons: [
			['subscript', 'superscript'],
			['backgroundColor',
				'customClasses',
				'link',
				'unlink',
				'insertImage',
				'insertVideo',
				'insertHorizontalRule',
				'removeFormat']
		]
	};


	constructor(private marketingLandingPageService: MarketingLandingPageService,
				private fb: FormBuilder,
				private cdRef: ChangeDetectorRef,
				public snackBar: MatSnackBar,
				private route: ActivatedRoute,
				private router: Router,
				public sanitizer: DomSanitizer,
				private environment: AppConfigService) {
		this.baseUrl = environment.config.arApiBaseUrl;
	}

	ngOnInit() {
		this.loader = new Loader();
		this.id = parseInt(this.route.snapshot.paramMap.get('id'));
		this.loader.isLoading = true;
		this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
		this.getAllMarketingLandingPageTemplates();
		this.fetchDetail();
	}

	public getAllMarketingLandingPageTemplates(): void {
		this.loader.isLoading = true;
		this.marketingLandingPageService.getAllTemplates()
			.subscribe(
				res => {
					this.templates = res;
					this.loader.isLoading = false;
				},
				err => {
					this.errorMsg = err;
					this.loader.isLoading = false;
				});
	}

	fetchDetail(): Subscription {
		return this.marketingLandingPageService.getMarketingLandingPageById(this.id).subscribe({
			next: res => {
				this.loader.isLoading = false;
				this.marketingLandingPage = res;
				this.checkChanges(this.marketingLandingPage);
				this.buildPreviewUrl(this.marketingLandingPage);
				this.createForm();
				this.cdRef.detectChanges();
			}, error: err => {
				this.loader.isLoading = false;
				this.openSnackBar('unknown server error occured', 'error');
				console.log('err ', err);
			}
		});
	}

	createForm() {
		this.marketingLandingPageForm = this.fb.group({
			id: [this.marketingLandingPage.id, Validators.required],
			path: [this.marketingLandingPage.path, Validators.required],
			template_name: [this.marketingLandingPage.template_name, Validators.required],
			searchFormHeadlineText: [this.marketingLandingPage.preview_data.searchFormHeadlineText],
			searchFormSubHeadlineText: [this.marketingLandingPage.preview_data.searchFormSubHeadlineText],
			headerText: [this.marketingLandingPage.preview_data.headerText],
			subHeaderText: [this.marketingLandingPage.preview_data.subHeaderText],
			freeFormText: [this.marketingLandingPage.preview_data.freeFormText],
			backgroundImageUrl: [this.marketingLandingPage.preview_data.backgroundImageUrl],
			additionalImageUrl: [this.marketingLandingPage.preview_data.additionalImageUrl],
			testimonial3Review: [this.marketingLandingPage.preview_data.testimonial3Review],
			pageTitle: [this.marketingLandingPage.preview_data.pageTitle],
			metaDescription: [this.marketingLandingPage.preview_data.metaDescription],
			metaKeywords: [this.marketingLandingPage.preview_data.metaKeywords],
			noIndex: [this.marketingLandingPage.preview_data.noIndex],
		});
		this.formReady = true;
	}

	onSubmit() {
		this.hasFormErrors = false;
		const controls = this.marketingLandingPageForm.controls;

		if (this.marketingLandingPageForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			this.hasFormErrors = true;
			return;
		}
		const v = this.prepareForm();

		this.marketingLandingPageService.updateMarketingLandingPage(v).subscribe({
			next: res => {
				this.marketingLandingPage = res;
				this.checkChanges(this.marketingLandingPage);
				this.openSnackBar('Updated', 'success');
				window.location.reload();
			}, error: err => {
				this.openSnackBar('Server Error', 'error');
				console.log('test', err);
			}
		});
	}

	prepareForm(): MarketingLandingPage {
		const controls = this.marketingLandingPageForm.controls;

		const data: any = {};
		data.searchFormHeadlineText = controls['searchFormHeadlineText'].value;
		data.searchFormSubHeadlineText = controls['searchFormSubHeadlineText'].value;
		data.headerText = controls['headerText'].value;
		data.subHeaderText = controls['subHeaderText'].value;
		data.freeFormText = controls['freeFormText'].value;
		data.additionalImageUrl = controls['additionalImageUrl'].value;
		data.backgroundImageUrl = controls['backgroundImageUrl'].value;

		data.pageTitle = controls['pageTitle'].value;
		data.metaDescription = controls['metaDescription'].value;
		data.metaKeywords = controls['metaKeywords'].value;
		data.noIndex = controls['noIndex'].value;

		this.marketingLandingPage['template_name'] = controls['template_name'].value;
		this.marketingLandingPage['preview_data'] = data;
		return this.marketingLandingPage;
	}

	openSnackBar(message: string, type) {
		this.snackBar.open(message, type, {
			duration: 10000,
		});
	}

	checkChanges(marketingLandingPage: MarketingLandingPage) {
		this.hasChanges = true;

		if (marketingLandingPage.data != null && marketingLandingPage.preview_data != null &&
			marketingLandingPage.preview_data.searchFormHeadlineText == marketingLandingPage.data.searchFormHeadlineText &&
			marketingLandingPage.preview_data.searchFormSubHeadlineText == marketingLandingPage.data.searchFormSubHeadlineText &&
			marketingLandingPage.preview_data.headerText == marketingLandingPage.data.headerText &&
			marketingLandingPage.preview_data.subHeaderText == marketingLandingPage.data.subHeaderText &&
			marketingLandingPage.preview_data.freeFormText == marketingLandingPage.data.freeFormText &&
			marketingLandingPage.preview_data.backgroundImageUrl == marketingLandingPage.data.backgroundImageUrl &&
			marketingLandingPage.preview_data.additionalImageUrl == marketingLandingPage.data.additionalImageUrl &&
			marketingLandingPage.preview_data.pageTitle == marketingLandingPage.data.pageTitle &&
			marketingLandingPage.preview_data.metaDescription == marketingLandingPage.data.metaDescription &&
			marketingLandingPage.preview_data.metaKeywords == marketingLandingPage.data.metaKeywords &&
			marketingLandingPage.preview_data.noIndex == marketingLandingPage.data.noIndex
		) {
			this.hasChanges = false;
		} else {
			this.hasChanges = true;
		}
	}

	publishChanges() {
		this.marketingLandingPageService.publishChanges(this.marketingLandingPage).subscribe({
			next: res => {
				this.marketingLandingPage = res;
				this.checkChanges(this.marketingLandingPage);
				this.openSnackBar('Changes Published', 'success');
				this.backToSeoLandingList();
			}, error: err => {
				this.openSnackBar('Server Error', 'error');
				console.log('test', err);
			}
		});
	}

	activatePage() {
		this.marketingLandingPageService.activatePage(this.marketingLandingPage).subscribe({
			next: res => {
				this.marketingLandingPage = res;
				this.checkChanges(this.marketingLandingPage);
				this.openSnackBar('Page Activated', 'success');
				this.buildPreviewUrl(this.marketingLandingPage);
			}, error: err => {
				this.openSnackBar('Server Error', 'error');
				console.log('test', err);
			}
		});
	}

	deactivatePage() {
		this.marketingLandingPageService.deactivatePage(this.marketingLandingPage).subscribe({
			next: res => {
				this.marketingLandingPage = res;
				this.checkChanges(this.marketingLandingPage);
				this.openSnackBar('Page deactivated', 'success');
				this.buildPreviewUrl(this.marketingLandingPage);
			}, error: err => {
				this.openSnackBar('Server Error', 'error');
				console.log('test', err);
			}
		});
	}

	discardChanges() {
		this.marketingLandingPageService.discardChanges(this.marketingLandingPage).subscribe({
			next: res => {
				this.marketingLandingPage = res;
				this.checkChanges(this.marketingLandingPage);
				this.openSnackBar('Preview Changes Discarded', 'success');
				this.backToSeoLandingList();
			}, error: err => {
				this.openSnackBar('Server Error', 'error');
				console.log('test', err);
			}
		});
	}

	buildPreviewUrl(marketingLandingPage: MarketingLandingPage) {
		if (marketingLandingPage && marketingLandingPage.id && marketingLandingPage.path) {
			this.url = this.baseUrl + '/preview-pages/' + marketingLandingPage.path;
			this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
		}
	}

	backToSeoLandingList() {
		this.router.navigate([`/admin/landingPage`]);
	}
}

