import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from './app-config.service';

@Injectable()
export class AccountService {
	private baseUrl: string;

	constructor(private http: HttpClient, private authService: AuthService, private environment: AppConfigService) {
		this.baseUrl = environment.config.apiBaseUrl + '/api/admin/password/change';
	}

	changePassword(oldPassword: string, newPassword: string, confirmPassword: string): any {

		const requestBody = {
			oldPassword: oldPassword,
			newPassword: newPassword,
			newPasswordConfirm: confirmPassword
		};
		return this.http.put<any>(this.baseUrl, requestBody, this.authService.getOptions());
	}
}

