import {map, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from './auth.service';
import {Observable} from 'rxjs/Observable';
import {AppConfigService} from './app-config.service';

@Injectable()
export class AdPlacementService {
	private baseUrl: string;

	constructor(private http: HttpClient, private authService: AuthService, private environment: AppConfigService) {
		this.baseUrl = environment.config.apiBaseUrl + '/api/ad-placement';
	}

	getAll(): Observable<any> {
		return this.http.get(`${this.baseUrl}`, this.authService.getOptions()).pipe(
			map(res => res)
		);
	}

	updatePlacement(placement: any): Observable<any> {
		return this.http.put(`${this.baseUrl}/${placement.id}`, JSON.stringify(placement), this.authService.getOptions()).pipe(
			res => res
		)
	}
}
