import {Component, OnInit} from '@angular/core';
import {EmailService} from '../../_services/email-service';
import {Util} from '../../_utils/util';
import {Loader} from '../../_models/loader';
import {MatSnackBar} from '@angular/material';
import {AuthService} from '../../_services/auth.service';
import {FormGroup} from '@angular/forms';

@Component({
	selector: 'app-email-service',
	templateUrl: './email-service.component.html',
	styleUrls: ['./email-service.component.scss'],
})

export class EmailServiceComponent implements OnInit {

	public editMode: boolean;
	public _emailInput: string;
	public _userId: number;
	public _userEmail: string;
	public loader: Loader;
	public isAdmin: boolean;
	isEdit: boolean;

	constructor(private _emailService: EmailService, private _util: Util, public snackBar: MatSnackBar, public authService: AuthService) {
		this.isAdmin = false;
	}

	ngOnInit() {
		this.loader = new Loader();
		this._userId = -1;
		this.editMode = false;
		this.isAdmin = this.authService.isAdmin();
		this.isEdit = false;
	}

	public toggleEmail() {
		this.editMode = true;
	}

	public findUserIdByEmail(): void {
		this.loader.isLoading = true;

		if (this._util.validateEmail(this._emailInput)) {
			this._emailService.findUserIdByEmail(this._emailInput)
				.subscribe(
					res => {
						console.log(res);
						this.loader.isLoading = false;
						this._userId = res.userId;
						this._userEmail = this._emailInput;
						if (res.userId === -1) {
							this.openSnackBar('No user found with this email address');
						}
					},
					err => {
						this.loader.isLoading = false;
						this.openSnackBar(err);
					});
		} else {
			this.loader.isLoading = false;
			this.openSnackBar('Invalid Email');
		}
	}

	public changeEmail() {
		this.loader.isLoading = true;
		this.loader.loadingMessage = 'Updating please wait...';

		if (this._util.validateEmail(this._userEmail)) {
			this._emailService.changeEmail(this._userEmail.trim(), this._userId)
				.subscribe(
					res => {
						console.log(res);
						this.loader.isLoading = false;
						this.editMode = false;
						if (res === 'DONE') {
							this.openSnackBar('Update complete');
						} else if (res === 'EMAIL ALREADY EXISTS') {
							this.openSnackBar('Update failed: User with email \'' + this._userEmail.trim() + '\' already exists');
						} else {
							this.openSnackBar('Update failed: ' + res);
						}
					},
					err => {
						this.openSnackBar('Unexpected error occur');
						this.loader.isLoading = false;
					}
				)
		}
	}

	edit() {
		this.isEdit = true;
	}

	closeEdit() {
		this.isEdit = false;
	}

	openSnackBar(message: string) {
		this.snackBar.open(message, '', {
			duration: 4000,
		});
	}
}
