import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';
import {Campaign} from '../_models/campaign';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from './app-config.service';

@Injectable()
export class CampaignService {
	private baseUrl: string;
	private plAdjbaseUrl: string;

	constructor(private http: HttpClient, private authService: AuthService, private environment: AppConfigService) {
		this.baseUrl = environment.config.apiBaseUrl + '/api/campaign';
		this.plAdjbaseUrl = environment.config.apiBaseUrl + '/api/v1/pl_adjustment_share';
	}

	getAll(): Observable<any> {
		return this.http.get(this.baseUrl, this.authService.getOptions()).pipe(map(res => res));
	}

	getDetailedCampaignData(startDate: String, endDate: String, campaignId: number): Observable<Campaign> {
		return this.http.get<Campaign>(`${this.baseUrl}/expense/${campaignId}?startDate=${startDate}&endDate=${endDate}`, this.authService.getOptions()).pipe(
			map(res => res));
	}

	adjustExpensesForCampaign(startDate: String, endDate: String, campaignId: number, newTotalExpense: string,
							  updateExistingCpc: boolean): Observable<number> {
		const data = {
			startDate: startDate,
			endDate: endDate,
			totalCost: newTotalExpense,
			updateCampaignDefaultCost: updateExistingCpc
		};
		return this.http.post<number>(`${this.baseUrl}/expense/${campaignId}`, JSON.stringify(data), this.authService.getOptions()).pipe(
			map(res => res));
	}

	getExpenseAdjustments(campaignId: number) {
		return this.http.get<any>(`${this.baseUrl}/${campaignId}/adjustments`, this.authService.getOptions()).pipe(
			map(res => res));
	}

	getPartners() {
		return this.http.get<any>(`${this.environment.config.apiBaseUrl}/api/partner?size=1000`, this.authService.getOptions()).pipe(
			map(res => res));
	}

	getUtmMediumForCampaign(id: string) {
		return this.http.get<any>(`${this.environment.config.apiBaseUrl}/api/v1/campaigns/${id}/utmMedium`, this.authService.getOptions()).pipe(
			map(res => res));
	}

	getUtmMediums() {
		return this.http.get<any>(`${this.environment.config.apiBaseUrl}/api/v1/utm_mediums`, this.authService.getOptions()).pipe(
			map(res => res));
	}

	getCampaigns(page: number) {
		return this.http.get<any>(`${this.environment.config.apiBaseUrl}/api/v1/campaigns?page=${page}&size=100`, this.authService.getOptions()).pipe(
			map(res => res));
	}

	getCampaignsByDescription(page: number, description: string) {
		page = page - 1;
		return this.http.get<any>(`${this.environment.config.apiBaseUrl}/api/v1/campaigns/search/findAllByDescriptionContaining?description=${description}&page=${page}&size=100`,
			this.authService.getOptions())
			.pipe(map(res => res));
	}

	getCampaignsById(id: number) {
		return this.http.get<any>(`${this.environment.config.apiBaseUrl}/api/v1/campaigns/${id}`, this.authService.getOptions()).pipe(
			map(res => res));
	}

	createNewCampaign(data: any) {
		return this.http.post<any>(`${this.environment.config.apiBaseUrl}/api/campaign`, JSON.stringify(data),
			this.authService.getOptions()).pipe(map(res => res));
	}

	createNewPlAdjustmentRecord(data: any) {
		return this.http.post<any>(`${this.plAdjbaseUrl}`, JSON.stringify(data), this.authService.getOptions()).pipe(map(res => res));
	}

	updateCampaign(data: any) {
		return this.http.put<any>(`${this.environment.config.apiBaseUrl}/api/v1/campaigns/${data.id}`, JSON.stringify(data),
			this.authService.getOptions()).pipe(map(res => res));
	}

	addUtmMediumToCampaign(cId: string, utmLink: string) {
		return this.http.put<any>(`${this.environment.config.apiBaseUrl}/api/v1/campaigns/${cId}/utmMedium`, utmLink,
			this.authService.getTextOptions()).pipe(map(res => res));
	}

	getPlAdjustmentShares(cId: number) {
		return this.http.get<any>(`${this.environment.config.apiBaseUrl}/api/v1/pl_adjustment_share/search/findByCampaignId/${cId}`,
			this.authService.getOptions()).pipe(map(res => res));
	}

	updatePlAdjustmentShares(data: any, plAdjustmentId) {
		let plAdjustmentLink = `${this.plAdjbaseUrl}/${plAdjustmentId}`;
		return this.http.put<any>(plAdjustmentLink, JSON.stringify(data), this.authService.getOptions()).pipe(map(res => res));
	}
}

function toCampaign(r: any): Campaign {
	return new Campaign(r.id, r.id + ' - ' + r.name);
}
