import {Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {Loader} from '../../_models/loader';
import {Subscription} from 'rxjs/Rx';
import {MatSnackBar} from '@angular/material';
import {Brand} from '../../_models/brand';
import {BrandService} from '../../_services/brand-service';

@Component({
	selector: 'app-brand-detail-edit',
	templateUrl: './brand-details-edit.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class BrandDetailsEditComponent implements OnInit {

	errorMsg: string;
	brandForm: FormGroup;
	hasFormErrors = false;
	loader: Loader;
	id: number;
	brand: Brand;
	formReady: Boolean = false;
	backlink = '/admin/brandEdit';
	inputsFields = [
		// {prop: 'name', label: 'Brand Name', disabled: true},
		// {prop: 'vendor_id', label: 'Vendor Id', disabled: true},
		{label: 'DB Commission Rate', prop: 'dbexpert_commission_rate', disabled: true},
		{label: 'DB Booking Fee', prop: 'dbexpert_booking_fee', disabled: true},
		{label: 'DB Show Rate', prop: 'dbexpert_show_rate', disabled: true},
		{label: 'Search Rate Percent', prop: 'rate_search_percent', disabled: true},
		{label: 'Max CPC Clicks', prop: 'max_cpc_clicks', disabled: true},
		{label: 'Max CPC Clicks p SIPP', prop: 'max_cpc_clicks_per_sipp', disabled: true},
		{label: 'Phone Number', prop: 'phone', disabled: true},
		{label: 'Equivalent CPC', prop: 'equivalent_cpc', disabled: true},
		{prop: 'matrix_position', label: 'Matrix Position', disabled: true},
		{prop: 'list_sort_index', label: 'List Sort Index', disabled: true},
		{prop: 'budget_cap_threshold', label: 'Budget Cap Threshold', disabled: true}
	];

	constructor(private brandService: BrandService,
				private fb: FormBuilder,
				private cdRef: ChangeDetectorRef,
				public snackBar: MatSnackBar,
				private route: ActivatedRoute) {
	}

	ngOnInit() {
		this.loader = new Loader();
		this.id = parseInt(this.route.snapshot.paramMap.get('id'));
		this.loader.isLoading = true;
		this.fetchDetail();
	}

	fetchDetail(): Subscription {
		return this.brandService.getBrandById(this.id).subscribe({
			next: res => {
				this.loader.isLoading = false;
				this.brand = res;
				this.createForm();
				this.cdRef.detectChanges();
			}, error: err => {
				this.loader.isLoading = false;
				this.openSnackBar('unknown server error occured', 'error');
				console.log('err ', err);
			}
		});
	}

	createForm() {
		this.brandForm = this.fb.group({
			name: [this.brand.name, Validators.required],
			active: [this.brand.active, Validators.required],
			id: [this.brand.id, Validators.required],
			vendor_id: [this.brand.vendor_id, Validators.required],
			partner_api_brand_id: [this.brand.partner_api_brand_id],
			brand_parent_id: [this.brand.brand_parent_id],
			matrix_position: [this.brand.matrix_position, Validators.required],
			list_sort_index: [this.brand.list_sort_index, Validators.required],
			dbexpert_commission_rate: [this.brand.dbexpert_commission_rate, Validators.required],
			dbexpert_booking_fee: [this.brand.dbexpert_booking_fee, Validators.required],
			dbexpert_show_rate: [this.brand.dbexpert_show_rate, Validators.required],
			rate_search_percent: [this.brand.rate_search_percent, Validators.required],
			max_cpc_clicks: [this.brand.max_cpc_clicks],
			max_cpc_clicks_per_sipp: [this.brand.max_cpc_clicks_per_sipp],
			phone: [this.brand.phone],
			equivalent_cpc: [this.brand.equivalent_cpc],
			budget_cap_threshold: [this.brand.budget_cap_threshold]
		});
		this.formReady = true;
	}

	onSubmit() {
		this.hasFormErrors = false;
		const controls = this.brandForm.controls;

		if (this.brandForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			this.hasFormErrors = true;
			return;
		}

		const b = this.prepareForm();

		this.brandService.updateBrand(b).subscribe({
			next: res => {
				this.brand = res;
				this.openSnackBar('Updated', 'success');
			}, error: err => {
				this.openSnackBar('Server Error', 'error');
				console.log('test', err);
			}
		});
	}

	prepareForm(): Brand {
		const controls = this.brandForm.controls;
		this.inputsFields.forEach(i => {
			this.brand[i.prop] = controls[i.prop].value
		});
		this.brand['active'] = controls['active'].value;
		this.brand['matrix_position'] = controls['matrix_position'].value;
		this.brand['list_sort_index'] = controls['list_sort_index'].value;
		this.brand['id'] = controls['id'].value;
		return this.brand;
	}

	openSnackBar(message: string, type) {
		this.snackBar.open(message, type, {
			duration: 10000,
		});
	}

}
