import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {AppConfigService} from './app-config.service';

@Injectable()
export class SeoLocationService {

	private baseUrl: string;

	constructor(private http: HttpClient, private authService: AuthService, private environment: AppConfigService) {
		this.baseUrl = environment.config.apiBaseUrl + '/api/v1/seo-location';
	}

	getLocationsByName(name: String, page: number) {
		page = page - 1;
		return this.http.get<any>(`${this.baseUrl}/findAllByNameContaining?name=${name}&page=${page}`, this.authService.getOptions());
	}

	getActiveLocationsByName(name: String, page: number) {
		page = page - 1;
		return this.http.get<any>(`${this.baseUrl}/findAllByNameContainingAndActiveIsTrue?name=${name}&page=${page}`, this.authService.getOptions());
	}

	getInactiveLocationsByName(name: String, page: number) {
		page = page - 1;
		return this.http.get<any>(`${this.baseUrl}/findAllByNameContainingAndActiveIsFalse?name=${name}&page=${page}`, this.authService.getOptions());
	}

	update(location: any) {
		return this.http.put<any>(`${this.baseUrl}/${location.id}`, JSON.stringify(location), this.authService.getOptions());
	}

	getLocationDetails(location: any) {
		location.id = null;
		return this.http.get<any>(`${this.baseUrl}/${location.id}`, this.authService.getOptions());
	}

	getSeoLocationDetails(location: any) {
		location.id = null;
		return this.http.get<any>(`${this.baseUrl}/${location.id}`, this.authService.getOptions());
	}

	createSeoLocation(data: any) {
		return this.http.post<any>(`${this.baseUrl}`, JSON.stringify(data), this.authService.getOptions()).pipe(map(res => res));
	}
}

