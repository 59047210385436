import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {Observable} from 'rxjs';
import {AbTestDescriptor, AbTestVariantDescriptor} from '../_models/abTest';
import {HttpClient, HttpEvent, HttpHeaders} from '@angular/common/http';
import {AppConfigService} from './app-config.service';


@Injectable()
export class AbToolService {
	private baseUrl: string;
	private token = this.authService.getToken();

	constructor(private http: HttpClient, private authService: AuthService, private environment: AppConfigService) {
		this.baseUrl = environment.config.apiBaseUrl + '/api/ab_tool';
	}

	getAllTests(): Observable<AbTestDescriptor[]> {
		return this.http.get<AbTestDescriptor[]>(`${this.baseUrl}/get_all_tests`, this.authService.getOptions());
	}

	activateTest(testId: number) {
		return this.http.get(`${this.baseUrl}/activate/${testId}`, {
			headers:
				new HttpHeaders({'content-type': 'application/json', 'Authorization': this.token}), responseType: 'text'
		});
	}

	deactivateTest(testId: number) {
		return this.http.get(`${this.baseUrl}/deactivate/${testId}`, {
			headers:
				new HttpHeaders({'content-type': 'application/json', 'Authorization': this.token}), responseType: 'text'
		});
	}

	changeTestPercentage(testId: number, controlPercentage: number, variantPercentage: number) {
		return this.http.get(
			`${this.baseUrl}/update_percentage/${testId}?controlPercentage=${controlPercentage}&variantPercentage=${variantPercentage}`,
			{
				headers:
					new HttpHeaders({'content-type': 'application/json', 'Authorization': this.token}), responseType: 'text'
			});
	}

	retireTest(testId: number, winnerId: number) {
		return this.http.get<any>(`${this.baseUrl}/retire/${testId}/${winnerId}`,
			this.authService.getDefaultOptions()).pipe();
	}
}
