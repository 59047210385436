import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
	selector: 'app-seo-tab-tool',
	templateUrl: './seo-tool-tab.component.html',
	styleUrls: ['./seo-tool-tab.component.scss']
})
export class SeoToolTabComponent implements OnInit {

	@Input() location: any;
	@Input() seoFaqs: any[];
	@Input() seoLocationMappings: any[];
	@Input() seoSuppliers: any[];
	@Input() faqElements: any;
	@Input() popUpShowed: any;
	valueChange: boolean;
	@Output() locationSelect: EventEmitter<any> = new EventEmitter<any>();
	@Output() disableTabEvent: EventEmitter<any> = new EventEmitter<any>();
	disableOtherTabs: boolean;

	constructor() {
	}

	ngOnInit() {
		this.disableOtherTabs = false;
		this.popUpShowed = false;
		this.location.closeEditableTabs = true;
	}

	showEditableTabs() {
		this.location.closeEditableTabs = false;
	}

	valueChanged() {
		this.disableTabEvent.emit(!this.location.closeEditableTabs);
		this.valueChange = true;
		this.disableOtherTabs = this.valueChange;
	}

	faqUnsavedChangesHandler(isChange) {
		this.disableTabEvent.emit(isChange);
		this.valueChange = true;
	}

	supplierUnsavedChangesHandler(isChange) {
		this.disableTabEvent.emit(isChange);
		this.valueChange = true;
	}

	faqEditModeHandler(isChange) {
		this.valueChange = false;
	}
}
