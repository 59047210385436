import {Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {Loader} from '../../_models/loader';
import {BrandService} from '../../_services/brand-service';
import {BaseCpc} from './brand-cpc-editor.component';
import {Subscription} from 'rxjs/Rx';
import {MatSnackBar} from '@angular/material';

@Component({
	selector: 'app-brand-cpc-edit-dialog',
	templateUrl: './brand-cpc-edit-dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class BrandCpcEditDialogComponent implements OnInit {

	errorMsg: string;
	baseCpcForm: FormGroup;
	hasFormErrors = false;
	loader: Loader;
	id: string;
	baseCpc: BaseCpc;
	ready: Boolean = false;
	backlink = '/admin/update_cpc';

	constructor(private _brandService: BrandService,
				private fb: FormBuilder,
				private cdRef: ChangeDetectorRef,
				public snackBar: MatSnackBar,
				private route: ActivatedRoute) {
	}

	ngOnInit() {
		this.loader = new Loader();
		this.loader.isLoading = true;
		this.fetchDetail();
	}

	fetchDetail(): Subscription {
		this.id = this.route.snapshot.paramMap.get('id');
		return this._brandService.getBrandCpcById(this.id).subscribe({
			next: res => {
				console.log('res ', res);
				this.loader.isLoading = false;
				this.baseCpc = res;
				this.ready = true;
				this.createForm();
				this.cdRef.detectChanges();
			}, error: err => {
				this.loader.isLoading = false;
				this.openSnackBar('unknown server error occured', 'error');
				console.log('err ', err);
			}
		});
	}

	createForm() {
		this.baseCpcForm = this.fb.group({
			brand_id: [{value: this.baseCpc.brand_id, disabled: true}, Validators.required],
			cpc: [this.baseCpc.cpc, Validators.required],
			device: [{value: this.baseCpc.device_type, disabled: true}, Validators.compose([Validators.required])],
			placement: [{value: this.baseCpc.placement_type, disabled: true}, Validators.compose([Validators.required])]
		});
		console.log('test', this.baseCpcForm);
	}

	onSubmit() {
		this.hasFormErrors = false;
		const controls = this.baseCpcForm.controls;

		if (this.baseCpcForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			this.hasFormErrors = true;
			return;
		}

		const baseCpc = this.prepareForm();
		// this.loader.isLoading = true;
		this._brandService.updateBrandBaseCpc(baseCpc, this.id).subscribe({
			next: res => {
				console.log('test ', res);
				// this.loader.isLoading = false;
				this.openSnackBar('Updated', 'success');
			}, error: err => {
				// this.loader.isLoading = false;
				this.openSnackBar('Server Error', 'error');
				console.log('test', err);
			}
		});
	}

	prepareForm(): BaseCpc {
		const controls = this.baseCpcForm.controls;
		return {
			cpc: controls.cpc.value,
			brand_id: controls.brand_id.value,
			placement_type: controls.placement.value,
			brand_name: '',
			device_type: controls.device.value
		};
	}

	openSnackBar(message: string, type) {
		this.snackBar.open(message, type, {
			duration: 10000,
		});
	}

	reset(): void {
		this.ngOnInit();
	}
}
