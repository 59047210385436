import {Component, OnInit} from '@angular/core';
import * as XLSX from 'xlsx';
import {UserService} from '../../_services/user-service';
import {AuthService} from '../../_services/auth.service';

@Component({
	selector: 'app-email-subscription',
	templateUrl: './email-subscription.component.html',
	styleUrls: ['./email-subscription.component.scss'],
})

export class EmailSubscriptionComponent implements OnInit {

	arrayBuffer: any;
	file: File;
	data: any[];
	dataFromExcel: any[];
	dataString: string;
	count: number;
	userService: UserService;
	authService: AuthService;
	errorMessage: string;
	dataNewFromExcel: any[];
	countFailed: number;
	emailsFailed: string[];
	isAdmin: boolean;

	ngOnInit(): void {
		this.isAdmin = this.authService.isAdmin();
	}


	constructor(userService: UserService, authService: AuthService) {
		this.data = [];
		this.dataFromExcel = [];
		this.dataNewFromExcel = [];
		this.dataString = '';
		this.userService = userService;
		this.authService = authService;
		this.count = 0;
		this.errorMessage = '';
		this.countFailed = 0;
		this.emailsFailed = [];
		this.isAdmin = false;
	}


	incomingFile(event) {
		this.file = event.target.files[0];
	}

	upload() {
		this.errorMessage = '';
		let fileReader = new FileReader();
		fileReader.onload = (e) => {
			this.dataFromExcel = [];
			this.data = [];
			this.arrayBuffer = fileReader.result;
			var data = new Uint8Array(this.arrayBuffer);
			var arr = [];
			for (var i = 0; i != data.length; ++i) {
				arr[i] = String.fromCharCode(data[i]);
			}
			var bstr = arr.join('');
			var workbook = XLSX.read(bstr, {type: 'binary'});
			var first_sheet_name = workbook.SheetNames[0];
			var worksheet = workbook.Sheets[first_sheet_name];
			var dataJson = XLSX.utils.sheet_to_json(worksheet, {raw: true});
			this.dataFromExcel = dataJson;
			if (dataJson == null || dataJson.length > 0) {
				this.errorMessage = 'No data found. Please upload a excel sheet that has email as header';
			}
		}
		fileReader.readAsArrayBuffer(this.file);
	}

	getEmailsFromTextBox() {
		this.data = [];
		this.dataFromExcel = [];
		this.dataString = this.dataString.trim();
		console.log(this.dataString);
		this.data = this.dataString.split('\n');
		console.log(this.data);
	}

	subscribe() {
		this.emailsFailed = [];
		this.countFailed = 0;
		this.count = 0;
		if (this.data != null && this.data.length > 0) {
			this.userService.subscribeEmails(this.data).subscribe(
				res => {
					this.count = res.count;
					this.countFailed = res.count_fail;
					this.emailsFailed = res.email;
					this.data = [];
				}, err => {
					this.errorMessage = err;
				}
			)
		} else if (this.dataFromExcel != null && this.dataFromExcel.length > 0) {
			this.userService.subscribeExcelEmails(this.dataFromExcel).subscribe(
				res => {
					this.count = res.count;
					this.countFailed = res.count_fail;
					this.emailsFailed = res.email;
					this.dataFromExcel = [];
					this.dataString = '';
				}, err => {
					this.errorMessage = err;
				}
			)
		}
	}

	unsubscribe() {
		this.emailsFailed = [];
		this.countFailed = 0;
		this.count = 0;
		if (this.data != null && this.data.length > 0) {
			this.userService.unSubscribeEmails(this.data).subscribe(
				res => {
					this.count = res.count;
					this.countFailed = res.count_fail;
					this.emailsFailed = res.email;
					this.data = [];
				}, err => {
					this.errorMessage = err;
				}
			)
		} else if (this.dataFromExcel != null && this.dataFromExcel.length > 0) {
			this.userService.unSubscribeExcelEmails(this.dataFromExcel).subscribe(
				res => {
					this.count = res.count;
					this.countFailed = res.count_fail;
					this.emailsFailed = res.email;
					this.dataFromExcel = [];
					this.dataString = '';
				}, err => {
					this.errorMessage = err;
				}
			)
		}
	}

}
