import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {AppConfigService} from './app-config.service';

@Injectable()
export class SeoSupplierService {

	private baseUrl: string;

	constructor(private http: HttpClient, private authService: AuthService, private environment: AppConfigService) {
		this.baseUrl = environment.config.apiBaseUrl + '/api/v1/seo-suppliers';
	}

	getSuppliersBylocationId(locationId: any) {
		return this.http.get<any>(`${this.baseUrl}/findAllByLocationId?locationId=${locationId}&page=1&size=1000`,
			this.authService.getOptions());
	}

	update(supplier: any) {
		return this.http.put<any>(`${this.baseUrl}/${supplier.id}`, JSON.stringify(supplier), this.authService.getOptions());
	}

	getSupplierDetails(supplier: any) {
		return this.http.get<any>(`${supplier._links.self.href}`, this.authService.getOptions());
	}

	createSeoSupplier(data: any) {
		return this.http.post(`${this.baseUrl}`, JSON.stringify(data), this.authService.getOptions()).pipe(map(res => res));
	}
}


