import {Component, OnInit} from '@angular/core';
import {Loader} from '../../_models/loader';
import {MatSnackBar} from '@angular/material';
import {CampaignService} from '../../_services/campaign-service';
import {AuthService} from '../../_services/auth.service';
import {CampaignGroupService} from '../../_services/campaign-group-service';
import {CampaignGroupMappingService} from '../../_services/campaign-group-mapping-service';

@Component({
	selector: 'app-campaign-tool',
	templateUrl: './campaign-tool.component.html',
	styleUrls: ['./campaign-tool.component.scss']
})
export class CampaignToolComponent implements OnInit {
	errorMsg: string;
	_campaign: any;
	_campaigns: any[];
	_partners: any[];
	_partners_name: string[];
	_utm_mediums: any[];
	_utm_mediums_names: any[];
	_pagination: any;

	business_model: string[] = ['CPC', 'Free', 'CPM', 'Fixed Fee', 'CPA'];
	unit_of_cost: string[] = ['Dollar', 'Percent'];
	currentPage: number;
	_searchTerm: any;
	showForm: boolean;
	campaignNotCreated: boolean;
	createdCid: number;
	isSearch: boolean;
	showEdit: boolean;
	editingCampaign: any;
	campaignNotUpdated: any;
	plAdjustmentShare: any;
	plAdjustmentShare1: any;
	isAdmin: boolean;
	campaignGroups: any[];
	campaignGroupMappings: any[];
	deletedCampaignGroupMappings: any[];
	percentage: string;
	group: any;
	percentageCreate: string;
	groupCreate: any;

	public campaignMap = [
		{name: 'Id', prop: 'id'},
		{name: 'Description', prop: 'description'},
		{name: 'Business Model', prop: 'business_model'},
		{name: 'Default Cost', prop: 'default_cost'},
		{name: 'Date Fixed Fee Apply ', prop: 'date_fixed_fee_apply'},
		{name: 'Unit of Cost', prop: 'unit_of_cost'},
		{name: 'Partner', prop: 'partner_id'},
		{name: 'UTM Medium', prop: 'utm_medium'},
		{name: 'Capture Params', prop: 'capture_params'},
		{name: 'Is tpm', prop: 'tpm'},
		{name: 'Dynamic Widget Tab Title', prop: 'dynamic_widget_show_tab_title'}
	];

	public loader: Loader;

	constructor(private _campaignService: CampaignService, public snackBar: MatSnackBar, private authService: AuthService,
				private _campaignGroupService: CampaignGroupService, private campaignGroupMappingService: CampaignGroupMappingService) {
		this.currentPage = 1;
		this._searchTerm = '';
		this.showForm = false;
		this.campaignNotCreated = true;
		this.isSearch = false;
		this.showEdit = false;
		this.campaignNotUpdated = true;
		this.isAdmin = false;
	}

	createCampaign(): void {
		if (this.validateCampaign()) {
			const pId = this._campaign.partner_id.id;
			this._campaign.partner_id = pId;
			const utm_medium = this._campaign.utm_mediums;
			delete this._campaign.utm_mediums;
			this.calclateEmailSupressionValues(this._campaign);
			if (this._campaign.campaignGroups && this._campaign.campaignGroups.length > 0) {
				for (let i in this._campaign.campaignGroups) {
					this.campaignGroupMappingService.createNewCampaignGroupMapping(this._campaign.campaignGroups[i]).subscribe(
						res => {
						});
				}
			}
			this._campaignService.createNewCampaign(this._campaign).subscribe(
				res => {
					const cId = res.id;
					this._campaignService.addUtmMediumToCampaign(cId, utm_medium.name).subscribe(
						updateRes => {
							this.loader.isLoading = false;
							this.openSnackBar('Campaign ' + cId + ' created');
							this.getInitParams();
							this.campaignNotCreated = false;
							this.createdCid = cId;
							setTimeout(() => {
									this.closePopUp();
								},
								5000);
						},
						err => {
							this.loader.isLoading = false;
							this.openSnackBar('Campaign created but unable to add UTM Medium to campaign');
							setTimeout(() => {
									this.closePopUp();
								},
								5000);
						}
					);
					if (this._campaign.business_model === 'rev_share') {
						if (this.plAdjustmentShare.percentage !== 0) {
							this.plAdjustmentShare.campaign_id = cId;
							this.plAdjustmentShare.percentage = this.plAdjustmentShare.percentage / 100;
							this.plAdjustmentShare.share_type = 'EXPENSE';
							this.plAdjustmentShare.device_type = 'DESKTOP';
							this._campaignService.createNewPlAdjustmentRecord(this.plAdjustmentShare).subscribe(
								res => {
									console.log('res ', res);
								}, err => {
									console.log(err);
									this.loader.isLoading = false;
									console.log('error ', err._body.message);
									this.openSnackBar('Campaign created but unable to add pl adjustment record');
								}
							)
						}
						if (this.plAdjustmentShare1.percentage !== 0) {
							this.plAdjustmentShare1.campaign_id = cId;
							this.plAdjustmentShare1.percentage = this.plAdjustmentShare1.percentage / 100;
							this.plAdjustmentShare1.share_type = 'EXPENSE';
							this.plAdjustmentShare1.device_type = 'MOBILE';
							this._campaignService.createNewPlAdjustmentRecord(this.plAdjustmentShare1).subscribe(
								res => {
									console.log('res ', res);
								}, err => {
									console.log(err);
									this.loader.isLoading = false;
									console.log('error ', err._body.message);
									this.openSnackBar('Campaign created but unable to add pl adjustment record');
								}
							)
						}
					}
				},
				err => {
					if (err.status === 409) {
						this.loader.isLoading = false;
						this.openSnackBar('CID already exists. Please try again with a new ID');
					} else {
						this.loader.isLoading = false;
						this.openSnackBar('Internal server error. Unable to create campaign');
					}
				});

		} else {
			if (this._campaign.business_model === 'rev_share') {
				if (!this._campaign.utm_mediums) {
					this.openSnackBar('Please select a UTM medium');
				} else if (!this._campaign.partner_id) {
					this.openSnackBar('Please select a partner to create campaign');
				} else {
					this.openSnackBar('Please fill out all form to create campaign');
				}
			} else {
				if (!this._campaign.utm_mediums) {
					this.openSnackBar('Please select a UTM medium');
				} else if (!this._campaign.partner_id) {
					this.openSnackBar('Please select a partner to create campaign');
				} else {
					this.openSnackBar('Please fill out all form to create campaign');
				}
			}
		}
	}

	validateAndUpdateCampaign() {
		if (this.editingCampaign.business_model == 'rev_share') {
			if (this.editingCampaign.desktopPlShare && this.editingCampaign.mobilePlShare && this.editingCampaign.desktopPlShare.percentage > 0 && this.editingCampaign.mobilePlShare.percentage > 0 && this.editingCampaign.utm_medium) {
				this.updateCampaign();
			} else if (!this.editingCampaign.utm_medium) {
				this.openSnackBar('Please select a UTM medium');
			} else {
				this.openSnackBar('Please enter the Rev Share percentage to update campaign');
			}
		} else if (!this.editingCampaign.utm_medium) {
			this.openSnackBar('Please select a UTM medium');
		} else {
			this.updateCampaign();
		}
	}

	validateAndCreateCampaign() {
		if (this._campaign.business_model == 'rev_share') {
			if (this.plAdjustmentShare && this.plAdjustmentShare1 && this.plAdjustmentShare.percentage > 0 && this.plAdjustmentShare1.percentage > 0 && this._campaign.utm_mediums) {
				this.createCampaign();
			} else if (!this._campaign.utm_mediums) {
				this.openSnackBar('Please select a UTM medium');
			} else {
				this.openSnackBar('Please enter the Rev Share percentage to update campaign');
			}
		} else {
			this.createCampaign();
		}
	}

	updateCampaign(): void {
		if (this.editingCampaign.business_model != 'rev_share') {
			delete this.editingCampaign.desktopPlShare;
			delete this.editingCampaign.mobilePlShare;
		}
		this.calclateEmailSupressionValues(this.editingCampaign);
		if ((this.editingCampaign.campaignGroups && this.editingCampaign.campaignGroups.length > 0) || (this.deletedCampaignGroupMappings && this.deletedCampaignGroupMappings.length > 0)) {
			this.updateCampaignGroupMappings();
		}
		const utmLink = this.getUtmMediumByName(this.editingCampaign.utm_medium);
		this.editingCampaign.utm_medium = null;
		this.editingCampaign.external_params_excl = this.editingCampaign.external_params_excl.toString().split(',');
		this._campaignService.updateCampaign(this.editingCampaign).subscribe(
			res => {
				const cId = this.editingCampaign.id;
				this._campaignService.addUtmMediumToCampaign(cId, utmLink).subscribe(
					updateRes => {
						this.loader.isLoading = false;
						this.openSnackBar('Campaign ' + cId + ' updated');
						this.getInitParams();
						this.campaignNotUpdated = false;
						setTimeout(() => {
								this.closeEditPopUp();
							},
							5000);
						this.getCampaign(this.currentPage);
					},
					err => {
						this.loader.isLoading = false;
						this.openSnackBar('Campaign updated but unable to update UTM Medium to campaign');
					}
				)
				if (this.editingCampaign.business_model == 'rev_share') {
					if (this.editingCampaign.desktopPlShare._links && this.editingCampaign.desktopPlShare._links.self && this.editingCampaign.desktopPlShare._links.self.href) {
						this.editingCampaign.desktopPlShare.percentage = this.editingCampaign.desktopPlShare.percentage / 100;
						this._campaignService.updatePlAdjustmentShares(this.editingCampaign.desktopPlShare, this.editingCampaign.desktopPlShare._links.self.href).subscribe(
							res => {
							},
							err => {
								this.loader.isLoading = false;
								this.openSnackBar('Campaign updated but unable to update plAdjustmentShare');
							}
						)
					} else if (this.editingCampaign.desktopPlShare && this.editingCampaign.desktopPlShare.percentage) {
						this.editingCampaign.desktopPlShare.campaign_id = cId;
						this.editingCampaign.desktopPlShare.share_type = 'EXPENSE';
						this.editingCampaign.desktopPlShare.device_type = 'DESKTOP';
						this.editingCampaign.desktopPlShare.percentage = (this.editingCampaign.desktopPlShare.percentage && this.editingCampaign.desktopPlShare.percentage != null) ? this.editingCampaign.desktopPlShare.percentage / 100 : 1.000;
						this._campaignService.createNewPlAdjustmentRecord(this.editingCampaign.desktopPlShare).subscribe(
							res => {

							}, err => {
								console.log(err);
								this.loader.isLoading = false;
								console.log('error ', err._body.message);
								this.openSnackBar('Updated created but unable to add pl adjustment record');
							}
						)
					}
					if (this.editingCampaign.mobilePlShare._links && this.editingCampaign.mobilePlShare._links.self && this.editingCampaign.mobilePlShare._links.self.href) {
						this.editingCampaign.mobilePlShare.percentage = this.editingCampaign.mobilePlShare.percentage / 100;
						this._campaignService.updatePlAdjustmentShares(this.editingCampaign.mobilePlShare, this.editingCampaign.mobilePlShare._links.self.href).subscribe(
							res => {
							},
							err => {
								this.loader.isLoading = false;
								this.openSnackBar('Campaign updated but unable to update plAdjustmentShare');
							}
						)
					} else if (this.editingCampaign.mobilePlShare && this.editingCampaign.mobilePlShare.percentage) {
						this.editingCampaign.mobilePlShare.campaign_id = cId;
						this.editingCampaign.mobilePlShare.share_type = 'EXPENSE';
						this.editingCampaign.mobilePlShare.device_type = 'MOBILE';
						this.editingCampaign.mobilePlShare.percentage = (this.editingCampaign.mobilePlShare.percentage && this.editingCampaign.mobilePlShare.percentage != null) ? this.editingCampaign.mobilePlShare.percentage / 100 : 1.000;
						this._campaignService.createNewPlAdjustmentRecord(this.editingCampaign.mobilePlShare).subscribe(
							res => {

							}, err => {
								console.log(err);
								this.loader.isLoading = false;
								console.log('error ', err._body.message);
								this.openSnackBar('Updated created but unable to add pl adjustment record');
							}
						)
					}
				}
			},
			err => {
				this.loader.isLoading = false;
				this.openSnackBar('Internal error, unable to update campaign');
			});
	}

	validateCampaign() {
		if (this._campaign.business_model == 'rev_share') {
			return Object.keys(this._campaign).length > 9 && this.plAdjustmentShare.percentage > 0 && this.plAdjustmentShare1.percentage > 0 && this._campaign.partner_id && this._campaign.utm_mediums && this._campaign.default_cost && this._campaign.default_cost.length > 0;
		} else {
			return Object.keys(this._campaign).length > 9 && this._campaign.partner_id && this._campaign.utm_mediums && this._campaign.default_cost && this._campaign.default_cost.length > 0;
		}
	}

	public getCampaign(page: number) {
		this.errorMsg = '';
		if (!this.isSearch) {
			this.loader.isLoading = true;
			this.currentPage = page;
			this._campaignService.getCampaigns(page).subscribe(res => {
				this._campaigns = this.processCampaigns(res.content);
				this._pagination = {};
				this._pagination.pages = Array.from(new Array(res.total_pages), (x, i) => i + 1);
				this.loader.isLoading = false;
				this.isSearch = false;
			});
		} else {
			this.loader.isLoading = true;
			this.currentPage = page;
			if (isNaN(this._searchTerm)) {
				this._campaignService.getCampaignsByDescription(page, this._searchTerm).subscribe(res => {
					this._campaigns = this.processCampaigns(res.content);
					this._pagination = {};
					this._pagination.pages = Array.from(new Array(res.total_pages), (x, i) => i + 1);
					this.loader.isLoading = false;
					if (this._campaigns.length == 0) {
						this.errorMsg = 'No campaigns found';
					}
				}, err => {
					this.loader.isLoading = false;
					this.errorMsg = err;
				})
			} else {
				this._campaignService.getCampaignsById(parseInt(this._searchTerm)).subscribe(res => {
					this._campaigns = this.processCampaigns(new Array(res));
					this._pagination.pages = [1];
					this.loader.isLoading = false;
					if (this._campaigns.length == 0) {
						this.errorMsg = 'No campaigns found';
					}
				}, err => {
					this.loader.isLoading = false;
					this.errorMsg = 'No campaigns found';
				})
			}
		}
	}

	public getInitParams(): void {
		this.isAdmin = this.authService.isAdmin();
		this.loader.isLoading = false;
		this._campaign = {
			display_partner_logo: false,
			dynamic_fixedfee_ecpc: 0,
			exit_popup: false,
			capture_params: false,
			campaignGroups: []
		};
		this.plAdjustmentShare = {};
		this.plAdjustmentShare1 = {};
		this.plAdjustmentShare.percentage = 0;
		this.plAdjustmentShare1.percentage = 0;
		this._campaignService.getUtmMediums().subscribe(res => {
			this._utm_mediums = res;
			this._utm_mediums_names = [];
			for (let utm_medium in this._utm_mediums) {
				this._utm_mediums_names.push(this._utm_mediums[utm_medium].name);
			}
		});

		this._campaignService.getPartners().subscribe(res => {
			this._partners = res;
			this._partners = this._partners.sort((a, b) => {
				return ('' + a.short_name.toLocaleUpperCase()).localeCompare(b.short_name.toLocaleUpperCase())
			});
			this._partners_name = [];
			for (const _partner in this._partners) {
				this._partners_name.push(this._partners[_partner].id);
			}
		});

		this._campaignGroupService.getCampaignGroups().subscribe(res => {
			this.campaignGroups = res;
		});

		this.campaignGroupMappingService.getCampaignGroupMappings().subscribe(res => {
			this.campaignGroupMappings = res;
		});

		this._campaignService.getCampaigns(this.currentPage).subscribe(res => {
			this._campaigns = this.processCampaigns(res.content);
			this._pagination = {};
			this._pagination.pages = Array.from(new Array(res.total_pages), (x, i) => i + 1);
		});
		this.editingCampaign = {};
		this.editingCampaign.desktopPlShare = {};
		this.editingCampaign.mobilePlShare = {};
	}

	ngOnInit() {
		this.loader = new Loader();
		this.errorMsg = '';
		this.getInitParams();
	}

	processCampaigns(campaigns) {
		return campaigns.map(c => {
			this._campaignService.getUtmMediumForCampaign(c.id).subscribe(res => {
				if (res != null) {
					c.utm_medium = res.name;
				}
			});
			return c;
		})
	}

	openSnackBar(message: string) {
		this.snackBar.open(message, '', {
			duration: 4000,
		});
	}

	showPopUp() {
		this.showForm = true;
		this.campaignNotCreated = true;
	}

	closePopUp() {
		this.showForm = false;
		this.getInitParams();
		this._campaign = {
			display_partner_logo: false,
			dynamic_fixedfee_ecpc: 0,
			exit_popup: false,
			capture_params: false,
			campaignGroups: []
		};
	}

	searchClicked(page: number) {
		if (this._searchTerm && this._searchTerm.length > 0) {
			this.isSearch = true;
		} else {
			this.isSearch = false;
		}
		this.getCampaign(page);
	}

	showEditPopUp(c: any) {
		this.deletedCampaignGroupMappings = [];
		this.showEdit = true;
		this.editingCampaign = c;
		this.campaignNotUpdated = true;
		this.editingCampaign.partner_id = c.partner_id;
		this.editingCampaign.mobilePlShare = {};
		this.editingCampaign.desktopPlShare = {};
		if (this.editingCampaign.business_model == 'rev_share') {
			this.getPlAdjustmentSharesByCampaignId(this.editingCampaign.id);
			if (this.editingCampaign.desktopPlShare.percentage && this.editingCampaign.mobilePlShare.percentage) {
				this.editingCampaign.desktopPlShare.percentage = (this.editingCampaign.desktopPlShare.percentage && this.editingCampaign.desktopPlShare.percentage != null) ? this.editingCampaign.desktopPlShare.percentage : 0;
				this.editingCampaign.mobilePlShare.percentage = (this.editingCampaign.mobilePlShare.percentage && this.editingCampaign.mobilePlShare.percentage != null) ? this.editingCampaign.mobilePlShare.percentage : 0;
			}
		}
		this.getSupressionDetails(this.editingCampaign);
		this.editingCampaign.campaignGroups = [];
		for (let x in this.campaignGroupMappings) {
			if (this.campaignGroupMappings[x].campaign_id == parseInt(this.editingCampaign.id)) {
				this.campaignGroupMappings[x].name = this.getCampaignGroupNameById(this.campaignGroupMappings[x].campaign_group_id);
				this.editingCampaign.campaignGroups.push(this.campaignGroupMappings[x]);
			}
		}
	}

	closeEditPopUp() {
		this.showEdit = false;
		this.getCampaign(this.currentPage);
		this.campaignGroupMappingService.getCampaignGroupMappings().subscribe(res => {
			this.campaignGroupMappings = res;
		});
		this.getInitParams();
	}

	getPartnerNameById(id: string): string {
		for (const _partner in this._partners) {
			const pId = this._partners[_partner].id;
			if (pId === id) {
				return this._partners[_partner].short_name;
			}
		}
	}

	getUtmMediumByName(name: string): string {
		for (const _utmMedium in this._utm_mediums) {
			if (this._utm_mediums[_utmMedium].name == name) {
				return this._utm_mediums[_utmMedium].name;
			}
		}
	}

	getPlAdjustmentSharesByCampaignId(id: number) {
		this._campaignService.getPlAdjustmentShares(id).subscribe(
			res => {
				const plAdjustmentShares: any[] = res;
				if (plAdjustmentShares.length > 0) {
					for (const plAdjustmentShare in plAdjustmentShares) {
						if (plAdjustmentShares[plAdjustmentShare].device_type == 'DESKTOP') {
							this.editingCampaign.desktopPlShare = plAdjustmentShares[plAdjustmentShare];
							this.editingCampaign.desktopPlShare.percentage = this.editingCampaign.desktopPlShare.percentage * 100;
						} else if (plAdjustmentShares[plAdjustmentShare].device_type == 'MOBILE') {
							this.editingCampaign.mobilePlShare = plAdjustmentShares[plAdjustmentShare];
							this.editingCampaign.mobilePlShare.percentage = this.editingCampaign.mobilePlShare.percentage * 100;
						}
					}
				}
			}
		)
	}

	calclateEmailSupressionValues(campaign: any) {
		if (campaign.supress_homePage === true) {
			campaign.supress_homePage = 1;
		} else {
			campaign.supress_homePage = 0;
		}

		if (campaign.supress_resultsPage == true) {
			campaign.supress_resultsPage = 2;
		} else {
			campaign.supress_resultsPage = 0;
		}

		if (campaign.supress_orderPage === true) {
			campaign.supress_orderPage = 4;
		} else {
			campaign.supress_orderPage = 0;
		}

		if (campaign.supress_locationPage === true) {
			campaign.supress_locationPage = 8;
		} else {
			campaign.supress_locationPage = 0;
		}

		if (campaign.supress_locationSpecificPage === true) {
			campaign.supress_locationSpecificPage = 16;
		} else {
			campaign.supress_locationSpecificPage = 0;
		}
		campaign.suppress_email_collection = campaign.supress_homePage + campaign.supress_resultsPage + campaign.supress_orderPage + campaign.supress_locationPage + campaign.supress_locationSpecificPage;
		delete campaign.supress_homePage;
		delete campaign.supress_resultsPage;
		delete campaign.supress_orderPage;
		delete campaign.supress_locationPage;
		delete campaign.supress_locationSpecificPage;
	}

	getSupressionDetails(campaign: any) {
		if (campaign.suppress_email_collection == 0) {
			campaign.supress_homePage = false;
			campaign.supress_resultsPage = false;
			campaign.supress_orderPage = false;
			campaign.supress_locationPage = false;
			campaign.supress_locationSpecificPage = false;
		} else if (campaign.suppress_email_collection == 1) {
			campaign.supress_homePage = true;
			campaign.supress_resultsPage = false;
			campaign.supress_orderPage = false;
			campaign.supress_locationPage = false;
			campaign.supress_locationSpecificPage = false;
		} else if (campaign.suppress_email_collection == 2) {
			campaign.supress_homePage = false;
			campaign.supress_resultsPage = true;
			campaign.supress_orderPage = false;
			campaign.supress_locationPage = false;
			campaign.supress_locationSpecificPage = false;
		} else if (campaign.suppress_email_collection == 3) {
			campaign.supress_homePage = true;
			campaign.supress_resultsPage = true;
			campaign.supress_orderPage = false;
			campaign.supress_locationPage = false;
			campaign.supress_locationSpecificPage = false;
		} else if (campaign.suppress_email_collection == 4) {
			campaign.supress_homePage = false;
			campaign.supress_resultsPage = false;
			campaign.supress_orderPage = true;
			campaign.supress_locationPage = false;
			campaign.supress_locationSpecificPage = false;
		} else if (campaign.suppress_email_collection == 5) {
			campaign.supress_homePage = true;
			campaign.supress_resultsPage = false;
			campaign.supress_orderPage = true;
			campaign.supress_locationPage = false;
			campaign.supress_locationSpecificPage = false;
		} else if (campaign.suppress_email_collection == 6) {
			campaign.supress_homePage = false;
			campaign.supress_resultsPage = true;
			campaign.supress_orderPage = true;
			campaign.supress_locationPage = false;
			campaign.supress_locationSpecificPage = false;
		} else if (campaign.suppress_email_collection == 7) {
			campaign.supress_homePage = true;
			campaign.supress_resultsPage = true;
			campaign.supress_orderPage = true;
			campaign.supress_locationPage = false;
			campaign.supress_locationSpecificPage = false;
		} else if (campaign.suppress_email_collection == 8) {
			campaign.supress_homePage = false;
			campaign.supress_resultsPage = false;
			campaign.supress_orderPage = false;
			campaign.supress_locationPage = true;
			campaign.supress_locationSpecificPage = false;
		} else if (campaign.suppress_email_collection == 9) {
			campaign.supress_homePage = true;
			campaign.supress_resultsPage = false;
			campaign.supress_orderPage = false;
			campaign.supress_locationPage = true;
			campaign.supress_locationSpecificPage = false;
		} else if (campaign.suppress_email_collection == 10) {
			campaign.supress_homePage = false;
			campaign.supress_resultsPage = true;
			campaign.supress_orderPage = false;
			campaign.supress_locationPage = true;
			campaign.supress_locationSpecificPage = false;
		} else if (campaign.suppress_email_collection == 11) {
			campaign.supress_homePage = true;
			campaign.supress_resultsPage = true;
			campaign.supress_orderPage = false;
			campaign.supress_locationPage = true;
			campaign.supress_locationSpecificPage = false;
		} else if (campaign.suppress_email_collection == 12) {
			campaign.supress_homePage = false;
			campaign.supress_resultsPage = false;
			campaign.supress_orderPage = true;
			campaign.supress_locationPage = true;
			campaign.supress_locationSpecificPage = false;
		} else if (campaign.suppress_email_collection == 13) {
			campaign.supress_homePage = true;
			campaign.supress_resultsPage = false;
			campaign.supress_orderPage = true;
			campaign.supress_locationPage = false;
			campaign.supress_locationSpecificPage = false;
		} else if (campaign.suppress_email_collection == 14) {
			campaign.supress_homePage = false;
			campaign.supress_resultsPage = true;
			campaign.supress_orderPage = true;
			campaign.supress_locationPage = true;
			campaign.supress_locationSpecificPage = false;
		} else if (campaign.suppress_email_collection == 15) {
			campaign.supress_homePage = true;
			campaign.supress_resultsPage = true;
			campaign.supress_orderPage = true;
			campaign.supress_locationPage = true;
			campaign.supress_locationSpecificPage = false;
		} else if (campaign.suppress_email_collection == 16) {
			campaign.supress_homePage = false;
			campaign.supress_resultsPage = false;
			campaign.supress_orderPage = false;
			campaign.supress_locationPage = false;
			campaign.supress_locationSpecificPage = true;
		} else if (campaign.suppress_email_collection == 17) {
			campaign.supress_homePage = true;
			campaign.supress_resultsPage = false;
			campaign.supress_orderPage = false;
			campaign.supress_locationPage = false;
			campaign.supress_locationSpecificPage = true;
		} else if (campaign.suppress_email_collection == 18) {
			campaign.supress_homePage = false;
			campaign.supress_resultsPage = true;
			campaign.supress_orderPage = false;
			campaign.supress_locationPage = false;
			campaign.supress_locationSpecificPage = true;
		} else if (campaign.suppress_email_collection == 19) {
			campaign.supress_homePage = true;
			campaign.supress_resultsPage = true;
			campaign.supress_orderPage = false;
			campaign.supress_locationPage = false;
			campaign.supress_locationSpecificPage = true;
		} else if (campaign.suppress_email_collection == 20) {
			campaign.supress_homePage = false;
			campaign.supress_resultsPage = false;
			campaign.supress_orderPage = true;
			campaign.supress_locationPage = false;
			campaign.supress_locationSpecificPage = true;
		} else if (campaign.suppress_email_collection == 21) {
			campaign.supress_homePage = true;
			campaign.supress_resultsPage = false;
			campaign.supress_orderPage = true;
			campaign.supress_locationPage = false;
			campaign.supress_locationSpecificPage = true;
		} else if (campaign.suppress_email_collection == 22) {
			campaign.supress_homePage = false;
			campaign.supress_resultsPage = true;
			campaign.supress_orderPage = true;
			campaign.supress_locationPage = false;
			campaign.supress_locationSpecificPage = true;
		} else if (campaign.suppress_email_collection == 23) {
			campaign.supress_homePage = true;
			campaign.supress_resultsPage = true;
			campaign.supress_orderPage = true;
			campaign.supress_locationPage = false;
			campaign.supress_locationSpecificPage = true;
		} else if (campaign.suppress_email_collection == 24) {
			campaign.supress_homePage = false;
			campaign.supress_resultsPage = false;
			campaign.supress_orderPage = false;
			campaign.supress_locationPage = true;
			campaign.supress_locationSpecificPage = true;
		} else if (campaign.suppress_email_collection == 25) {
			campaign.supress_homePage = true;
			campaign.supress_resultsPage = false;
			campaign.supress_orderPage = false;
			campaign.supress_locationPage = true;
			campaign.supress_locationSpecificPage = true;
		} else if (campaign.suppress_email_collection == 26) {
			campaign.supress_homePage = false;
			campaign.supress_resultsPage = true;
			campaign.supress_orderPage = false;
			campaign.supress_locationPage = true;
			campaign.supress_locationSpecificPage = true;
		} else if (campaign.suppress_email_collection == 27) {
			campaign.supress_homePage = true;
			campaign.supress_resultsPage = true;
			campaign.supress_orderPage = false;
			campaign.supress_locationPage = true;
			campaign.supress_locationSpecificPage = true;
		} else if (campaign.suppress_email_collection == 28) {
			campaign.supress_homePage = false;
			campaign.supress_resultsPage = false;
			campaign.supress_orderPage = true;
			campaign.supress_locationPage = true;
			campaign.supress_locationSpecificPage = true;
		} else if (campaign.suppress_email_collection == 29) {
			campaign.supress_homePage = true;
			campaign.supress_resultsPage = false;
			campaign.supress_orderPage = true;
			campaign.supress_locationPage = true;
			campaign.supress_locationSpecificPage = true;
		} else if (campaign.suppress_email_collection == 30) {
			campaign.supress_homePage = false;
			campaign.supress_resultsPage = true;
			campaign.supress_orderPage = true;
			campaign.supress_locationPage = true;
			campaign.supress_locationSpecificPage = true;
		} else if (campaign.suppress_email_collection == 31) {
			campaign.supress_homePage = true;
			campaign.supress_resultsPage = true;
			campaign.supress_orderPage = true;
			campaign.supress_locationPage = true;
			campaign.supress_locationSpecificPage = true;
		}
	}

	deleteDataFromList(data: any) {
		const index: number = this.editingCampaign.campaignGroups.indexOf(data);
		this.deletedCampaignGroupMappings.push(data);
		if (index !== -1) {
			this.editingCampaign.campaignGroups.splice(index, 1);
		}
	}

	deleteDataFromCreateList(data: any) {
		const index: number = this._campaign.campaignGroups.indexOf(data);
		if (index !== -1) {
			this._campaign.campaignGroups.splice(index, 1);
		}
	}

	getCampaignGroupNameById(id: any): any {
		for (let x in this.campaignGroups) {
			if (this.campaignGroups[x].id == id) {
				return this.campaignGroups[x].name;
			}
		}
	}

	addDataToList(percentage, group) {
		if (group != null && group.id != null && group.name != null && percentage != null && percentage.length > 0 && !isNaN(percentage)) {
			group.split_percentage = parseInt(percentage);
			group.campaign_id = parseInt(this.editingCampaign.id);
			group.campaign_group_id = parseInt(group.id);
			delete group._links;
			delete group.id;
			delete group.base_cpc;
			this.editingCampaign.campaignGroups.push(group);
			this.percentage = '';
			this.group = {};
		} else {
			this.openSnackBar('Please enter split percentage and select a campaign group');
		}
	}

	addDataToNewCampaign(percentage, group) {
		if (group != null && group.id != null && group.name != null && percentage != null && percentage.length > 0 && !isNaN(percentage)) {
			group.split_percentage = parseInt(percentage);
			group.campaign_id = parseInt(this._campaign.id);
			group.campaign_group_id = parseInt(group.id);
			delete group._links;
			delete group.id;
			delete group.base_cpc;
			this._campaign.campaignGroups.push(group);
			this.percentageCreate = '';
			this.groupCreate = {};
		} else {
			this.openSnackBar('Please enter split percentage and select a campaign group');
		}
	}

	showEditCampaignGroup(campaignGroup: any) {
		campaignGroup.isEdit = true;
	}

	updateCampaignGroupMappings() {
		if (this.deletedCampaignGroupMappings && this.deletedCampaignGroupMappings.length > 0) {
			for (const x in this.deletedCampaignGroupMappings) {
				if (this.deletedCampaignGroupMappings[x] && this.deletedCampaignGroupMappings[x].id) {
					this.campaignGroupMappingService.deleteCampaignGroupMapping(this.deletedCampaignGroupMappings[x]).subscribe(
						res => {
						});
				}
			}
		}
		for (const i in this.editingCampaign.campaignGroups) {
			if (this.editingCampaign.campaignGroups[i] && this.editingCampaign.campaignGroups[i].id) {
				this.campaignGroupMappingService.updateCampaignGroupMapping(this.editingCampaign.campaignGroups[i]).subscribe(
					res => {
					});
			} else {
				this.campaignGroupMappingService.createNewCampaignGroupMapping(this.editingCampaign.campaignGroups[i]).subscribe(
					res => {
					});
			}
		}
	}
}
