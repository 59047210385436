import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {AuthService} from './auth.service';
import {AppConfigService} from './app-config.service';

@Injectable()
export class SupplierService {

	private baseUrl: string;
	private refreshCacheUrl: string;

	constructor(private http: HttpClient, private authService: AuthService, private environment: AppConfigService) {
		this.baseUrl = environment.config.apiBaseUrl + '/api/v1/supplier';
		this.refreshCacheUrl = environment.config.apiBaseUrl + '/api/cache/reload';
	}

	getAll(search: String): Observable<any> {
		return this.http.get(`${this.baseUrl}/findAllBySupplierNameContaining?name=${search}&page=1&size=1000`, this.authService.getOptions())
			.pipe(res => res);
	}

	getAllPg(search: String): Observable<any> {
		return this.http.get(`${this.baseUrl}/partner_api/findAllBySupplierNameContaining?name=${search}&page=1&size=1000`, this.authService.getOptions())
			.pipe(res => res);
	}
}
