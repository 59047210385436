// global imports
import {Component, OnInit, ElementRef, ViewChild, OnDestroy} from '@angular/core';
import {fromEvent} from 'rxjs';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';

import {MatPaginator, MatSort, MatSnackBar} from '@angular/material';
import {Loader} from '../../_models/loader';
import {MatTableDataSource} from '@angular/material/table';
import {BrandService} from '../../_services/brand-service';
import {Subscription} from 'rxjs/Rx';

@Component({
	selector: 'app-brand-cpc-editor',
	templateUrl: './brand-cpc-editor.component.html',
	styleUrls: ['./brand-cpc-editor.component.scss'],
})

export class BrandCpcEditorComponent implements OnInit, OnDestroy {

	loader: Loader;
	tableData;
	dataSource: BaseCpc[];

	filterType = 'brand_id';
	displayedHeader: string[] = ['Brand Id', 'Brand Name', 'Device Type', 'Placement Type', 'CPC', 'Action'];
	displayedColumns: string[] = ['brand_id', 'brand_name', 'device_type', 'placement_type', 'cpc', 'actions'];
	filterOptions = ['brand_id', 'brand_name', 'device_type', 'placement_type', 'cpc'];
	private subscriptions: Subscription[] = [];

	@ViewChild(MatSort, {static: true}) sort: MatSort;
	@ViewChild('searchInput', {static: true}) searchInput: ElementRef;

	constructor(private _brandService: BrandService,
				private router: Router,
				private activatedRoute: ActivatedRoute,
				public snackBar: MatSnackBar) {
	}

	ngOnInit() {
		this.loader = new Loader();
		this.fetchAll();
		this.subscriptions.push(this.setupFilter());
	}

	setupFilter(): Subscription {
		return fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
			debounceTime(50),
			distinctUntilChanged(),
			tap(() => {
				const filtered = this.dataSource.filter(b => {
					const param = b[this.filterType].toString().toLowerCase();
					if (b[this.filterType]) {
						return param.includes(this.searchInput.nativeElement.value.toString().toLowerCase());
					}
				});
				this.tableData = new MatTableDataSource(filtered);
			})
		).subscribe();
	}

	fetchAll(): void {
		this.loader.isLoading = true;
		this._brandService.getAllBrandCpc()
			.subscribe({
				next: res => {
					console.log('res ', res);
					this.dataSource = res.map(b => {
						b.cpc = b.cpc.toFixed(4);
						this._brandService.getBrandById(b.brand_id)
							.subscribe({
								next: result => {
									b.brand_name = result.name;
								}
							})
						return b;
					});

					this.tableData = new MatTableDataSource(this.dataSource);
					this.tableData.sort = this.sort;
					this.loader.isLoading = false;
				}, error: err => {
					this.loader.isLoading = false;
					this.openSnackBar('unknown server error occured', 'error');
					console.log('err ', err);
				}
			});
	}

	restartCache(): void {
		this.loader.isLoading = true;
		this._brandService.restartServerCache()
			.subscribe(
				res => {
					this.loader.isLoading = false;
				},
				err => {
					this.loader.isLoading = false;
					this.openSnackBar('unknown server error occured', 'error');
					console.log('err ', err);
				});
	}

	editBaseCpc(data) {
		console.log('test', data);
		const id = data.id;
		this.router.navigate([`/admin/update_cpc/edit/${id}`]);
	}

	openSnackBar(message: string, type) {
		this.snackBar.open(message, type, {
			duration: 10000,
		});
	}

	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}
}

export interface BaseCpc {
	brand_id: string;
	device_type: string;
	placement_type: string;
	cpc: number;
	brand_name: string;
	_links?: any;
}
