import {Pipe, PipeTransform} from '@angular/core'

@Pipe({name: 'queryTime'})
export class QueryTimePipe implements PipeTransform {
	transform(duration: number): string {
		let result = '';
		if (duration < 1000000) {
			const num_secs = duration / 1000;
			result = `${num_secs} s`;
		}
		return result;
	}
}
