import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {AuthService} from './auth.service';
import {AppConfigService} from './app-config.service';

@Injectable()
export class CampaignGroupService {
	private baseUrl: string;

	constructor(private http: HttpClient, private authService: AuthService, private environment: AppConfigService) {
		this.baseUrl = environment.config.apiBaseUrl + '/api/v1/campaign_group';
	}

	getCampaignGroups(): Observable<any> {
		return this.http.get(`${this.baseUrl}?size=1000`, this.authService.getOptions())
			.pipe(map(res => res));
	}

	getCampaignGroupDetails(id: number) {
		return this.http.get(`${this.baseUrl}/${id}`, this.authService.getOptions())
			.pipe(map(res => res));
	}

	createNewCampaignGroup(data: any) {
		return this.http.post(`${this.baseUrl}`, JSON.stringify(data), this.authService.getOptions())
			.pipe(map(res => res));
	}

	updateCampaignGroup(data: any, id: number) {
		return this.http.put(`${this.baseUrl}/${id}`, JSON.stringify(data), this.authService.getOptions())
			.pipe(map(res => res));
	}
}
