import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';
import {Vendor} from '../_models/vendor';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from './app-config.service';

@Injectable()
export class PaymentDetailService {

	private baseUrl: string;

	constructor(private http: HttpClient, private authService: AuthService, private environment: AppConfigService) {
		this.baseUrl = environment.config.apiBaseUrl + '/api/v1/payments';
	}

	getPaymentDetails(uuid: string) {
		return this.http.get(`${this.baseUrl}/${uuid}`, this.authService.getOptions())
			.pipe(res => res);
	}
}
