import {Component, OnInit} from '@angular/core';
import {AccountService} from '../../_services/account-service';
import {Loader} from '../../_models/loader';
import {AuthService} from '../../_services/auth.service';

@Component({
	selector: 'app-change-password',
	templateUrl: './change-password.component.html',
	styleUrls: ['./change-password.component.scss'],
})

export class ChangePasswordComponent implements OnInit {

	public loader: Loader;
	public errors: string[];
	public state: any;

	_oldPassword: string;
	_newPassword: string;
	_confirmPassword: string;

	constructor(private _accountService: AccountService, private _authService: AuthService) {
	}

	public submit() {
		this.loader.isLoading = true;

		this._accountService.changePassword(this._oldPassword, this._newPassword, this._confirmPassword)
			.subscribe(
				res => {
					console.log(res);
					this.loader.isLoading = false;
					if (res.status === 'FAILURE') {
						this.errors = res.errors;
					} else if (res.status === 'SUCCESS') {
						this._authService.logoutUser();
					}
				},
				err => {
					this.loader.isLoading = false;
					this.errors[0] = err;
				}
			);
	}

	ngOnInit() {
		this.loader = new Loader();
	}
}
