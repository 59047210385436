/**
 * Created by junzhang on 8/7/17.
 */
import {Injectable} from '@angular/core';

@Injectable()
export class Util {

	constructor() {
	}

	dateToIsoString(date: Date): string {
		if (date) {
			return date.toISOString().substring(0, 10);
		} else {
			return '';
		}
	}

	numbersOnly(val: string): string {
		return val.replace(/[^0-9\.]+/g, '');
	}

	getCookie(key: string): string {
		const value = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
		return value ? value[2] : null
	}

	validateEmail(email: string): boolean {
		if (email) {
			const re = /(?:[a-z0-9!#$%&'*+/=?^_{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;
			return re.test(email.trim());
		} else {
			return false;
		}
	}
}

