import {Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {Loader} from '../../_models/loader';
import {Subscription} from 'rxjs/Rx';
import {MatSnackBar} from '@angular/material';
import {SeoCarService} from '../../_services/seo-car-service';
import {SeoCarView, SeoCarFaq} from '../../_models/seoPage';

@Component({
	selector: 'app-seo-car-edit',
	templateUrl: './seo-car-edit.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class SeoCarEditComponent implements OnInit {

	errorMsg: string;
	seoCarForm: FormGroup;
	hasFormErrors = false;
	loader: Loader;
	id: string;
	seoCar: SeoCarView;
	faqs: SeoCarFaq[];
	formReady: Boolean = false;
	backlink = '/admin/seo-car-class';
	carImages = [];
	inputsFields = [
		{prop: 'name', label: 'Car Class'},
		{prop: 'h1', label: 'H1'},
		{prop: 'title', label: 'Meta-Title'},
		{prop: 'description', label: 'Meta-Description'},
		{prop: 'keywords', label: 'Meta-Keywords'},
		{prop: 'canonical', label: 'Meta-Canonical'},
		{prop: 'doors', label: 'Doors'},
		{prop: 'passengers', label: 'Passengers'},
		{prop: 'large_bags', label: 'Bags'},
		{prop: 'paragraph', label: 'Paragraph'}
	];

	constructor(private _SeoCarService: SeoCarService,
				private fb: FormBuilder,
				private cdRef: ChangeDetectorRef,
				public snackBar: MatSnackBar,
				private route: ActivatedRoute) {
	}

	ngOnInit() {
		this.loader = new Loader();
		this.setCarImages();
		this.id = this.route.snapshot.paramMap.get('id');
		if (this.id === 'new') {
			this.newSeoCar();
		} else {
			this.loader.isLoading = true;
			this.fetchDetail();
		}
	}

	setCarImages(): void {
		this.carImages = [
			'/a/cimg/arentals/MCAR-207x120.png',
			'/a/cimg/arentals/ECAR-207x120.png',
			'/a/cimg/arentals/CCAR-207x120.png',
			'/a/cimg/arentals/ICAR-207x120.png',
			'/a/cimg/arentals/SCAR-207x120.png',
			'/a/cimg/arentals/FCAR-207x120.png',
			'/a/cimg/arentals/PCAR-207x120.png',
			'/a/cimg/arentals/LCAR-207x120.png',
			'/a/cimg/arentals/XCAR-207x120.png',
			'/a/cimg/arentals/STAR-207x120.png',
			'/a/cimg/arentals/MVAR-207x120.png',
			'/a/cimg/arentals/SPAR-207x120.png',
			'/a/cimg/arentals/CFAR-207x120.png',
			'/a/cimg/arentals/IFAR-207x120.png',
			'/a/cimg/arentals/SFAR-207x120.png',
			'/a/cimg/arentals/FFAR-207x120.png',
			'/a/cimg/arentals/PFAR-207x120.png',
			'/a/cimg/arentals/LFAR-207x120.png'
		];
	}

	fetchDetail(): Subscription {
		return this._SeoCarService.fetchDetail(this.id).subscribe({
			next: res => {
				this.loader.isLoading = false;
				this.seoCar = res;
				this.faqs = this.seoCar.faqs;
				this.createForm();
				this.cdRef.detectChanges();
			}, error: err => {
				this.loader.isLoading = false;
				this.openSnackBar('unknown server error occured', 'error');
				console.log('err ', err);
			}
		});
	}

	newSeoCar() {
		this.seoCar = {
			id: '',
			name: '',
			active: false,
			h1: '',
			title: '',
			description: '',
			keywords: '',
			canonical: '',
			image_url: '',
			doors: '',
			passengers: '',
			large_bags: '',
			faqs: [],
			paragraph: ''
		};
		this.createForm();
	}

	createForm() {
		this.seoCarForm = this.fb.group({
			name: [this.seoCar.name, Validators.required],
			active: [this.seoCar.active, Validators.required],
			h1: [this.seoCar.h1, Validators.required],
			title: [this.seoCar.title, Validators.required],
			description: [this.seoCar.description, Validators.required],
			keywords: [this.seoCar.keywords, Validators.required],
			canonical: [this.seoCar.canonical, Validators.required],
			image_url: [this.seoCar.image_url, Validators.required],
			doors: [this.seoCar.doors, Validators.required],
			passengers: [this.seoCar.passengers, Validators.required],
			large_bags: [this.seoCar.large_bags, Validators.required],
			paragraph: [this.seoCar.paragraph, Validators.required]
		});
		this.formReady = true;
	}

	onSubmit() {
		this.hasFormErrors = false;
		const controls = this.seoCarForm.controls;

		if (this.seoCarForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			this.hasFormErrors = true;
			return;
		}

		const seoCar = this.prepareForm();

		this._SeoCarService.createOrUpdate(seoCar).subscribe({
			next: res => {
				this.seoCar = res;
				this.openSnackBar('Updated', 'success');
			}, error: err => {
				this.openSnackBar('Server Error', 'error');
				console.log('test', err);
			}
		});
	}

	prepareForm(): SeoCarView {
		const controls = this.seoCarForm.controls;
		this.inputsFields.forEach(i => {
			this.seoCar[i.prop] = controls[i.prop].value
		});
		this.seoCar['active'] = controls['active'].value;
		this.seoCar['image_url'] = controls['image_url'].value;
		return this.seoCar;
	}

	openSnackBar(message: string, type) {
		this.snackBar.open(message, type, {
			duration: 10000,
		});
	}

}
