import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {AuthService} from './auth.service';
import {AppConfigService} from './app-config.service';

@Injectable()
export class CampaignGroupMappingService {
	private baseUrl: string;

	constructor(private http: HttpClient, private authService: AuthService, private environment: AppConfigService) {
		this.baseUrl = environment.config.apiBaseUrl + '/api/campaign_group_mapping';
	}

	getCampaignGroupMappings(): Observable<any> {
		return this.http.get(`${this.baseUrl}`, this.authService.getOptions())
			.pipe(map(res => res));
	}

	createNewCampaignGroupMapping(data: any) {
		return this.http.post(`${this.baseUrl}`, JSON.stringify(data), this.authService.getOptions())
			.pipe(map(res => res));
	}

	updateCampaignGroupMapping(data: any) {
		return this.http.put(`${this.baseUrl}/${data.id}`, JSON.stringify(data), this.authService.getOptions())
			.pipe(map(res => res));
	}

	deleteCampaignGroupMapping(data: any) {
		return this.http.delete(`${this.baseUrl}/${data.id}`, this.authService.getOptions())
			.pipe(map(res => res));
	}
}
