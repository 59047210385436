import {Component, OnInit} from '@angular/core';
import {VendorXml} from '../../_models/vendorXml';
import {SessionDebugService} from '../../_services/session-debug-service';
import * as vkbeautify from 'vkbeautify';
import {DataTableResource} from 'angular5-data-table';
import {ExcelService} from '../../_services/excel-service';
import {ReservationService} from '../../_services/reservation-service';
import {Loader} from '../../_models/loader';

@Component({
	selector: 'app-session-debug',
	templateUrl: './session-debug.component.html',
	styleUrls: ['./session-debug.component.scss'],
})

export class SessionDebugComponent implements OnInit {

	_sessionId: string;
	filterBy: string;
	vendorXmls: VendorXml[];
	errorMsg: string;
	sessionDebugService: SessionDebugService;
	reservationService: ReservationService;
	excelService: ExcelService;
	_displaySection: string;
	_textRequestContent: string;
	_textResponseContent: string;
	_tempUrl: string;
	_textRequestContentType: string;
	_textResponseContentType: string;
	_textContentRequestDisplay: string;
	_textContentResponseDisplay: string;
	userDetails: any[];
	reservationDetails: any[];
	debugEvents: any[];
	sessionDetails: any[];
	searchDetails: any[];
	clicks: any[];
	searchTerm: string;
	loader: Loader;
	showVendorXmlsError: boolean;
	sessionDetailsError: boolean;
	userDetailsError: boolean;
	reservationDetailsError: boolean;
	debugEventsError: boolean;
	searchDetailsError: boolean;
	clicksError: boolean;

	// for data-table
	_xmlResource: DataTableResource<VendorXml>;
	_items: VendorXml[];
	_itemCount: number;

	query = {
		'first_name': '',
		'last_name': '',
		'email': '',
		'reservation_id': '',
		'brand_name': '',
		'vendor_name': '',
		'vendor_confirmation': '',
		'brand_confirmation': '',
		'car_type': '',
		'is_cancelled': '',
		'reservation_date1': null,
		'reservation_date2': null,
		'pickup_date1': null,
		'pickup_date2': null,
		'dropoff_date1': null,
		'dropoff_date2': null,
		'uuid': ''
	};

	constructor(sessionDebugService: SessionDebugService, excelService: ExcelService, reservationService: ReservationService) {
		this.sessionDebugService = sessionDebugService;
		this.excelService = excelService;
		this.reservationService = reservationService;
		this.vendorXmls = [];
		this._displaySection = 'none';
		this._textRequestContent = '';
		this._textContentRequestDisplay = '';
		this._textContentResponseDisplay = '';

		this._xmlResource = null;
		this._items = [];
		this._itemCount = 0;
		this.userDetails = [];
		this.reservationDetails = [];
		this.debugEvents = [];
		this.sessionDetails = [];
		this.searchDetails = [];
		this.clicks = [];
		this.filterBy = 'JSessionId';
		this.searchTerm = '';
		this.showVendorXmlsError = false;
		this.sessionDetailsError = false;
		this.userDetailsError = false;
		this.reservationDetailsError = false;
		this.debugEventsError = false;
		this.searchDetailsError = false;
		this.clicksError = false;
	}

	ngOnInit() {
		this.loader = new Loader();
	}

	getVendorXmls(): void {
		if (this.checkForm(this.searchTerm)) {
			this.userDetails = [];
			this.reservationDetails = [];
			this.debugEvents = [];
			this.sessionDetails = [];
			this.searchDetails = [];
			this.vendorXmls = [];
			this.clicks = [];
			// this._items = [];
			this.errorMsg = '';
			this.showVendorXmlsError = false;
			this.sessionDetailsError = false;
			this.userDetailsError = false;
			this.reservationDetailsError = false;
			this.debugEventsError = false;
			this.searchDetailsError = false;
			this.clicksError = false;

			if (this.filterBy == 'ReservationConfirmation' || this.filterBy == 'ReservationId') {
				this.reservationService
					.getReservations(this.getQuery(this.searchTerm))
					.subscribe(c => {
						if (c != null && c.content[0] != null) {
							this._sessionId = c.content[0].session_id;
							this.getSessionDetailsBySessionId();
							this.getVendorXmlDetailsBySessionId();
						} else {
							this.errorMsg = 'No records found';
						}
					}, err => {
						this.errorMsg = err;
					})
			} else if (this.filterBy == 'JSessionId') {
				this.sessionDebugService.getSessionDetailsFromJsessionId(this.searchTerm)
					.subscribe(res => {
						if (res != null) {
							this._sessionId = res.id;
							this.getSessionDetailsBySessionId();
							this.getVendorXmlDetailsBySessionId();
						} else {
							this.errorMsg = 'No records found';
						}
					}, err => {
						this.errorMsg = 'No records found';
					})
			} else {
				this._sessionId = this.searchTerm;
				this.getSessionDetailsBySessionId();
				this.getVendorXmlDetailsBySessionId();
			}
		}
	}

	getQuery(searchTerm): any {
		if (this.filterBy == 'ReservationConfirmation') {
			this.query.vendor_confirmation = searchTerm;
			this.query.brand_confirmation = searchTerm;
		} else if (this.filterBy == 'ReservationId') {
			this.query.reservation_id = searchTerm;
		}
		return this.query;
	}

	getSessionDetailsBySessionId(): void {
		if (this.checkForm(this._sessionId)) {
			this.loader.isLoading = true;
			this.sessionDebugService
				.getSessionResponse(this._sessionId)
				.subscribe(res => {
					if (res.session != null) {
						this.sessionDetails.push(res.session);
					} else {
						this.sessionDetailsError = true;
					}

					if (res.users != null) {
						this.userDetails.push(res.users);
					} else {
						this.userDetailsError = true;
					}

					if (res.reservation_list != null) {
						this.reservationDetails = res.reservation_list;
					} else {
						this.reservationDetailsError = true;
					}
					this.debugEvents = res.debug_events;
					if (this.debugEvents.length == 0) {
						this.debugEventsError = true;
					}
					this.searchDetails = res.search_list;
					if (this.searchDetails.length == 0) {
						this.searchDetailsError = true;
					}
					this.clicks = res.cliks;
					if (this.clicks.length == 0) {
						this.clicksError = true;
					}
					this.loader.isLoading = false;
				}, err => {
					this.errorMsg = err;
					this.loader.isLoading = false;
				})
		}
	}

	getVendorXmlDetailsBySessionId() {
		this.sessionDebugService
			.getVendorXmls(this._sessionId)
			.subscribe(c => {
				this.vendorXmls = c;
				this._displaySection = 'list';
				this._xmlResource = new DataTableResource<VendorXml>(this.vendorXmls);
				this._xmlResource.count().then(count => this._itemCount = count);
				if (this.vendorXmls.length == 0) {
					this.showVendorXmlsError = true;
				}
			}, err => {
				this.errorMsg = err;
				this.loader.isLoading = false;
			});
	}

	reloadItems(params) {
		this._xmlResource.query(params).then(items => this._items = items);
	}

	checkForm(sessionId: string): boolean {
		if (!sessionId || sessionId.length === 0) {
			if (this.filterBy == 'ReservationConfirmation') {
				this.errorMsg = 'Please Enter a Reservation Confirmation number';
				return false;
			} else if (this.filterBy == 'SessionId') {
				this.errorMsg = 'Please Enter a Session ID';
				return false;
			} else {
				this.errorMsg = 'Please Enter a Reservation ID';
				return false;
			}
		}
		return true;
	}

	validForm(): boolean {
		if (!this._sessionId) {
			return false;
		}
		return true;
	}

	displayRequestContent(xmlId: number, datasource: string): void {
		this.sessionDebugService
			.getSingleVendorRequest(xmlId, datasource)
			.subscribe(c => {
				this._textRequestContent = c.request;
				this._textResponseContent = c.response;
				this._displaySection = 'content';
				this.checkRequestContentType();
				this.checkResponseContentType();
			});
	}

	saveVendorRequestResponse(): void {
		const blobData = new Blob(['Request :', '\n \n', this._textContentRequestDisplay, '\n \n \n', 'Response :', '\n \n', this._textContentResponseDisplay], {type: 'application/octet-stream'});

		if (this._tempUrl) {
			window.URL.revokeObjectURL(this._tempUrl);
		}

		this._tempUrl = window.URL.createObjectURL(blobData);
		window.open(this._tempUrl);
	}

	saveVendorResponse(): void {
		const blobData = new Blob([this._textResponseContent], {type: 'application/octet-stream'});

		if (this._tempUrl) {
			window.URL.revokeObjectURL(this._tempUrl);
		}

		this._tempUrl = window.URL.createObjectURL(blobData);
		window.open(this._tempUrl);
	}

	saveVendorRequest(): void {
		const blobData = new Blob([this._textRequestContent], {type: 'application/octet-stream'});

		if (this._tempUrl) {
			window.URL.revokeObjectURL(this._tempUrl);
		}

		this._tempUrl = window.URL.createObjectURL(blobData);
		window.open(this._tempUrl);
	}

	displayVendorXmlList(): void {
		this._displaySection = 'list';
	}

	private checkRequestContentType(): void {
		if (this._textRequestContent.startsWith('{') || this._textRequestContent.startsWith('[')) {
			this._textRequestContentType = 'json';
			this._textContentRequestDisplay = vkbeautify.json(this._textRequestContent);
		} else if (this._textRequestContent.startsWith('<')) {
			this._textRequestContentType = 'xml';
			this._textContentRequestDisplay = vkbeautify.xml(this._textRequestContent);
		} else {
			this._textRequestContentType = '';
			this._textContentRequestDisplay = this._textRequestContent;
		}
	}

	private checkResponseContentType(): void {
		if (this._textResponseContent.startsWith('{')) {
			this._textResponseContentType = 'json';
			this._textContentResponseDisplay = vkbeautify.json(this._textResponseContent);
		} else if (this._textResponseContent.startsWith('<')) {
			this._textResponseContentType = 'xml';
			this._textContentResponseDisplay = vkbeautify.xml(this._textResponseContent);
		} else {
			this._textResponseContentType = '';
			this._textContentResponseDisplay = this._textResponseContent;
		}
	}

	copyToClipBoard(item) {
		document.addEventListener('copy', (e: ClipboardEvent) => {
			e.clipboardData.setData('text/plain', (item));
			e.preventDefault();
			document.removeEventListener('copy', null);
		});
		document.execCommand('copy');
	}

	exportAsXLSX(data, fileName): void {
		this.excelService.exportAsExcelFile(data, fileName + '_' + this._sessionId);
	}
}
