export interface ICampaign {
	id: number;
	name: string;
	old_total_expense: string;
	currentStartDate: string;
	currentEndDate: string;
	new_total_expense: string;
	session_cpc_mean: number;
	session_cpc_std_dev: number;
	session_cpc_min: number;
	session_cpc_max: number;
	session_count: number;
}

export class Campaign implements ICampaign {
	id: number;
	name: string;
	old_total_expense: string;
	new_total_expense: string;
	currentStartDate: string;
	currentEndDate: string;
	session_cpc_mean: number;
	session_cpc_std_dev: number;
	session_cpc_min: number;
	session_cpc_max: number;
	session_count: number;

	constructor(id: number, name: string) {
		this.id = id;
		this.name = name;
	}
}

