import {Component, OnInit} from '@angular/core';
import {PartnerService} from '../../_services/partner-service';
import {MatSnackBar} from '@angular/material';
import {MarketingGroupService} from '../../_services/marketing-group-service';
import {AuthService} from '../../_services/auth.service';

@Component({
	selector: 'app-partner-tool',
	templateUrl: './partner-tool.component.html',
	styleUrls: ['./partner-tool.component.scss'],
})
export class PartnerToolComponent implements OnInit {

	partners: any[];
	partnerServie: PartnerService;
	marketingGroupService: MarketingGroupService;
	_searchterm: string;
	errorMsg: string;
	showPopUP: boolean;
	showEditPopUP: boolean;
	partner: any;
	editPartner: any;
	partnerCreated: boolean;
	partnerCreationError: string;
	marketingGroups: any[];
	marketingGroupShortNames: string[];
	partnerUpdate: boolean;
	isAdmin: boolean;
	isLoading: boolean;

	constructor(partnerServie: PartnerService, marketingGroupService: MarketingGroupService,
				public snackBar: MatSnackBar, public authService: AuthService) {
		this.partners = [];
		this.partnerServie = partnerServie;
		this.errorMsg = '';
		this.showPopUP = false;
		this.showEditPopUP = false;
		this.partnerCreated = false;
		this.partnerUpdate = false;
		this.partnerCreationError = '';
		this.marketingGroupService = marketingGroupService;
		this.marketingGroupShortNames = [];
		this.editPartner = {};
		this.isAdmin = false;
		this.isLoading = false;
	}

	ngOnInit() {
		this.isAdmin = this.authService.isAdmin();
		this.getAllPartners();
		this.campaignInit();
		this.getMarketingGroupParams();
	}

	campaignInit() {
		this.partner = {
			short_name: '',
			corporate_name: null,
			address1: null,
			address2: null,
			city: null,
			state_short: null,
			postal_code: null,
			country: null,
			contact_name: null,
			contact_phone: null,
			contact_email: null,
			marketing_group: '',
			enum_name: null,
			external_params: null
		};

		this.editPartner = {
			short_name: '',
			corporate_name: null,
			address1: null,
			address2: null,
			city: null,
			state_short: null,
			postal_code: null,
			country: null,
			contact_name: null,
			contact_phone: null,
			contact_email: null,
			marketing_group: '',
			enum_name: null,
			external_params: null
		};
	}

	getAllPartners() {
		this.errorMsg = '';
		this.partnerServie.getPartners()
			.subscribe(
				res => {
					this.partners = res;
					if (this.partners.length == 0) {
						this.errorMsg = 'No partners found';
					}
				}, err => {
					this.errorMsg = err;
				}
			)
	}

	getMarketingGroupParams() {
		this.marketingGroupService.getAll()
			.subscribe(
				res => {
					this.marketingGroups = res;
					for (const marketingGroup in this.marketingGroups) {
						this.marketingGroupShortNames.push(this.marketingGroups[marketingGroup].short_name);
					}
				}
			)
	}

	filterPartners() {
		if (this._searchterm != null && this._searchterm.length > 0) {
			this.partnerServie.filterReservations(this._searchterm)
				.subscribe(
					res => {
						this.partners = res;
						if (this.partners.length == 0) {
							this.errorMsg = 'No partners found';
						}
					}, err => {
						this.errorMsg = err;
					}
				)
		} else {
			this.getAllPartners()
		}
	}

	showPopUp() {
		this.showPopUP = true;
		this.campaignInit();
	}

	closePopUp() {
		this.showPopUP = false;
		this.campaignInit();
		this.partnerCreated = false;
	}

	showEdit(id: number) {
		this.showEditPopUP = true;
		this.partnerServie.getPartnerDetails(id)
			.subscribe(
				res => {
					this.editPartner = res;
					this.editPartner.id = id;
				},
				err => {
					this.showEditPopUP = false;
					this.errorMsg = err;
				}
			)
	}

	closeEditPopUp() {
		this.showEditPopUP = false;
		this.editPartner = {};
		this.partnerUpdate = false;
	}

	updatePartner() {
		var id: number = this.editPartner.id;
		this.partnerServie.updatePartner(this.editPartner, id)
			.subscribe(
				res => {
					if (res != null) {
						this.editPartner = res;
						this.editPartner.id = id;
						this.partnerUpdate = true;
						this.getAllPartners();
					}
				}, err => {
					this.openSnackBar(err);
				}
			)
	}

	savePartner() {
		console.log('clicked ');
		if (this.validatePartner()) {
			this.isLoading = true;
			this.partnerServie.createNewPartner(this.partner)
				.subscribe(
					res => {
						if (res != null) {
							this.partner = res;
							this.partnerCreated = true;
							this.getAllPartners();
						}
						this.isLoading = false;
					}, err => {
						this.isLoading = false;
						this.openSnackBar(err.message);
					}
				)
		} else {
			this.openSnackBar('Please fill out mandatory fields to create partner');
		}
	}

	validatePartner() {
		if (this.partner == null) {
			return false;
		} else {
			if (this.partner.short_name == null || this.partner.short_name.length === 0) {
				return false;
			}
			if (this.partner.marketing_group == null || this.partner.marketing_group.length === 0) {
				return false;
			}
		}
		return true;
	}

	openSnackBar(message: string) {
		this.snackBar.open(message, '', {
			duration: 5000,
		});
	}
}
