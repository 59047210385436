import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';
import {Vendor} from '../_models/vendor';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from './app-config.service';

@Injectable()
export class VendorService {
	private baseUrl: string;
	private refreshCacheUrl: string;

	constructor(private http: HttpClient, private authService: AuthService, private environment: AppConfigService) {
		this.baseUrl = environment.config.apiBaseUrl + '/api/v1/vendors';
		this.refreshCacheUrl = environment.config.apiBaseUrl + '/api/cache/reload';
	}

	getAll(): Observable<any> {
		return this.http.get<any>(`${this.baseUrl}?size=1000`, this.authService.getOptions());
	}

	restartServerCache(): any {
		return this.http.get<any>(this.refreshCacheUrl, this.authService.getOptions());
	}

	updateVendor(vendor: Vendor): Observable<Vendor> {
		return this.http.put<Vendor>(`${this.baseUrl}/${vendor.id}`, JSON.stringify(vendor),
			this.authService.getOptions());
	}

	getVendorById(id: number) {
		return this.http.get<any>(`${this.baseUrl}/${id}`, this.authService.getOptions());
	}
}
