export interface ILoader {
	isLoading: boolean;
	loadingMessage: string;
}

export class Loader implements ILoader {
	isLoading: boolean;
	loadingMessage: string;

	constructor() {
		this.isLoading = false;
	}
}
