// global imports
import {Component, OnInit, ElementRef, ViewChild, OnDestroy, AfterViewChecked, AfterViewInit} from '@angular/core';
import {Subscription} from 'rxjs/Rx';
import {MatPaginator, MatSort, MatSnackBar, Sort} from '@angular/material';
import {Loader} from '../../_models/loader';
import {Router} from '@angular/router';
import {MatTableDataSource} from '@angular/material/table';
// local imports
import {ReservationService} from '../../_services/reservation-service';
import {BrandService} from '../../_services/brand-service';
import {VendorService} from '../../_services/vendor-service';
import {ReservationView} from '../../_models/reservation'
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
	selector: 'app-reservation-tool',
	templateUrl: './reservation-tool.component.html',
	styleUrls: ['./reservation-tool.component.scss'],
})

export class ReservationToolComponent implements OnInit {
	noFilter = 'Search All';
	loader: Loader;
	tableData;
	reservations: ReservationView[] = [];
	pageEvent: Subscription;
	searchForm: FormGroup;
	totalResults = 0;
	filterState = {
		brand: this.noFilter,
		vendor: this.noFilter,
		carType: this.noFilter,
		cancelled: this.noFilter,
		puDate1: null,
		puDate2: null,
		doDate1: null,
		doDate2: null,
		createdDate1: null,
		createdDate2: null
	};
	query = {
		'first_name': '',
		'last_name': '',
		'email': '',
		'brand_name': '',
		'vendor_name': '',
		'vendor_confirmation': '',
		'brand_confirmation': '',
		'car_type': '',
		'is_cancelled': '',
		'reservation_date1': null,
		'reservation_date2': null,
		'pickup_date1': null,
		'pickup_date2': null,
		'dropoff_date1': null,
		'dropoff_date2': null,
		'uuid': ''
	};
	formReady = false;
	isAdmin = true;
	inputsFields = [
		{prop: 'first_name', label: 'First Name'},
		{prop: 'last_name', label: 'Last Name'},
		{prop: 'email', label: 'Email Address'},
		{prop: 'vendor_confirmation', label: 'Vendor Confirmation'},
		{prop: 'brand_confirmation', label: 'Brand Confirmation'},
	];
	displayedHeader: string[] = ['Email Address', 'Name', 'Created', 'Car', 'Vendor', 'Brand',
		'Vendor C#', 'Pickup', 'Dropoff', 'Pickup Date', 'Dropoff Date', 'Cancelled'];
	displayedColumns: string[] = ['email', 'name', 'createdDate', 'carType', 'vendor', 'brand',
		'confirmation', 'loc1', 'loc2', 'date1', 'date2', 'cancelled'];
	brandFilter = [];
	vendorFilter = [];
	carTypeFilter = [this.noFilter, 'Economy', 'Compact', 'Intermediate', 'Supplier Choice', 'Standard', 'Full Size', 'MiniVan',
		'Intermediate SUV', 'Standard SUV', 'Convertible', 'Pickup Truck', 'Economy SUV', 'Compact SUV', 'Full Size SUV', 'Mini',
		'Premium', 'Luxury', 'Special', 'Full Size Van', 'Van', 'Cargo Van', 'Special SUV', 'Premium SUV', 'Luxury SUV']
	cancelledFilter = [this.noFilter, 'true', 'false'];

	@ViewChild(MatSort, {static: false}) sort: MatSort;
	@ViewChild('searchInput', {static: true}) searchInput: ElementRef;
	@ViewChild(MatPaginator, {static: false}) paginatorTop: MatPaginator;
	@ViewChild(MatPaginator, {static: false}) paginatorBot: MatPaginator;

	constructor(public snackBar: MatSnackBar,
				private router: Router,
				private _vendorService: VendorService,
				private _brandService: BrandService,
				private fb: FormBuilder,
				private _reservationService: ReservationService) {
	}

	ngOnInit() {
		this.loader = new Loader();
		this.createForm();
		this.fetchVendorAndBrand();
	}

	clearFilter() {
		this.filterState = {
			brand: this.noFilter,
			vendor: this.noFilter,
			carType: this.noFilter,
			cancelled: this.noFilter,
			puDate1: null,
			puDate2: null,
			doDate1: null,
			doDate2: null,
			createdDate1: null,
			createdDate2: null
		};
		this.searchForm.reset();
		this.createForm();
	}

	newPage() {
		this.paginatorTop.pageIndex = 0;
		this.paginatorBot.pageIndex = 0;
		this.fetch();
	}

	fetch() {
		const query = this.getQuery();
		if (this.valid(query)) {
			this.loader.isLoading = true;
			return this._reservationService.queryReservations(query, this.paginatorTop.pageIndex)
				.subscribe({
					next: res => {
						this.reservations = this.process(res.content);
						this.tableData = new MatTableDataSource(this.reservations);
						this.initSort();
						this.totalResults = res.total_elements;
						this.loader.isLoading = false;
					}, error: err => {
						this.openSnackBar('unknown server error occured', 'error');
						this.loader.isLoading = false;
					}
				});
		} else {
			this.openSnackBar('Please enter at least one search field to start search', 'error');
		}
	}

	valid(query: any): boolean {
		let isValid = false;
		Object.keys(query).map(key => {
			if (query[key] !== '' && query[key] !== ' ' && query[key] !== null) {
				isValid = true;
			}
		})
		return isValid;
	}

	fetchVendorAndBrand(): void {
		this._vendorService.getAll()
			.subscribe({
				next: res => {
					this.vendorFilter = res._embedded.vendors
				}
			});
		this._brandService.getAll()
			.subscribe({
				next: res => {
					this.brandFilter = [...new Set(res)];
				}
			})
	}

	openSnackBar(message: string, type) {
		this.snackBar.open(message, type, {
			duration: 10000,
		});
	}

	initSort(): void {
		this.tableData.sortingDataAccessor = (item, property) => {
			switch (property) {
				case 'createdDate':
					return new Date(item.createdDate);
				case 'date1':
					return new Date(item.date1);
				case 'date2':
					return new Date(item.date2);
				default:
					return item[property];
			}
		};
		this.tableData.sort = this.sort;
	}

	detail(data) {
		this.router.navigate([`/admin/reservations/detail/${data.uuid}`]);
	}

	process(res): any[] {
		return res.map(r => {
			return {
				name: r.first_name + ' ' + r.last_name,
				email: r.email,
				createdDate: new Date(r.created_date).toLocaleDateString(),
				vendor: r.vendor_name,
				brand: r.brand_name,
				confirmation: r.vendor_confirmation_code,
				date1: new Date(r.pickup_date).toLocaleDateString(),
				date2: new Date(r.dropoff_date).toLocaleDateString(),
				loc1: r.pickup_location,
				loc2: r.dropoff_location ? r.dropoff_location : 'Same',
				cancelled: r.cancelled.toString(),
				carType: r.car_type,
				uuid: r.uuid
			}
		});
	}

	createForm(): void {
		this.searchForm = this.fb.group({
			first_name: '',
			last_name: '',
			email: '',
			vendor_confirmation: '',
			brand_confirmation: ''
		});
		this.formReady = true;
	}

	getQuery(): any {
		this.query.first_name = this.searchForm.value.first_name;
		this.query.last_name = this.searchForm.value.last_name;
		this.query.email = this.searchForm.value.email;
		this.query.vendor_confirmation = this.searchForm.value.vendor_confirmation;
		this.query.brand_confirmation = this.searchForm.value.brand_confirmation;
		this.query.brand_name = (this.filterState.brand === this.noFilter) ? '' : this.filterState.brand;
		this.query.vendor_name = (this.filterState.vendor === this.noFilter) ? '' : this.filterState.vendor;
		this.query.is_cancelled = (this.filterState.cancelled === this.noFilter) ? '' : this.filterState.cancelled;
		this.query.car_type = (this.filterState.carType === this.noFilter) ? '' : this.filterState.carType;
		this.query.pickup_date1 = this.setDate(this.filterState.puDate1);
		this.query.pickup_date2 = this.setDate(this.filterState.puDate2);
		this.query.dropoff_date1 = this.setDate(this.filterState.doDate1);
		this.query.dropoff_date2 = this.setDate(this.filterState.doDate2);
		this.query.reservation_date1 = this.setDate(this.filterState.createdDate1);
		this.query.reservation_date2 = this.setDate(this.filterState.createdDate2);
		return this.query;
	}

	setDate(state): void {
		return state ? state.getTime() : null;
	}

	export() {
		this._reservationService.exportReservations(this.getQuery(), this.paginatorTop.pageIndex)
			.subscribe({
				next: res => {
					try {
						this.downloadFile(this.convertToCSV(this.processCSV(res)));
					} catch (e) {
						console.log('error ', e);
						this.openSnackBar('unable to export', 'error');
					}
				}, error: err => {
					this.openSnackBar('unknown server error occured', 'error');
				}
			});
	}

	convertToCSV(reservations: any[]) {
		let csv = '';
		let row = 'NO.,';
		const nextLine = '\r\n';
		const props = Object.keys(reservations[0]);

		props.forEach(h => {
			row += h + ',';
		})
		row = row.substring(0, row.length - 1);
		csv += row + nextLine;

		for (let i = 0; i < reservations.length; i++) {
			let line = (i + 1) + '';
			props.forEach(p => {
				line += ',' + reservations[i][p];
			})
			csv += line + nextLine;
		}
		return csv;
	}

	downloadFile(csv) {
		const blob = new Blob(['\ufeff' + csv], {type: 'text/csv;charset=utf-8;'});
		const download = document.createElement('a');
		const url = URL.createObjectURL(blob);
		const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
		if (isSafariBrowser) {
			download.setAttribute('target', '_blank');
		}
		download.setAttribute('href', url);
		download.setAttribute('download', 'reservations.csv');
		download.style.visibility = 'hidden';
		document.body.appendChild(download);
		download.click();
		document.body.removeChild(download);
	}

	processCSV(res): any[] {
		res.forEach(r => {
			r.created_date = new Date(r.created_date).toLocaleDateString();
			r.pickup_date = new Date(r.pickup_date).toLocaleDateString();
			r.dropoff_date = new Date(r.dropoff_date).toLocaleDateString();
			if (r.cancel_time) {
				r.cancel_time = new Date(r.cancel_time).toLocaleDateString();
			}
		})
		return res;
	}

	sortData(sort: Sort) {
		const data = this.reservations.slice();
		if (!sort.active || sort.direction == '') {
			this.tableData = data;
			return;
		}

		this.tableData = data.sort((a, b) => {
			let isAsc = sort.direction == 'asc';
			switch (sort.active) {
				case 'email':
					return this.compare(a.email, b.email, isAsc);
				case 'name':
					return this.compare(+a.name, +b.name, isAsc);
				case 'createdDate':
					return this.compare(+a.createdDate, +b.createdDate, isAsc);
				case 'carType':
					return this.compare(+a.carType, +b.carType, isAsc);
				case 'vendor':
					return this.compare(+a.vendor, +b.vendor, isAsc);
				case 'brand':
					return this.compare(+a.brand, +b.brand, isAsc);
				case 'confirmation':
					return this.compare(+a.confirmation, +b.confirmation, isAsc);
				case 'loc1':
					return this.compare(+a.loc1, +b.loc1, isAsc);
				case 'loc2':
					return this.compare(+a.loc2, +b.loc2, isAsc);
				case 'date1':
					return this.compare(+a.date1, +b.date1, isAsc);
				case 'date2':
					return this.compare(+a.date2, +b.date2, isAsc);
				case 'cancelled':
					return this.compare(+a.cancelled, +b.cancelled, isAsc);
				default:
					return 0;
			}
		});
	}


	compare(a, b, isAsc) {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}
}

