import {Component, OnInit} from '@angular/core';
import {PartnerApiService} from '../../_services/partner-api-service';
import {Util} from '../../_utils/util';
import {Loader} from '../../_models/loader';
import {MatSnackBar, MatTableDataSource, Sort} from '@angular/material';
import {AuthService} from '../../_services/auth.service';

@Component({
	selector: 'app-partner-location-update',
	templateUrl: './location-update.component.html',
	styleUrls: ['./location-update.component.scss'],
})

export class PartnerLocationUpdateComponent implements OnInit {

	public brandLocations;
	public brandLocation: any;
	public loader: Loader;
	public isAdmin: boolean;
	showConfirmationModal: boolean;
	totalResults = 0;
	tableData;
	displayedHeader: string[] = ['Vendor', 'Vendor Id', 'Brand', 'Brand Id', 'Batch Id', 'Timestamp', 'Unprocessed Locations', 'Action'];
	displayedColumns: string[] = ['vendor_name', 'vendor_id', 'brand_name', 'brand_id', 'batch_id', 'event_date', 'record_count', 'action'];

	constructor(private _apiService: PartnerApiService, private _util: Util, public snackBar: MatSnackBar, public authService: AuthService) {
		this.isAdmin = false;
	}

	ngOnInit() {
		this.loader = new Loader();
		this.getBrandLocations();
		this.isAdmin = this.authService.isAdmin();
		this.showConfirmationModal = false;
		this.brandLocation = {};
	}

	openSnackBar(message: string, action: string) {
		this.snackBar.open(message, action, {
			duration: 4000,
		});
	}

	private getBrandLocations(): void {
		this.loader.isLoading = true;
		this._apiService.getLocationBatches()
			.subscribe(
				res => {
					this.loader.isLoading = false;
					this.brandLocations = res;
					this.tableData = new MatTableDataSource(this.brandLocations);
					this.totalResults = this.brandLocations.length;
				},
				err => {
					this.loader.isLoading = false;
				});
	}

	update(location) {
		this.showConfirmationModal = true;
		this.brandLocation = location;
	}

	closePopUp() {
		this.brandLocation = {};
		this.showConfirmationModal = false;
	}

	public updateLocation(): void {
		this.loader.isLoading = true;

		this._apiService.updateLocation(this.brandLocation)
			.subscribe(
				res => {
					this.getBrandLocations();
					this.openSnackBar('Updated', 'Success');
				},
				err => {
					this.loader.isLoading = false;
					this.openSnackBar('Error while running location update.', 'Error');
				}
			)
		this.closePopUp();
	}

	sortData(sort: Sort) {
		const data = this.brandLocations.slice();
		if (!sort.active || sort.direction === '') {
			this.tableData = data;
			return;
		}

		this.tableData = data.sort((a, b) => {
			const isAsc = sort.direction === 'asc';
			switch (sort.active) {
				case 'vendor_name':
					return this.compare(a.vendor_name, b.vendor_name, isAsc);
				case 'vendor_id':
					return this.compare(+a.vendor_id, +b.vendor_id, isAsc);
				case 'brand_name':
					return this.compare(+a.brand_name, +b.brand_name, isAsc);
				case 'brand_id':
					return this.compare(+a.brand_id, +b.brand_id, isAsc);
				case 'batch_id':
					return this.compare(+a.batch_id, +b.batch_id, isAsc);
				case 'event_date':
					return this.compare(+a.event_date, +b.event_date, isAsc);
				case 'record_count':
					return this.compare(+a.record_count, +b.record_count, isAsc);
				default:
					return 0;
			}
		});
	}


	compare(a, b, isAsc) {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}
}
