import {Component, OnInit} from '@angular/core';
import {Loader} from '../../_models/loader';
import {MatSnackBar} from '@angular/material';

@Component({
	selector: 'app-dashboard-request',
	templateUrl: './dashboard-request.html'
})

export class DashboardRequestComponent implements OnInit {
	public loader: Loader;

	constructor(public snackBar: MatSnackBar) {
	}

	ngOnInit() {
	}
}
