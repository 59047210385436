import {Component, OnInit, ViewChild} from '@angular/core';
import {VendorService} from '../../_services/vendor-service';
import {Util} from '../../_utils/util';
import {Loader} from '../../_models/loader';
import {Vendor} from '../../_models/vendor';
import {AuthService} from '../../_services/auth.service';
import {Subscription} from 'rxjs/Rx';
import {MatPaginator, MatSort, MatTableDataSource, Sort} from '@angular/material';
import {Router} from '@angular/router';

@Component({
	selector: 'app-vendor-edit',
	templateUrl: './vendor-edit.component.html',
	styleUrls: ['./vendor-edit.component.scss'],
})

export class VendorEditComponent implements OnInit {
	noFilter = 'All';
	private _vendors: Vendor[];
	public _filteredVendors: Vendor[];
	public state: any;
	public loader: Loader;
	public errorMsg: string;
	isAdmin: boolean;
	totalResults = 0;
	currentPage = 0;
	pageSize = 10;
	filterState = {
		active: this.noFilter
	};
	activeFilter = ['Active', 'Inactive'];
	pageEvent: Subscription;
	tableData;
	displayedHeader: string[] = ['Vendor ID', 'Vendor Name', 'Active', 'Location Update', 'Action'];
	displayedColumns: string[] = ['id', 'name', 'active', 'update_location', 'action'];
	@ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
	@ViewChild(MatSort, {static: false}) sort: MatSort;


	constructor(private _vendorService: VendorService, private _util: Util, private authService: AuthService, private router: Router) {
		this.isAdmin = false;
	}

	ngOnInit() {
		this.isAdmin = this.authService.isAdmin();
		this.state = {
			sort: 'id',
			filter: 'none',
			min: false,
		};
		this.loader = new Loader();
		this.getAllVendors();
	}

	newPage() {
		this.paginator.pageIndex = 0;
		this.currentPage = 0;
		this.getAllVendors();
	}

	public handlePage(e: any) {
		this.currentPage = e.pageIndex;
		this.totalResults = e.length;
		this.pageSize = e.pageSize;
		this.iterator();
	}

	public getAllVendors(): void {
		this.loader.isLoading = true;
		this._vendorService.getAll()
			.subscribe(
				res => {
					console.log('brands ', res);
					this._vendors = res;
					if (this.filterState.active == 'Active') {
						this._vendors = this._vendors.filter(b => {
							return b.active;
						})
					} else if (this.filterState.active == 'Inactive') {
						this._vendors = this._vendors.filter(b => {
							return !b.active;
						})
					}
					// this.setVendorIds();
					this.tableData = new MatTableDataSource(this._vendors);
					this.loader.isLoading = false;
					this.totalResults = this._vendors.length;
					this.iterator();
					this.tableData.sort = this.sort;
				},
				err => {
					this.errorMsg = err;
					this.loader.isLoading = false;
				});
	}

	private iterator() {
		const end = (this.currentPage + 1) * this.pageSize;
		const start = this.currentPage * this.pageSize;
		const part = this._vendors.slice(start, end);
		this.tableData = part;
	}

	public restartCache(): void {
		this.loader.isLoading = true;
		this._vendorService.restartServerCache()
			.subscribe(
				res => {
					this.loader.isLoading = false;
				},
				err => {
					this.errorMsg = err;
					this.loader.isLoading = false;
				});
	}

	public vendorSort(): void {
		if (this.state.sort == 'name') {
			this._filteredVendors = this._vendors.sort((a, b) => {
				return this.state.min ? a[this.state.sort].toLocaleString().localeCompare(b[this.state.sort].toLocaleString()) : b[this.state.sort].toLocaleString().localeCompare(a[this.state.sort].toLocaleString());
			});
		} else {
			this._filteredVendors = this._vendors.sort((a, b) => {
				return this.state.min ? a[this.state.sort] - b[this.state.sort] : b[this.state.sort] - a[this.state.sort];
			});
		}

		this.vendorFilter(this.state.filter, true);
	}

	public setVendorIds(): void {
		if (this._vendors != null && this._vendors.length > 0) {
			for (let i in this._vendors) {
				const vendorLink: string = this._vendors[i]._links.self.href;
				this._vendors[i].id = parseInt(vendorLink.substring(vendorLink.lastIndexOf('/') + 1, vendorLink.length));
			}
		}
	}

	public vendorFilter(key: string, value?: boolean) {
		if (key === 'none') {
			this._filteredVendors = this._vendors;
		} else {
			this._filteredVendors = this._vendors.filter(v => {
				return v[key] === value;
			});
		}
	}

	public edit(v: Vendor) {
		this.router.navigate([`/admin/vendorEdit/edit/${v.id}`]);
	}

	sortData(sort: Sort) {
		const data = this._vendors.slice();
		if (!sort.active || sort.direction == '') {
			this._vendors = data;
			return;
		}

		this._vendors = data.sort((a, b) => {
			let isAsc = sort.direction == 'asc';
			switch (sort.active) {
				case 'name':
					return this.compare(a.name, b.name, isAsc);
				case 'id':
					return this.compare(+a.id, +b.id, isAsc);
				case 'active':
					return this.compare(+a.active, +b.active, isAsc);
				default:
					return 0;
			}
		});
		this.iterator();
	}


	compare(a, b, isAsc) {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}
}
