import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {Observable} from 'rxjs';
import {AdCostAdjustRequest, AdCostAdjustResult} from '../_models/adCostAdjust';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from './app-config.service';


@Injectable()
export class AdCostAdjustmentService {
	private baseUrl: string;

	constructor(private http: HttpClient, private authService: AuthService, private environment: AppConfigService) {
		this.baseUrl = environment.config.apiBaseUrl + '/api/revenue/st';
	}

	adjustCost(request: any): Observable<AdCostAdjustResult> {

		return this.http.post<AdCostAdjustResult>(`${this.baseUrl}/adjust`, JSON.stringify(request), this.authService.getOptions());
	}

	initParams(): Observable<AdCostAdjustRequest> {
		return this.http.get<AdCostAdjustRequest>(`${this.baseUrl}/`, this.authService.getOptions());
	}
}
