import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {AuthService} from './_services/auth.service';
import {environment} from '../environments/environment';
import {AppConfigService} from './_services/app-config.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

	public constructor(private titeService: Title, private authService: AuthService, private environment: AppConfigService) {

	}

	ngOnInit() {
		this.titeService.setTitle(this.environment.config.appTitle)
	}

	getAuthService() {
		return this.authService;
	}

}
