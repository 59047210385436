import {Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Loader} from '../../_models/loader';
import {Subscription} from 'rxjs/Rx';
import {MatSnackBar} from '@angular/material';
import {MarketingLandingPageService} from '../../_services/seo-landing-page-service';
import {MarketingLandingPage} from '../../_models/marketingLandingPage';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {AuthService} from '../../_services/auth.service';

@Component({
	selector: 'app-seo-landing-page-detail-create',
	templateUrl: './marketing-landing-page-details-create.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./marketing-landing-page.component.scss'],
})

export class MarketingLandingPageDetailsCreateComponent implements OnInit {

	errorMsg: string;
	marketingLandingPageForm: FormGroup;
	hasFormErrors = false;
	loader: Loader;
	id: number;
	marketingLandingPage: any = {};
	templates: any[];
	formReady: Boolean = false;
	backlink = '/admin/landingPage';
	config: AngularEditorConfig = {
		editable: true,
		spellcheck: true,
		height: 'auto',
		minHeight: '300px',
		placeholder: 'Free form text block',
		translate: 'no',
		defaultParagraphSeparator: 'p',
		defaultFontName: 'Arial',
		fonts: [
			{class: 'arial', name: 'Arial'},
			{class: 'open-sans', name: 'Open Sans'},
			{class: 'montserrat', name: 'Montserrat'},
			{class: 'roboto', name: 'Roboto'},
		],
		toolbarHiddenButtons: [
			['subscript', 'superscript'],
			['backgroundColor',
				'customClasses',
				'link',
				'unlink',
				'insertImage',
				'insertVideo',
				'insertHorizontalRule',
				'removeFormat']
		]
	};

	constructor(private marketingLandingPageService: MarketingLandingPageService,
				private fb: FormBuilder,
				private cdRef: ChangeDetectorRef,
				public snackBar: MatSnackBar,
				private route: ActivatedRoute,
				private router: Router) {
	}

	ngOnInit() {
		this.loader = new Loader();
		this.id = parseInt(this.route.snapshot.paramMap.get('id'));
		this.loader.isLoading = true;
		this.getAllMarketingLandingPageTemplates();
		this.createForm();
	}

	public getAllMarketingLandingPageTemplates(): void {
		this.loader.isLoading = true;
		this.marketingLandingPageService.getAllTemplates()
			.subscribe(
				res => {
					this.templates = res;
					this.loader.isLoading = false;
				},
				err => {
					this.errorMsg = err;
					this.loader.isLoading = false;
				});
	}

	createForm() {
		this.marketingLandingPageForm = this.fb.group({
			path: ['', Validators.required],
			template_name: ['', Validators.required],
			searchFormHeadlineText: [''],
			searchFormSubHeadlineText: [''],
			headerText: [''],
			subHeaderText: [''],
			freeFormText: [''],
			backgroundImageUrl: [''],
			additionalImageUrl: [''],
			testimonial3Review: [''],
			pageTitle: [''],
			metaDescription: [''],
			metaKeywords: [''],
			noIndex: [''],
		});
		this.formReady = true;
		this.loader.isLoading = false;
	}

	onSubmit() {
		this.hasFormErrors = false;
		const controls = this.marketingLandingPageForm.controls;

		if (this.marketingLandingPageForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			this.hasFormErrors = true;
			return;
		}
		const v = this.prepareForm();

		this.marketingLandingPageService.createMarketingLandingPage(v).subscribe({
			next: res => {
				this.marketingLandingPage = res;
				this.openSnackBar('Created', 'success');
				this.backToSeoLandingList();
			}, error: err => {
				this.openSnackBar('Server Error', 'error');
				console.log('test', err);
			}
		});
	}

	prepareForm(): MarketingLandingPage {
		const controls = this.marketingLandingPageForm.controls;

		const data: any = {};
		data.searchFormHeadlineText = controls['searchFormHeadlineText'].value;
		data.searchFormSubHeadlineText = controls['searchFormSubHeadlineText'].value;
		data.headerText = controls['headerText'].value;
		data.subHeaderText = controls['subHeaderText'].value;
		data.freeFormText = controls['freeFormText'].value;
		data.backgroundImageUrl = controls['backgroundImageUrl'].value;
		data.additionalImageUrl = controls['additionalImageUrl'].value;

		data.pageTitle = controls['pageTitle'].value;
		data.metaDescription = controls['metaDescription'].value;
		data.metaKeywords = controls['metaKeywords'].value;
		data.noIndex = controls['noIndex'].value;

		this.marketingLandingPage['seo_landing_page_template_id'] = controls['template_name'].value;
		this.marketingLandingPage['data'] = data;
		this.marketingLandingPage['path'] = controls['path'].value;
		return this.marketingLandingPage;
	}

	openSnackBar(message: string, type) {
		this.snackBar.open(message, type, {
			duration: 10000,
		});
	}

	backToSeoLandingList() {
		this.router.navigate([`/admin/landingPage`]);
	}

}
