import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from './app-config.service';

@Injectable()
export class DealSelectorService {
	private baseUrl: string;

	constructor(private http: HttpClient, private authService: AuthService, private environment: AppConfigService) {
		this.baseUrl = environment.config.apiBaseUrl + '/api/deal-selection';
	}

	getDeals(pickUpDate: string, location: string, days: number, excludeVendors: string): any {
		const requestBody = {
			pick_up_date: pickUpDate,
			location: location,
			days: days,
			exclude_vendors: excludeVendors
		};
		return this.http.post<any>(`${this.baseUrl}/deals`, requestBody, this.authService.getOptions());
	}
}
