import {Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {Loader} from '../../_models/loader';
import {Subscription} from 'rxjs/Rx';
import {MatSnackBar} from '@angular/material';
import {Brand} from '../../_models/brand';
import {BrandService} from '../../_services/brand-service';
import {Vendor} from '../../_models/vendor';
import {VendorService} from '../../_services/vendor-service';
import {SeoCarView} from '../../_models/seoPage';

@Component({
	selector: 'app-vendor-detail-edit',
	templateUrl: './vendor-details-edit.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class VendorDetailsEditComponent implements OnInit {

	errorMsg: string;
	vendorForm: FormGroup;
	hasFormErrors = false;
	loader: Loader;
	id: number;
	vendor: Vendor;
	formReady: Boolean = false;
	backlink = '/admin/vendorEdit';
	inputsFields = [
		// {prop: 'name', label: 'Vendor Name'},
		// {prop: 'id', label: 'Vendor Id'},
		{label: 'Max CPC Clicks', prop: 'max_cpc_clicks'},
		{label: 'Max CPC Clicks p SIPP', prop: 'max_cpc_clicks_per_sipp'}
	];

	constructor(private vendorService: VendorService,
				private fb: FormBuilder,
				private cdRef: ChangeDetectorRef,
				public snackBar: MatSnackBar,
				private route: ActivatedRoute) {
	}

	ngOnInit() {
		this.loader = new Loader();
		this.id = parseInt(this.route.snapshot.paramMap.get('id'));
		this.loader.isLoading = true;
		this.fetchDetail();
	}

	fetchDetail(): Subscription {
		return this.vendorService.getVendorById(this.id).subscribe({
			next: res => {
				this.loader.isLoading = false;
				this.vendor = res;
				//this.setVendorIds();
				this.createForm();
				this.cdRef.detectChanges();
			}, error: err => {
				this.loader.isLoading = false;
				this.openSnackBar('unknown server error occured', 'error');
				console.log('err ', err);
			}
		});
	}

	createForm() {
		this.vendorForm = this.fb.group({
			name: [this.vendor.name, Validators.required],
			active: [this.vendor.active, Validators.required],
			id: [this.vendor.id, Validators.required],
			update_location: [this.vendor.update_location, Validators.required],
			cpc_desktop_active: [this.vendor.cpc_desktop_active, Validators.required],
			cpc_mobile_active: [this.vendor.cpc_mobile_active, Validators.required],
			max_cpc_clicks: [this.vendor.max_cpc_clicks],
			max_cpc_clicks_per_sipp: [this.vendor.max_cpc_clicks_per_sipp]
		});
		this.formReady = true;
	}

	public setVendorIds(): void {
		if (this.vendor != null) {
			const vendorLink: string = this.vendor._links.self.href;
			this.vendor.id = parseInt(vendorLink.substring(vendorLink.lastIndexOf('/') + 1, vendorLink.length));
		}
	}

	onSubmit() {
		this.hasFormErrors = false;
		const controls = this.vendorForm.controls;

		if (this.vendorForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			this.hasFormErrors = true;
			return;
		}
		const v = this.prepareForm();

		this.vendorService.updateVendor(v).subscribe({
			next: res => {
				this.vendor = res;
				this.openSnackBar('Updated', 'success');
			}, error: err => {
				this.openSnackBar('Server Error', 'error');
				console.log('test', err);
			}
		});
	}

	prepareForm(): Vendor {
		const controls = this.vendorForm.controls;
		this.inputsFields.forEach(i => {
			this.vendor[i.prop] = controls[i.prop].value
		});
		this.vendor['active'] = controls['active'].value;
		this.vendor['update_location'] = controls['update_location'].value;
		this.vendor['cpc_desktop_active'] = controls['cpc_desktop_active'].value;
		this.vendor['cpc_mobile_active'] = controls['cpc_mobile_active'].value;
		return this.vendor;
	}

	openSnackBar(message: string, type) {
		this.snackBar.open(message, type, {
			duration: 10000,
		});
	}

}
