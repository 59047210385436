import {Component, OnInit, Inject} from '@angular/core';
import {MatSnackBar} from '@angular/material';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
	selector: 'app-confirmation',
	templateUrl: './confirmation.component.html'
})

export class ConfirmationComponent implements OnInit {

	constructor(public snackBar: MatSnackBar, @Inject(MAT_DIALOG_DATA) public data) {
	}

	ngOnInit() {
	}
}
