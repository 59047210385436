import {Component, OnInit, Input} from '@angular/core';
import {BrandService} from '../../_services/brand-service';
import {Util} from '../../_utils/util';
import {Loader} from '../../_models/loader';
import {Brand} from '../../_models/brand';

@Component({
	selector: 'app-brand-pos',
	templateUrl: './brand-pos.component.html',
	styleUrls: ['./brand-pos.component.scss'],
})

export class BrandPositionComponent implements OnInit {
	private _brands: Brand[];
	public _filteredBrands: Brand[];
	public state: any;
	public loader: Loader;
	public errorMsg: string;

	constructor(private _brandService: BrandService, private _util: Util) {
	}

	ngOnInit() {
		this.state = {
			sort: 'matrix_position',
			filter: 'active'
		};
		this.loader = new Loader();
		this.getAllBrands();
	}

	public getAllBrands(): void {
		this.loader.isLoading = true;
		this._brandService.getAll()
			.subscribe(
				res => {
					console.log('brand', res);
					this._brands = res;
					this.brandSort(this.state.sort);
					this.loader.isLoading = false;
				},
				err => {
					console.log('error', err);
					this.errorMsg = err;
					this.loader.isLoading = false;
				});
	}

	public restartCache(): void {
		this.loader.isLoading = true;
		this._brandService.restartServerCache()
			.subscribe(
				res => {
					console.log(res);
					this.loader.isLoading = false;
				},
				err => {
					console.log('error', err);
					this.errorMsg = err;
					this.loader.isLoading = false;
				});
	}

	public brandSort(param: string): void {
		this._filteredBrands = this._brands.sort((a, b) => {
			return b[param] - a[param];
		});
		this.brandFilter(this.state.filter, true);
	}

	public brandFilter(key: string, value?: boolean) {
		if (key === 'none') {
			this._filteredBrands = this._brands;
		} else {
			this._filteredBrands = this._brands.filter(b => {
				return b[key] === value;
			});
		}
	}

	public updateMatrixPosition(b: Brand, i: number) {
		if (this.state.sort === 'matrix_position') {
			this.loader.isLoading = true;
			this.loader.loadingMessage = 'Updating please wait...';
			const base = 100;

			for (let i = 0; i < this._filteredBrands.length; i++) {
				const brand = this._filteredBrands[i];
				brand.matrix_position = (base - i);

				this._brandService.updateBrand(brand).subscribe(
					res => {
						this.loader.isLoading = false;
					},
					err => {
						console.log('error', err);
						this.loader.isLoading = false;
					}
				);
			}
		} else if (this.state.sort === 'matrix_position_mobile') {
			this.loader.isLoading = true;
			this.loader.loadingMessage = 'Updating please wait...';
			const base = 100;

			for (let i = 0; i < this._filteredBrands.length; i++) {
				const brand = this._filteredBrands[i];
				brand.matrix_position_mobile = (base - i);

				this._brandService.updateBrand(brand).subscribe(
					res => {
						this.loader.isLoading = false;
					},
					err => {
						console.log('error', err);
						this.loader.isLoading = false;
					}
				);
			}
		} else {
			this.updateListPosition(b, i);
		}
	}

	public updateListPosition(brand: Brand, index: number) {
		this.loader.isLoading = true;
		this.loader.loadingMessage = 'Updating please wait...';
		// getting the current sort view from the state;
		brand[this.state.sort] = this.calculateNewPosition(index, this.state.sort);

		this._brandService.updateBrand(brand).subscribe(
			res => {
				brand = res;
				this.loader.isLoading = false;
			},
			err => {
				console.log('error', err);
				this.loader.isLoading = false;
			}
		);
	}

	private calculateNewPosition(index: number, param: string) {
		let newPosition: number;
		const minimalIncrement = 20;
		if (index === this._filteredBrands.length - 1) {
			newPosition = this._filteredBrands[index - 1][param] - minimalIncrement;
		} else if (index === 0) {
			newPosition = this._filteredBrands[index + 1][param] + minimalIncrement;
		} else {
			newPosition = (this._filteredBrands[index + 1][param] + this._filteredBrands[index - 1][param]) / 2;
		}
		return newPosition < 0 ? 0 : newPosition;
	}

	public changeState(param: string, state: string) {
		this.state[param] = state;
	}
}
