import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthService} from '../_services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

	constructor(private authService: AuthService,
				private router: Router) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		const isLoggedIn = this.authService.isAuthenticated();
		if (isLoggedIn) {
			return true;
		} else {
			this.router.navigate(['/login']);
			return false;
		}
	}
}
