import {Component, OnInit} from '@angular/core';
import {DealSelectorService} from '../../_services/deal-selector-service';
import * as moment from 'moment';

@Component({
	selector: 'app-email-subscription',
	templateUrl: './deal-selection.component.html',
	styleUrls: ['./deal-selection.component.scss'],
})

export class DealSelectionComponent implements OnInit {

	numbers: number[];
	pickUpdate: string;
	location: string;
	excludeVendors: string;
	days: number;
	deals: any[];
	error: string;

	ngOnInit(): void {
	}

	constructor(private dealSelectorService: DealSelectorService) {
		this.numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
		this.excludeVendors = 'N';
		this.deals = [];
		this.error = '';
	}

	public getAllDeals(): void {
		this.error = '';
		this.days = this.days != null ? this.days : 0;
		this.dealSelectorService
			.getDeals(moment(this.pickUpdate).format('MM/DD/YYYY'), this.location, this.days, this.excludeVendors).subscribe(
			res => {
				this.deals = res;
				console.log(this.deals);
				if (this.deals.length == 0) {
					this.error = 'No deals found';
				}
			},
			err => {
				this.error = err;
			})
	}
}
