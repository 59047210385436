import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {AuthService} from './auth.service';
import {AppConfigService} from './app-config.service';

@Injectable()
export class SupplierMappingService {

	private baseUrl: string;
	private baseUrlSupplier: string;
	private refreshCacheUrl: string;

	constructor(private http: HttpClient, private authService: AuthService, private environment: AppConfigService) {
		this.baseUrl = environment.config.apiBaseUrl + '/api/v1/supplier-mapping';
		this.baseUrlSupplier = environment.config.apiBaseUrl + '/api/v1/supplier';
		this.refreshCacheUrl = environment.config.apiBaseUrl + '/api/cache/reload';
	}

	getAll(): Observable<any> {
		return this.http.get(`${this.baseUrl}/findAllBySupplierIdEquals?supplier=0`, this.authService.getOptions())
			.pipe(map(res => res));
	}

	update(supplierMapping: any) {
		return this.http.put(`${supplierMapping._links.self.href}`, JSON.stringify(supplierMapping), this.authService.getOptions())
			.pipe(map(res => res));
	}

	getAllPg(): Observable<any> {
		return this.http.get(`${this.baseUrl}/partner_api/findAllBySupplierIdEquals?supplier=0`, this.authService.getOptions())
			.pipe(map(res => res));
	}

	updatePg(supplierMapping: any) {
		return this.http.put(`${this.baseUrlSupplier}/${supplierMapping.id}`, JSON.stringify(supplierMapping), this.authService.getOptions())
			.pipe(map(res => res));
	}
}
