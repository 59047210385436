import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';
import {Vendor} from '../_models/vendor';
import {HttpClient} from '@angular/common/http';
import {InsuranceCommission} from '../_models/insuranceCommission';
import {AppConfigService} from './app-config.service';

@Injectable()
export class InsuranceCommissionService {
	private baseUrl: string;

	constructor(private http: HttpClient, private authService: AuthService, private environment: AppConfigService) {
		this.baseUrl = environment.config.apiBaseUrl + '/api/v1/insurance_brand';
	}

	getAll(): Observable<any> {
		return this.http.get<any>(`${this.baseUrl}`, this.authService.getOptions());
	}

	update(insuranceCommission: InsuranceCommission): Observable<InsuranceCommission> {
		return this.http.put<InsuranceCommission>(`${this.baseUrl}/${insuranceCommission.id}`, JSON.stringify(insuranceCommission),
			this.authService.getOptions());
	}

	getInsuranceById(id: string) {
		return this.http.get<any>(`${this.baseUrl}/${id}`, this.authService.getOptions());
	}
}
