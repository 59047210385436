import {Component, OnInit} from '@angular/core';
import {AbTestDescriptor} from '../../_models/abTest';
import {AbToolService} from '../../_services/ab-service';
import {HttpEvent} from '@angular/common/http';
import {AuthService} from '../../_services/auth.service';


@Component({
	selector: 'app-ab-tool',
	templateUrl: './ab-tool.component.html',
	styleUrls: ['./ab-tool.component.scss'],
})

export class AbToolComponent implements OnInit {

	abService: AbToolService;
	authService: AuthService;
	abTests: AbTestDescriptor[];

	operationMsg: string;
	_displaySection: string;
	_editContent: AbTestDescriptor;
	_variantPercentageChoice: number;
	// -1 - control:100;variant:0  0 - control:50;variant:50  1 - control:0;variant:100
	_controlPercentage: number;
	_controlId: number;
	_variantId: number;
	_variantPercentage: number;
	_winnerChoice: number;
	isAdmin: boolean;


	constructor(abService: AbToolService, authService: AuthService) {
		this.abService = abService;
		this.authService = authService;
		this.abTests = [];
		this.operationMsg = '';
		this._displaySection = 'none';
		this.isAdmin = false;
	}

	ngOnInit() {
		this.isAdmin = this.authService.isAdmin();
		this.abService.getAllTests()
			.subscribe(c => {
				this.abTests = c.reverse();
				this._displaySection = 'list'
			});
	}

	editTest(testDescriptor: AbTestDescriptor) {
		this._displaySection = 'content';
		this._editContent = testDescriptor;
		this.operationMsg = '';
		testDescriptor.test_variants.forEach(testVariant => {
			if (testVariant.control_variant) {
				this._controlPercentage = testVariant.ab_test_variant_percentage;
				this._controlId = testVariant.id;
			} else {
				this._variantPercentage = testVariant.ab_test_variant_percentage;
				this._variantId = testVariant.id;
			}
		});
		if (this._controlPercentage === 100) {
			this._variantPercentageChoice = -1;
		} else if (this._variantPercentage === 100) {
			this._variantPercentageChoice = 1;
		} else {
			this._variantPercentageChoice = 0;
		}
		this._winnerChoice = testDescriptor.winner_id;
	}

	displayTestList() {
		this._displaySection = 'list';
	}

	activateTest() {
		this._editContent.active = true;
		this.abService.activateTest(this._editContent.id).subscribe(
			c => {
				if (c.toString() === 'SUCCESS') {
					this.operationMsg = 'Test ' + this._editContent.id + ' - ' + this._editContent.ab_test_name + ' has been activated';
				} else {
					this.operationMsg = 'Failed to activate test ' + this._editContent.id + ' - ' + this._editContent.ab_test_name;

				}
			}
		);
	}

	deactivateTest() {
		this._editContent.active = false;
		this.abService.deactivateTest(this._editContent.id).subscribe(
			c => {
				if (c.toString() === 'SUCCESS') {
					this.operationMsg = 'Test ' + this._editContent.id + ' - ' + this._editContent.ab_test_name + ' has been deactivated';
				} else {
					this.operationMsg = 'Failed to deactivate test ' + this._editContent.id + ' - ' + this._editContent.ab_test_name;

				}

			}
		);
	}

	retireTest() {
		if (this._winnerChoice > 0) {
			this.abService.retireTest(this._editContent.id, this._winnerChoice).subscribe(
				c => {
					if (c.toString() === 'SUCCESS') {
						this._editContent.active = false;
						this._editContent.winner_id = this._winnerChoice;
						console.log(this._winnerChoice);
						this.operationMsg = 'Test ' + this._editContent.id + ' - ' + this._editContent.ab_test_name + ' has been retired.';
					} else {
						this.operationMsg = 'Failed to retire test ' + this._editContent.id + ' - ' + this._editContent.ab_test_name;
					}
				}
			);
		}
	}

	changeTestPercentage() {
		if (this._variantPercentageChoice === -1) {
			this._controlPercentage = 100;
			this._variantPercentage = 0;
		} else if (this._variantPercentageChoice === 0) {
			this._controlPercentage = 50;
			this._variantPercentage = 50;
		} else {
			this._controlPercentage = 0;
			this._variantPercentage = 100;
		}
		this.abService.changeTestPercentage(this._editContent.id, this._controlPercentage, this._variantPercentage).subscribe(
			c => {
				if (c.toString() === 'SUCCESS') {
					this.operationMsg = 'Percentage of test ' + this._editContent.id + ' - ' + this._editContent.ab_test_name + ' has been updated.';
					this._editContent.test_variants.forEach(testVariant => {
						if (testVariant.control_variant) {
							testVariant.ab_test_variant_percentage = this._controlPercentage;
						} else {
							testVariant.ab_test_variant_percentage = this._variantPercentage;
						}
					});
				} else {
					this.operationMsg = 'Failed to change percentage of test ' + this._editContent.id + ' - ' + this._editContent.ab_test_name;
				}
			}
		);
	}

}
