import {Component, OnInit} from '@angular/core';
import {Loader} from '../../_models/loader';
import {MatSnackBar} from '@angular/material';


@Component({
	selector: 'app-template',
	templateUrl: './template.component.html',
	styleUrls: ['./template.component.scss'],
})

export class TemplateComponent implements OnInit {
	public loader: Loader;

	constructor(public snackBar: MatSnackBar) {
	}

	ngOnInit() {
	}
}
