import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from './app-config.service';

@Injectable()
export class SeoFaqService {
	private baseUrl: string;

	constructor(private http: HttpClient, private authService: AuthService, private environment: AppConfigService) {
		this.baseUrl = environment.config.apiBaseUrl + '/api/v1/seo-faqs';
	}

	getLocationFaqsBylocationId(locationId: any) {
		return this.http.get<any>(`${this.baseUrl}/findAllByLocationId?locationId=${locationId}&page=1`,
			this.authService.getOptions());
	}

	update(faq: any) {
		return this.http.put<any>(`${this.baseUrl}/${faq.id}`, JSON.stringify(faq), this.authService.getOptions());
	}

	getFaqDetails(faq: any) {
		return this.http.get<any>(`${faq._links.self.href}`, this.authService.getOptions());
	}

	createFaq(faq: any) {
		return this.http.post(this.baseUrl, JSON.stringify(faq), this.authService.getOptions());
	}

	deleteFaq(faq: any) {
		return this.http.delete(`${this.baseUrl}/${faq.id}`, this.authService.getOptions());
	}
}
