import {Component, OnInit} from '@angular/core';
import {Loader} from '../../_models/loader';
import {KlaviyoService} from '../../_services/klaviyo-service';
import {MatSnackBar} from '@angular/material';
import {AuthService} from '../../_services/auth.service';

@Component({
	selector: 'app-klaviyo-tool',
	templateUrl: './klaviyo-tool.component.html',
	styleUrls: ['./klaviyo-tool.component.scss']
})
export class KlaviyoToolComponent implements OnInit {

	klaviyoService: KlaviyoService;
	datafeedsJsonData: any[];
	dataFeed: any;
	defaultDataFeed: any;
	editingDataFeed: any;
	errorMsg: string;
	showForm: boolean;
	showEditForm: boolean;
	createdRecord: boolean;
	updatedRecord: boolean;
	brand_name: string;
	image_name: string;
	tag_name: string;
	nearby_name: string;
	loader: Loader;
	isAdmin: boolean;

	constructor(klaviyoService: KlaviyoService, public snackBar: MatSnackBar, public authService: AuthService) {
		this.datafeedsJsonData = [];
		this.klaviyoService = klaviyoService;
		this.dataFeed = {};
		this.defaultDataFeed = {};
		this.editingDataFeed = {};
		this.errorMsg = '';
		this.showForm = false;
		this.showEditForm = false;
		this.createdRecord = false;
		this.updatedRecord = false;
		this.brand_name = '';
		this.image_name = '';
		this.tag_name = '';
		this.nearby_name = '';
		this.isAdmin = false;
	}

	ngOnInit() {
		this.loader = new Loader();
		this.getAllDetails();
		this.isAdmin = this.authService.isAdmin();
	}

	getAllDetails() {
		this.loader.isLoading = true;
		this.dataFeed.data = {};
		this.defaultDataFeed.data = {};
		this.dataFeed.data.brands = [];
		this.dataFeed.data.images = [];
		this.dataFeed.data.tags = [];
		this.dataFeed.data.nearby = [];
		this.defaultDataFeed.data.brands = [];
		this.defaultDataFeed.data.images = [];
		this.defaultDataFeed.data.tags = [];
		this.defaultDataFeed.data.nearby = [];
		this.klaviyoService.getAll().subscribe(
			res => {
				this.datafeedsJsonData = res;
				if (this.datafeedsJsonData != null) {
					for (let i in res) {
						if (this.datafeedsJsonData[i].pickup_location === 'Default') {
							this.defaultDataFeed = this.datafeedsJsonData[i];
						}
					}
				}
				this.datafeedsJsonData = this.datafeedsJsonData.filter(item => item != this.defaultDataFeed);
				this.loader.isLoading = false;
			}, err => {
				this.errorMsg = err;
				this.loader.isLoading = false;
			}
		)
	}

	createDataFeed() {
		this.loader.isLoading = true;
		this.dataFeed.data_description = 'PS';
		this.klaviyoService.createKlaviyo(this.dataFeed).subscribe(
			r => {
				this.loader.isLoading = false;
				this.createdRecord = true;
			},
			err => {
				this.errorMsg = err;
				this.openSnackBar('Error creating record');
				this.loader.isLoading = false;
			}
		)
	}

	updateDataFeed() {
		this.loader.isLoading = true;
		const id = this.editingDataFeed.id;
		this.klaviyoService.updateDataFeed(this.editingDataFeed, id).subscribe(
			res => {
				this.loader.isLoading = false;
				this.updatedRecord = true;
			}, err => {
				this.errorMsg = err;
				this.openSnackBar('Error while updating');
				this.loader.isLoading = false;
			}
		)
	}

	openSnackBar(message: string) {
		this.snackBar.open(message, '', {
			duration: 4000,
		});
	}

	showPopUp() {
		this.showForm = true;
	}

	closePopUp() {
		this.showForm = false;
		this.getAllDetails();
		this.createdRecord = false;
	}

	addDataToList(data: string, dataList: string[], type: string) {
		dataList.push(data);
		if (type == 'brand') {
			this.brand_name = '';
		} else if (type == 'image') {
			this.image_name = '';
		} else if (type == 'tags') {
			this.tag_name = '';
		} else if (type == 'nearby') {
			this.nearby_name = '';
		}
	}

	deleteDataFromList(data: string, dataList: string[]) {
		const index: number = dataList.indexOf(data);
		if (index !== -1) {
			dataList.splice(index, 1);
		}
	}

	showEditModal(data: any) {
		this.showEditForm = true;
		this.editingDataFeed = data;
	}

	closeEditModal() {
		this.showEditForm = false;
		this.getAllDetails();
		this.updatedRecord = false;
	}
}
