import {Component, Input, OnInit} from '@angular/core';
import {Campaign} from '../../../_models/campaign';
import {Loader} from '../../../_models/loader';
import {CampaignService} from '../../../_services/campaign-service';
import {Util} from '../../../_utils/util';
import {MatSnackBar} from '@angular/material';

@Component({
	selector: 'app-expense-adjustment-edit',
	templateUrl: './expense-adjustment-edit.component.html',
	styleUrls: ['./expense-adjustment-edit.component.scss'],
})

export class ExpenseAdjustmentEditComponent implements OnInit {
	_detailCampaign: Campaign;
	selectedCampaignId: number;
	newTotalExpense: string;
	updateExistingCpc: boolean;
	visibleAnimate: boolean;
	visible: boolean;
	errorMsg: string;
	loader: Loader;

	@Input()
	set detailCampaign(value: Campaign) {
		this._detailCampaign = value;
		this.selectedCampaignId = this._detailCampaign.id;
		this.open();
	}

	get detailCampaign(): Campaign {
		return this._detailCampaign;
	}

	constructor(private campaignService: CampaignService, private util: Util, public snackBar: MatSnackBar) {
		this.updateExistingCpc = true;
		this.visibleAnimate = true;
		this.visible = true;
		this.loader = new Loader();
	}

	public open(): void {
		this.visible = true;
		setTimeout(() => this.visibleAnimate = true, 100);
	}

	public hide(): void {
		this.visibleAnimate = false;
		setTimeout(() => this.visible = false, 300);
	}

	public onContainerClicked(event: MouseEvent): void {
		// close modal
		if ((<HTMLElement>event.target).classList.contains('modal')) {
			this.hide();
		}
	}

	openSnackBar(message: string) {
		this.snackBar.open(message, '', {
			duration: 4000,
		});
	}

	public save() {

		this._detailCampaign.new_total_expense = this.util.numbersOnly(this.newTotalExpense);
		if (this._detailCampaign.new_total_expense === '' || this._detailCampaign.new_total_expense === 'undefined') {
			this.errorMsg = 'Invalid Entry';
			return;
		}

		this.loader.isLoading = true;
		this.loader.loadingMessage = 'Saving...';

		this.campaignService.adjustExpensesForCampaign(this._detailCampaign.currentStartDate, this._detailCampaign.currentEndDate,
			this._detailCampaign.id, this._detailCampaign.new_total_expense, this.updateExistingCpc).subscribe(
			res => {
				this.ngOnInit();
				this.refreshCampaign(this._detailCampaign.currentStartDate, this._detailCampaign.currentEndDate, this._detailCampaign.id);
				this.openSnackBar('Your request has been received, if the adjustment fails you will receive an email.');
			},
			err => {
				this.ngOnInit();
				this.errorMsg = 'Adjustment failed';
			});
	}

	public refreshCampaign(startDate, endDate, campaignId): void {

		this.campaignService.getDetailedCampaignData(startDate, endDate, campaignId).subscribe(
			res => {
				this._detailCampaign.old_total_expense = res.old_total_expense;
				this._detailCampaign.session_cpc_mean = res.session_cpc_mean;
				this._detailCampaign.session_cpc_min = res.session_cpc_min;
				this._detailCampaign.session_cpc_max = res.session_cpc_max;
				this._detailCampaign.session_count = res.session_count;
			});
	}

	ngOnInit() {
		this.loader.isLoading = false;
		this.newTotalExpense = '';
		this.errorMsg = '';
	}
}
