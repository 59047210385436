import {Component, OnInit} from '@angular/core';
import {Loader} from '../../_models/loader';
import {SeoLocationService} from '../../_services/seo-location-service';
import {SeoFaqService} from '../../_services/seo-faq-service';
import {SeoLocationMappingService} from '../../_services/seo-location-mapping-service';
import {SeoSupplierService} from '../../_services/seo-supplier-service';

@Component({
	selector: 'app-seo-tool',
	templateUrl: './seo-tool.component.html',
	styleUrls: ['./seo-tool.component.scss']
})
export class SeoToolComponent implements OnInit {
	public locations: any[];
	public loader: Loader;
	_pagination: any;
	currentPage: number;
	searchTerm: string;
	location: any;
	locationNew: any;
	seoFaqs: any[];
	faqElements: any;
	seoLocationMapping: any[];
	seoSuppliers: any[];
	filterBy: any;
	previouslySelectedLocation: any;
	unsavedChanges: any;
	searchStarted: boolean;
	locationSelected: boolean;
	public errorMsg: string;
	isCreate: boolean;
	locationMappingNew: string;
	supplierMappingNewName: string;
	supplierMappingNewImageUrl: string;

	constructor(private service: SeoLocationService, private faqService: SeoFaqService, private locationMappingService: SeoLocationMappingService, private supplierService: SeoSupplierService) {
		this.currentPage = 1;
		this.searchTerm = '';
		this.location = {};
		this.location.showTab = false;
		this.location.closeEditableTabs = false;
		this.seoFaqs = [];
		this.seoLocationMapping = [];
		this.seoSuppliers = [];
		this.unsavedChanges = false;
		this.locationSelected = false;
		this._pagination = {};
		this.locationNew = {};
		this.locationNew.locationMappings = [];
		this.locationNew.suppliers = [];
	}

	ngOnInit() {
		this.loader = new Loader();
		this.searchSeoLocations(this.currentPage, 'Active');
		this.filterBy = 'Active';
		this.previouslySelectedLocation = {};
		this.location.closeEditableTabs = false;
	}

	public searchSeoLocations(page: number, filterBy: any) {
		this.searchStarted = true;
		this.location.isTabSelected = false;
		this.location.closeEditableTabs = false;
		this.loader.isLoading = true;
		if (filterBy == 'Active') {
			this.currentPage = page;
			this.service.getActiveLocationsByName(this.searchTerm, page).subscribe(res => {
					this.locations = res.content;
					this._pagination.total_elements = res.total_elements;
					this._pagination.pages = Array.from(new Array(res.total_pages), (x, i) => i + 1);
					this.loader.isLoading = false;
				},
				err => {
					this.errorMsg = err;
					this.loader.isLoading = false;
				})
		} else if (filterBy == 'Inactive') {
			this.currentPage = page;
			this.service.getInactiveLocationsByName(this.searchTerm, page).subscribe(res => {
					this.locations = res.content;
					this._pagination.total_elements = res.total_elements;
					this._pagination.pages = Array.from(new Array(res.total_pages), (x, i) => i + 1);
					this.loader.isLoading = false;
				},
				err => {
					this.errorMsg = err;
					this.loader.isLoading = false;
				})
		} else {
			this.currentPage = page;
			this.service.getLocationsByName(this.searchTerm, page).subscribe(res => {
					this.locations = res.content;
					this._pagination.total_elements = res.total_elements;
					this._pagination.pages = Array.from(new Array(res.total_pages), (x, i) => i + 1);
					this.loader.isLoading = false;
				},
				err => {
					this.errorMsg = err;
					this.loader.isLoading = false;
				})
		}
	}

	public getLocationDetails(location: any) {
		this.locationSelected = false;
		if (!this.unsavedChanges) {
			if (this.previouslySelectedLocation != location) {
				this.previouslySelectedLocation.isTabSelected = false;
			}
			this.location = location;
			this.location.isTabSelected = true;
			// const locationLink: string = location._links.self.href;
			this.getSeoLocationFaqDetails(this.location.id);
			this.getSeoLocationMappingDetails(this.location.id);
			this.getSeoSupplierDetails(this.location.id);
			this.previouslySelectedLocation = location;
			this.location.closeEditableTabs = true;
		} else {
			this.locationSelected = true;
		}
	}

	public getSeoLocationFaqDetails(locationId: any) {
		this.loader.isLoading = true;
		this.faqService.getLocationFaqsBylocationId(locationId).subscribe(res => {
				this.seoFaqs = res.content;
				this.faqElements = res.total_elements;
				this.loader.isLoading = false;
			},
			err => {
				this.errorMsg = err;
				this.loader.isLoading = false;
			})
	}

	public getSeoLocationMappingDetails(locationId: any) {
		this.loader.isLoading = true;
		this.locationMappingService.getLocationMappingBylocationId(locationId).subscribe(res => {
				this.seoLocationMapping = res.content;
				this.loader.isLoading = false;
			},
			err => {
				this.errorMsg = err;
				this.loader.isLoading = false;
			})
	}

	public getSeoLocationDetails(location: any) {
		this.loader.isLoading = true;
		this.service.getSeoLocationDetails(location).subscribe(res => {
				this.location = res;
				this.location.isTabSelected = true;
				this.location.closeEditableTabs = true;
				const locationLink: string = location._links.self.href;
				this.location.id = locationLink.substring(locationLink.lastIndexOf('/') + 1, locationLink.length);
				this.loader.isLoading = false;
			},
			err => {
				this.errorMsg = err;
				this.loader.isLoading = false;
			})
	}

	public getSeoSupplierDetails(locationId: any) {
		this.loader.isLoading = true;
		this.supplierService.getSuppliersBylocationId(locationId).subscribe(res => {
				this.seoSuppliers = res.content;
				this.loader.isLoading = false;
			},
			err => {
				this.errorMsg = err;
				this.loader.isLoading = false;
			})
	}

	unsavedChangesFunc(isChanged: boolean) {
		this.unsavedChanges = isChanged;
	}

	locationSelectedFunc(isChanged: boolean) {
		this.locationSelected = isChanged;
	}

	closePopUp() {
		this.locationSelected = false;
	}

	saveLocations(locations: any, faqs: any[], suppliers: any[]) {
		this.location.closeEditableTabs = false;
		this.loader.isLoading = true;
		this.service.update(locations).subscribe(res => {
			this.location = res;
			this.location.closeEditableTabs = true;
			this.location.isTabSelected = true;
			this.loader.isLoading = false;
		}, err => {
			this.errorMsg = err;
			this.loader.isLoading = false;
		})
		if (faqs != null) {
			for (let faq in faqs) {
				delete faqs[faq].canEdit;
				delete faqs[faq].showAnswer;
				this.loader.isLoading = true;
				this.faqService.update(faqs[faq]).subscribe(res => {
					faqs[faq] = res;
					this.loader.isLoading = false;
				}, err => {
					this.errorMsg = err;
					this.loader.isLoading = false;
				});
			}
		}
		if (suppliers != null) {
			for (let supplier in suppliers) {
				delete suppliers[supplier].canEdit;
				this.loader.isLoading = true;
				this.supplierService.update(suppliers[supplier]).subscribe(res => {
					suppliers[supplier] = res;
					this.loader.isLoading = false;
				}, err => {
					this.errorMsg = err;
					this.loader.isLoading = false;
				});
			}
		}
		this.unsavedChanges = false;
		this.locationSelected = false;
	}

	cancelChanges() {
		this.location.closeEditableTabs = true;
		this.getSeoLocationDetails(this.location);
		this.unsavedChanges = false;
		this.getSeoLocationFaqDetails(this.location.id);
		this.getSeoSupplierDetails(this.location.id);
		this.locationSelected = false;
	}

	showCreate() {
		this.isCreate = true;
	}

	hideCreate() {
		this.isCreate = false;
		this.searchSeoLocations(this.currentPage, 'Active');
	}

	deleteDataFromList(data: string, dataList: string[]) {
		const index: number = dataList.indexOf(data);
		if (index !== -1) {
			dataList.splice(index, 1);
		}
	}

	deleteObjectFromList(data: any, dataList: any[]) {
		const index: number = dataList.indexOf(data);
		if (index !== -1) {
			dataList.splice(index, 1);
		}
	}

	addObjectToList(value: string, name: string, imageUrl: string, dataList: any[], dataType: string) {
		var data: any = {};
		if (dataType == 'locationMapping') {
			data.value = value;
			this.locationMappingNew = '';
		} else if (dataType == 'supplierMapping') {
			data.name = name;
			data.image_url = imageUrl;
			this.supplierMappingNewName = '';
			this.supplierMappingNewImageUrl = '';
		}
		dataList.push(data);
	}

	createSeoLocation() {
		if (this.locationNew != null && this.locationNew.name != null && this.locationNew.name.length > 0 && this.locationNew.search_term != null && this.locationNew.search_term.length > 0) {
			this.loader.isLoading = true;
			this.service.createSeoLocation(this.locationNew).subscribe(res => {
				const result: any = res;
				this.locationNew.id = result.id;
				if (this.locationNew.id != null) {
					for (let i in this.locationNew.locationMappings) {
						this.locationNew.locationMappings[i].location_id = this.locationNew.id;
						this.locationMappingService.createSeoLocationMappings(this.locationNew.locationMappings[i]).subscribe(result => {
						}, error1 => {
							this.loader.isLoading = false;
							this.errorMsg = error1;
						})
					}
					for (let j in this.locationNew.suppliers) {
						this.locationNew.suppliers[j].location_id = this.locationNew.id;
						this.supplierService.createSeoSupplier(this.locationNew.suppliers[j]).subscribe(res => {
						}, error1 => {
							this.loader.isLoading = false;
							this.errorMsg = error1;
						})
					}
					this.loader.isLoading = false;
					this.locationNew = {};
					this.errorMsg = 'SEO Location was created successfully. '
					this.hideCreate();
				}
			}, err => {
				this.loader.isLoading = false;
			})
		} else {
			this.errorMsg = 'Please enter all mandatory fields. '
		}
	}
}
