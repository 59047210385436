import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Util} from '../../_utils/util';
import {Loader} from '../../_models/loader';
import {AuthService} from '../../_services/auth.service';
import {Subscription} from 'rxjs/Rx';
import {MatPaginator, MatSort, MatTableDataSource, Sort} from '@angular/material';
import {Router} from '@angular/router';
import {MarketingLandingPage} from '../../_models/marketingLandingPage';
import {MarketingLandingPageService} from '../../_services/seo-landing-page-service';
import {fromEvent} from 'rxjs';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';

@Component({
	selector: 'app-seo-landing-page-edit',
	templateUrl: './marketing-landing-page.component.html',
	styleUrls: ['./marketing-landing-page.component.scss'],
})

export class MarketingLandingPageComponent implements OnInit {
	noFilter = 'All';
	private _marketingLandingPage: MarketingLandingPage[];
	public state: any;
	public loader: Loader;
	public errorMsg: string;
	isAdmin: boolean;
	totalResults = 0;
	currentPage = 0;
	pageSize = 10;
	filterState = {
		active: this.noFilter
	};
	pageEvent: Subscription;
	tableData;
	filterType = 'id';
	filterOptions = ['id', 'template_name', 'path'];
	displayedHeader: string[] = ['id', 'Template Name', 'Path', 'Active', 'Action'];
	displayedColumns: string[] = ['id', 'template_name', 'path', 'active', 'action'];
	private subscriptions: Subscription[] = [];

	@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
	@ViewChild(MatSort, {static: true}) sort: MatSort;
	@ViewChild('searchInput', {static: true}) searchInput: ElementRef;


	constructor(private _marketingLandingPageService: MarketingLandingPageService, private _util: Util, private authService: AuthService, private router: Router) {
		this.isAdmin = false;
	}

	ngOnInit() {
		this.isAdmin = this.authService.isAdmin();
		this.state = {
			sort: 'id',
			filter: 'none',
			min: false,
		};
		this.loader = new Loader();
		this.getAllMarketingLandingPages();
		this.subscriptions.push(this.setupFilter());
	}

	setupFilter(): Subscription {
		return fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
			debounceTime(50),
			distinctUntilChanged(),
			tap(() => {
				const filtered = this._marketingLandingPage.filter(b => {
					const param = b[this.filterType].toString().toLowerCase();
					if (b[this.filterType]) {
						return param.includes(this.searchInput.nativeElement.value.toString().toLowerCase());
					}
				});
				this.tableData = new MatTableDataSource(filtered);
			})
		).subscribe();
	}

	public handlePage(e: any) {
		this.currentPage = e.pageIndex;
		this.totalResults = e.length;
		this.pageSize = e.pageSize;
		this.iterator();
	}

	public getAllMarketingLandingPages(): void {
		this.loader.isLoading = true;
		this._marketingLandingPageService.getAll()
			.subscribe(
				res => {
					console.log('brands ', res);
					this._marketingLandingPage = res.content;
					// this.setVendorIds();
					this.tableData = new MatTableDataSource(this._marketingLandingPage);
					this.loader.isLoading = false;
					this.totalResults = this._marketingLandingPage.length;
					this.iterator();
					this.tableData.sort = this.sort;
				},
				err => {
					this.errorMsg = err;
					this.loader.isLoading = false;
				});
	}

	private iterator() {
		const end = (this.currentPage + 1) * this.pageSize;
		const start = this.currentPage * this.pageSize;
		const part = this._marketingLandingPage.slice(start, end);
		this.tableData = part;
	}


	public edit(v: MarketingLandingPage) {
		this.router.navigate([`/admin/landingPage/edit/${v.id}`]);
	}

	sortData(sort: Sort) {
		const data = this._marketingLandingPage.slice();
		if (!sort.active || sort.direction == '') {
			this._marketingLandingPage = data;
			return;
		}

		this._marketingLandingPage = data.sort((a, b) => {
			let isAsc = sort.direction == 'asc';
			switch (sort.active) {
				case 'template_name':
					return this.compare(a.template_name, b.template_name, isAsc);
				case 'id':
					return this.compare(+a.id, +b.id, isAsc);
				case 'path':
					return this.compare(a.path, b.path, isAsc);
				default:
					return 0;
			}
		});
		this.iterator();
	}

	compare(a, b, isAsc) {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}

	createMarketingLandingPage() {
		this.router.navigate([`/admin/landingPage/create`]);
	}

	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}
}
