import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from './app-config.service';

@Injectable()
export class PartnerApiService {
	private baseUrl: string;
	private refreshCacheUrl: string;

	constructor(private http: HttpClient, private authService: AuthService, private environment: AppConfigService) {
		this.baseUrl = environment.config.apiBaseUrl;
		this.refreshCacheUrl = environment.config.apiBaseUrl + '/api/cache/reload';
	}

	getLocationBatches(): Observable<any> {
		return this.http.get<any>(`${this.baseUrl}/api/location/update/partner-api/unprocessed_batches`,
			this.authService.getOptions()).pipe(map(res => res));
	}

	updateLocation(location): Observable<any> {
		return this.http.get<any>(
			`${this.baseUrl}/api/location/update/partner-api/run/phase-two/
			${location.vendor_id}/${location.brand_id}/${location.batch_id}`,
			this.authService.getOptions()).pipe(map(res => res));
	}
}

