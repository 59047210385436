// global imports
import {Component, OnInit, ElementRef, ViewChild, OnDestroy} from '@angular/core';
import {fromEvent} from 'rxjs';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {Subscription} from 'rxjs/Rx';
import {MatPaginator, MatSort, MatSnackBar} from '@angular/material';
import {Loader} from '../../_models/loader';
import {Router} from '@angular/router';
import {MatTableDataSource} from '@angular/material/table';
// local imports
import {SeoCarService} from '../../_services/seo-car-service';
import {SeoCarView} from '../../_models/seoPage';

@Component({
	selector: 'app-seo-car',
	templateUrl: './seo-car.component.html',
	styleUrls: ['./seo-car.component.scss'],
})

export class SeoCarComponent implements OnInit, OnDestroy {
	loader: Loader;
	tableData;
	dataSource: SeoCarView[];

	filterType = 'name';
	displayedHeader: string[] = ['Id', 'Car Class', 'Active', 'H1', 'Image', 'Bags', 'Doors', 'Passengers', 'Action'];
	displayedColumns: string[] = ['id', 'name', 'active', 'h1', 'image_url', 'large_bags', 'doors', 'passengers', 'action'];
	filterOptions = ['id', 'name', 'active', 'h1', 'image_url', 'large_bags', 'doors', 'passengers'];
	private subscriptions: Subscription[] = [];

	@ViewChild(MatSort, {static: true}) sort: MatSort;
	@ViewChild('searchInput', {static: true}) searchInput: ElementRef;

	constructor(public snackBar: MatSnackBar,
				private router: Router,
				private _seoCarService: SeoCarService) {
	}

	ngOnInit() {
		this.loader = new Loader();
		this.fetchAll();
		this.subscriptions.push(this.setupFilter());
	}

	setupFilter(): Subscription {
		return fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
			debounceTime(50),
			distinctUntilChanged(),
			tap(() => {
				const filtered = this.dataSource.filter(b => {
					const param = b[this.filterType].toString().toLowerCase();
					if (b[this.filterType]) {
						return param.includes(this.searchInput.nativeElement.value);
					}
				});
				this.tableData = new MatTableDataSource(filtered);
			})
		).subscribe();
	}

	fetchAll(): void {
		this.loader.isLoading = true;
		this._seoCarService.fetchAll()
			.subscribe({
				next: res => {
					this.dataSource = res;
					this.tableData = new MatTableDataSource(this.dataSource);
					this.tableData.sort = this.sort;
					this.loader.isLoading = false;
				}, error: err => {
					this.openSnackBar('unknown server error occured', 'error');
					console.log('err ', err);
					this.loader.isLoading = false;
				}
			});
	}

	openSnackBar(message: string, type) {
		this.snackBar.open(message, type, {
			duration: 10000,
		});
	}

	edit(data: SeoCarView) {
		this.router.navigate([`/admin/seo-car-class/edit/${data.id}`]);
	}

	create() {
		this.router.navigate([`/admin/seo-car-class/edit/new`]);
	}

	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}
}
