import {Component, OnInit} from '@angular/core';
import {Loader} from '../../_models/loader';
import {MatSnackBar} from '@angular/material';
import {SupplierMappingService} from '../../_services/supplier-mapping-service';
import {SupplierService} from '../../_services/supplier-service';
import {Supplier} from '../../_models/supplier';
import {Observable} from 'rxjs/Observable';

@Component({
	selector: 'app-supplier-mapping',
	templateUrl: './supplier-mapping.component.html',
	styleUrls: ['./supplier-mapping.component.scss'],
})

export class SupplierMappingComponent implements OnInit {
	public loader: Loader;
	public errorMsg: string;
	public supplierMappings: any[];
	public suppliers: Supplier[];
	public searchString: string;
	public showSuppliers: boolean;
	public sortAsc: true
	supplierMapping: any;
	countUpdated: number;
	supplierMappingCount: number;
	columnsMap: any[] = [
		{
			label: 'Vendor Id',
			column: 'vendor_id',
			order: 'asc'
		}, {
			label: 'Supplier Code',
			column: 'supplier_code',
			order: 'asc'
		}, {
			label: 'Supplier name',
			column: 'supplier_name',
			order: 'asc'
		}, {
			label: 'Created at',
			column: 'created_at',
			order: 'asc'
		}
	]

	constructor(public snackBar: MatSnackBar,
				private supplierMappingService: SupplierMappingService,
				private supplierService: SupplierService) {
		this.searchString = '';
		this.showSuppliers = false;
		this.countUpdated = 0;
		this.supplierMappingCount = 0;
		this.suppliers = [];
	}

	ngOnInit() {
		this.loader = new Loader();
		this.showSupplierData();
		setTimeout(() => {
				this.syncData();
			},
			5000);
	}

	openSnackBar(message: string) {
		this.snackBar.open(message, '', {
			duration: 4000,
		});
	}

	showSupplierData() {
		this.supplierMappingService.getAll().subscribe(
			res => {
				this.supplierMappings = res;
				this.supplierMappingCount = this.supplierMappings.length;
			}
		);
		this.supplierService.getAll(this.searchString).subscribe(
			res => {
				this.suppliers = res.content;
			}
		)
	}

	syncData() {
		if (this.supplierMappings != null && this.supplierMappings.length > 0) {
			for (let sm in this.supplierMappings) {
				const supplierMappingName: string = this.supplierMappings[sm].supplier_name.replace(/\s/g, '').toLowerCase();
				if (this.suppliers != null && this.suppliers.length > 0) {
					for (let s in this.suppliers) {
						const supplierName: string = this.suppliers[s].supplier_name.replace(/\s/g, '').toLowerCase();
						if (supplierMappingName == supplierName) {
							const supplierIdLink = this.suppliers[s]._links.self.href;
							this.supplierMappings[sm].supplier_id = supplierIdLink.substring(supplierIdLink.lastIndexOf('/') + 1, supplierIdLink.length);
							this.supplierMappingService.update(this.supplierMappings[sm]).subscribe(
								res => {
									this.countUpdated = this.countUpdated + 1;
								}
							)
						}
					}
				}
			}
			this.showSupplierData();
			this.openSnackBar('Updated Supplier Mappings');
		}
	}

	showSuppliersFunc(supMapping: any) {
		console.log('test ', supMapping);
		this.showSuppliers = true;
		this.supplierMapping = supMapping;
		this.searchString = '';
		this.searchSuppliers();
	}

	searchSuppliers() {
		this.supplierService.getAll(this.searchString).subscribe(
			res => {
				this.suppliers = res.content;
				this.suppliers = this.suppliers.sort((a, b) => {
					return a.supplier_name.toLocaleLowerCase().localeCompare(b.supplier_name.toLocaleLowerCase());
				});
			}
		)
	}

	selectSupplier(supplier: any) {
		const supplierIdLink = supplier._links.self.href;
		this.supplierMapping.supplier_id = supplierIdLink.substring(supplierIdLink.lastIndexOf('/') + 1, supplierIdLink.length);
		console.log(this.supplierMapping);
		this.supplierMappingService.update(this.supplierMapping).subscribe(
			res => {
				this.searchString = '';
				this.showSuppliers = false;
				this.showSupplierData();
				this.openSnackBar('Updated Successfully');
			},
			err => {
				this.searchString = '';
				this.showSuppliers = false;
				this.showSupplierData();
				this.openSnackBar('Error while updating');
			}
		)
	}

	sort(colMap: any) {
		if (colMap.order == 'asc') {
			this.supplierMappings.sort((a, b) => a[colMap.column] > b[colMap.column] ? 1 : a[colMap.column] < b[colMap.column] ? -1 : 0)
			colMap.order = 'desc';
		} else {
			this.supplierMappings.sort((a, b) => b[colMap.column] > a[colMap.column] ? 1 : b[colMap.column] < a[colMap.column] ? -1 : 0)
			colMap.order = 'asc';
		}
	}
}
