import {Component, OnInit, ViewChild} from '@angular/core';
import {BrandService} from '../../_services/brand-service';
import {Loader} from '../../_models/loader';
import {Brand} from '../../_models/brand';
import {Subscription} from 'rxjs/Rx';
import {MatPaginator, MatSort, MatTableDataSource, Sort} from '@angular/material';
import {Router} from '@angular/router';

@Component({
	selector: 'app-brand-edit',
	templateUrl: './brand-edit.component.html',
	styleUrls: ['./brand-edit.component.scss'],
})

export class BrandEditComponent implements OnInit {
	noFilter = 'All';
	private _brands: Brand[];
	public _filteredBrands: Brand[];
	public state: any;
	public loader: Loader;
	public errorMsg: string;
	totalResults = 0;
	currentPage = 0;
	pageSize = 10;
	filterState = {
		active: this.noFilter
	};
	activeFilter = ['Active', 'Inactive'];
	pageEvent: Subscription;
	tableData;
	displayedHeader: string[] = ['Brand', 'Active', 'Brand ID', 'Vendor ID', 'Brand Parent ID', 'Partner API Brand Id', 'Action'];
	displayedColumns: string[] = ['name', 'active', 'id', 'vendor_id', 'brand_parent_id', 'partner_api_brand_id', 'action'];
	@ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
	@ViewChild(MatSort, {static: false}) sort: MatSort;

	constructor(private _brandService: BrandService, private router: Router) {
	}

	ngOnInit() {
		this.state = {
			sort: 'matrix_position',
			filter: 'none',
			min: false,
		};
		this.loader = new Loader();
		this.getAllBrands();
	}

	newPage() {
		this.paginator.pageIndex = 0;
		this.currentPage = 0;
		this.getAllBrands();
	}

	public handlePage(e: any) {
		this.currentPage = e.pageIndex;
		this.totalResults = e.length;
		this.pageSize = e.pageSize;
		this.iterator();
	}

	getAllBrands() {
		this.loader.isLoading = true;
		this._brandService.getAll()
			.subscribe(
				res => {
					this._brands = res.filter(b => {
						return !b.archived;
					});
					if (this.filterState.active == 'Active') {
						this._brands = this._brands.filter(b => {
							return b.active;
						})
					} else if (this.filterState.active == 'Inactive') {
						this._brands = this._brands.filter(b => {
							return !b.active;
						})
					}
					this.tableData = new MatTableDataSource(this._brands);
					this.brandSort();
					this.loader.isLoading = false;
					this.totalResults = this._brands.length;
					this.iterator();
					this.tableData.sort = this.sort;
				},
				err => {
					this.errorMsg = err;
					this.loader.isLoading = false;
				});
	}

	private iterator() {
		const end = (this.currentPage + 1) * this.pageSize;
		const start = this.currentPage * this.pageSize;
		const part = this._brands.slice(start, end);
		this.tableData = part;
	}

	public restartCache(): void {
		this.loader.isLoading = true;
		this._brandService.restartServerCache()
			.subscribe(
				res => {
					this.loader.isLoading = false;
				},
				err => {
					this.errorMsg = err;
					this.loader.isLoading = false;
				});
	}

	public brandSort(): void {
		if (this.state.sort == 'name') {
			this._filteredBrands = this._brands.sort((a, b) => {
				return this.state.min ? a[this.state.sort].toLocaleString().localeCompare(b[this.state.sort].toLocaleString()) : b[this.state.sort].toLocaleString().localeCompare(a[this.state.sort].toLocaleString());
			});
		} else {
			this._filteredBrands = this._brands.sort((a, b) => {
				return this.state.min ? a[this.state.sort] - b[this.state.sort] : b[this.state.sort] - a[this.state.sort];
			});
		}

		this.brandFilter(this.state.filter, true);
	}

	public brandFilter(key: string, value?: boolean) {
		if (key === 'none') {
			this._filteredBrands = this._brands;
		} else {
			this._filteredBrands = this._brands.filter(b => {
				return b[key] === value;
			});
		}
	}

	public edit(b: Brand) {
		this.router.navigate([`/admin/brandEdit/edit/${b.id}`]);
	}

	sortData(sort: Sort) {
		const data = this._brands.slice();
		if (!sort.active || sort.direction == '') {
			this._brands = data;
			return;
		}

		this._brands = data.sort((a, b) => {
			let isAsc = sort.direction == 'asc';
			switch (sort.active) {
				case 'name':
					return this.compare(a.name, b.name, isAsc);
				case 'id':
					return this.compare(+a.id, +b.id, isAsc);
				case 'vendor_id':
					return this.compare(+a.vendor_id, +b.vendor_id, isAsc);
				case 'active':
					return this.compare(+a.active, +b.active, isAsc);
				case 'brand_parent_id':
					return this.compare(+a.brand_parent_id, +b.brand_parent_id, isAsc);
				default:
					return 0;
			}
		});
		this.iterator();
	}


	compare(a, b, isAsc) {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}
}



