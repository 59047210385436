import {Component, OnInit, ChangeDetectionStrategy, Input} from '@angular/core';
import {FormBuilder, FormArray, FormGroup, FormControl, Validators} from '@angular/forms';
import {SeoCarFaq, SeoCarView} from '../../_models/seoPage';
import {MatSnackBar} from '@angular/material';
import {SeoCarService} from '../../_services/seo-car-service';

@Component({
	selector: 'app-seo-car-faq-edit',
	templateUrl: './seo-car-faq-edit.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./seo-car-faq-edit.scss']
})

export class SeoCarFaqEditComponent implements OnInit {

	@Input() seoCar: SeoCarView;
	faqs: SeoCarFaq[];
	faqsForm: FormGroup;
	id: string;
	hasFormErrors: boolean;
	backlink = '/admin/seo-car-class';

	constructor(private fb: FormBuilder,
				public snackBar: MatSnackBar,
				private _SeoCarService: SeoCarService) {
	}

	ngOnInit() {
		this.createForm();
	}

	createForm() {
		this.faqsForm = this.fb.group({
			faqs: this.fb.array([])
		});
		this.patch();
	}

	patch() {
		const control = <FormArray>this.faqsForm.get('faqs');
		if (this.seoCar.faqs.length > 0) {
			this.seoCar.faqs.forEach(i => {
				control.push(this.fb.group({
					id: i.id,
					class_id: i.class_id,
					question: [i.question, Validators.required],
					answer: [i.answer, Validators.required],
				}));
			});
		} else {
			this.create();
		}
	}

	onSubmit() {
		if (!this.seoCar.id) {
			this.openSnackBar('Please create SEO car class first.', 'error');
		} else {
			const controls = this.faqsForm.controls;
			this.seoCar.faqs = this.faqsForm.value.faqs;
			if (this.faqsForm.invalid) {
				Object.keys(controls).forEach(controlName =>
					controls[controlName].markAsTouched()
				);
				this.hasFormErrors = true;
				return;
			}

			this._SeoCarService.createOrUpdate(this.seoCar).subscribe({
				next: res => {
					this.openSnackBar('Updated', 'success');
				}, error: err => {
					this.openSnackBar('Server Error', 'error');
					console.log('test', err);
				}
			});
		}
	}

	openSnackBar(message: string, type) {
		this.snackBar.open(message, type, {
			duration: 10000,
		});
	}

	create(): void {
		const control = <FormArray>this.faqsForm.get('faqs');
		control.push(this.fb.group({
			question: ['', Validators.required],
			answer: ['', Validators.required]
		}));
	}

	delete(control): void {
		alert('functionality not ready')
	}
}
