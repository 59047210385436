import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from './app-config.service';

@Injectable()
export class PartnerService {
	private baseUrl: string;

	constructor(private http: HttpClient, private authService: AuthService, private environment: AppConfigService) {
		this.baseUrl = environment.config.apiBaseUrl + '/api/partner';
	}

	getPartners(): Observable<any> {
		return this.http.get<any>(`${this.baseUrl}?size=1000`, this.authService.getOptions());
	}

	getPartnerDetails(id: number) {
		return this.http.get(`${this.baseUrl}/${id}`, this.authService.getOptions())
			.pipe(res => res);
	}

	filterReservations(search: string) {
		return this.http.get<any>(`${this.baseUrl}/search/${search}`, this.authService.getOptions());
	}

	createNewPartner(data: any) {
		return this.http.post<any>(`${this.baseUrl}`, JSON.stringify(data), this.authService.getOptions());
	}

	updatePartner(data: any, id: number) {
		return this.http.put(`${this.environment.config.apiBaseUrl}/api/partner/${id}`, JSON.stringify(data), this.authService.getOptions())
			.pipe(res => res);
	}
}
