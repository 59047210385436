import {Component, Input, OnInit} from '@angular/core';

@Component({
	selector: 'app-seo-location-mapping-tab',
	templateUrl: './seo-location-mapping-tab.component.html'
})
export class SeoLocationMappingTabComponent implements OnInit {

	@Input() locationId: any;
	@Input() locationMappings: any[];
	@Input() showTab: boolean;
	editable: boolean;

	constructor() {
		this.editable = false;
	}

	ngOnInit() {
	}
}
