import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppConfigService} from './app-config.service';

@Injectable()
export class ReservationService {
	private baseUrl: string;

	constructor(private http: HttpClient, private authService: AuthService, private environment: AppConfigService) {
		this.baseUrl = environment.config.apiBaseUrl + '/api/v1/reservations';
	}

	queryReservations(body: any, page): Observable<any> {
		return this.http.post<any>(`${this.baseUrl}/search/${page}/20`, body, this.authService.getOptions());
	}

	getReservations(body: string) {
		return this.http.post<any>(`${this.baseUrl}/search/0/50`, body, this.authService.getOptions());
	}

	getReservationDetailsById(uuid): Observable<any> {
		return this.http.get<any>(`${this.baseUrl}/${uuid}`, this.authService.getOptions());
	}

	cancelReservation(uuid: string) {
		return this.http.get<any>(`${this.baseUrl}/cancel/${uuid}`, this.authService.getOptions());
	}

	cancelReservationArdb(body: any, uuid: string) {
		return this.http.post<any>(`${this.baseUrl}/cancel/${uuid}`, body, this.authService.getOptions());
	}

	refundReservation(uuid: string) {
		return this.http.post<any>(`${this.baseUrl}/refund/${uuid}`, null, this.authService.getOptions());
	}

	exportReservations(body: any, page): Observable<any> {
		return this.http.post<any>(`${this.baseUrl}/all/export`, body, this.authService.getOptions());
	}

	sendConfirmationEmail(uuid: string) {
		return this.http.get<any>(`${this.baseUrl}/send/confirmation/${uuid}`, this.authService.getOptions());
	}

	sendSurveyEmail(uuid: string) {
		return this.http.get<any>(`${this.baseUrl}/send/survey/${uuid}`, this.authService.getOptions());
	}
}

