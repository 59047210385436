import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
	selector: 'app-seo-suppliers-tab',
	templateUrl: './seo-suppliers-tab.component.html'
})
export class SeoSuppliersTabComponent implements OnInit {

	@Input() locationId: any;
	@Input() suppliers: any[];
	@Input() showTab: boolean;
	@Input() popUpShowed: boolean;
	editable: boolean;
	hasChanges: boolean;
	@Output() supplierEditMode: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() supplierUnsavedChanges: EventEmitter<boolean> = new EventEmitter<boolean>();

	constructor() {
		this.editable = false;
		this.hasChanges = false;
	}

	showEditableTabs(supplier: any) {
		supplier.canEdit = true;
		this.supplierEditMode.emit(supplier.canEdit);
	}

	ngOnInit() {
	}

	supplierUnsaved(supplier: any) {
		this.supplierUnsavedChanges.emit(supplier.canEdit);
		this.hasChanges = true;
	}
}

