import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from './app-config.service';

@Injectable()
export class UserService {
	private baseUrl: string;

	constructor(private http: HttpClient, private authService: AuthService, private environment: AppConfigService) {
		this.baseUrl = environment.config.apiBaseUrl + '/api/user';
	}

	subscribeEmails(emails: string[]): Observable<any> {
		const data = {
			emails: emails
		};
		return this.http.put<any>(`${this.baseUrl}/subscribe`, JSON.stringify(data), this.authService.getOptions());
	}

	subscribeExcelEmails(emailObjs: any[]): Observable<any> {
		var emails = [];
		for (var i = 0; i < emailObjs.length; i++) {
			emails.push(emailObjs[i].email)
		}
		const data = {
			emails: emails
		};
		return this.http.put<any>(`${this.baseUrl}/subscribe`, JSON.stringify(data), this.authService.getOptions());
	}

	unSubscribeEmails(emails: string[]): Observable<any> {
		const data = {
			emails: emails
		};
		return this.http.put<any>(`${this.baseUrl}/un-subscribe`, JSON.stringify(data), this.authService.getOptions());
	}

	unSubscribeExcelEmails(emailObjs: any[]): Observable<any> {
		var emails = [];
		for (var i = 0; i < emailObjs.length; i++) {
			emails.push(emailObjs[i].email)
		}
		const data = {
			emails: emails
		};
		return this.http.put<any>(`${this.baseUrl}/un-subscribe`, JSON.stringify(data), this.authService.getOptions());
	}

	getUserById(id: any) {
		return this.http.get<any>(`${this.baseUrl}/${id}`, this.authService.getOptions());
	}
}
