import {map, startWith} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {MatSnackBar} from '@angular/material';
import {CampaignService} from '../../_services/campaign-service';
import {Campaign} from '../../_models/campaign';
import {Util} from '../../_utils/util';
import {AuthService} from '../../_services/auth.service';

@Component({
	selector: 'app-expense-adjustment',
	templateUrl: './expense-adjustment.component.html',
	styleUrls: ['./expense-adjustment.component.scss']
})

export class ExpenseAdjustmentComponent implements OnInit {

	campaigns: Campaign[];
	filteredCampaigns: Observable<Campaign[]>;
	campaignCtrl: FormControl;
	selectedCampaign: Campaign;
	_startDate: Date;
	_endDate: Date;
	errorMsg: string;
	isAdmin: boolean;

	constructor(private campaignService: CampaignService, private util: Util, public snackBar: MatSnackBar,
				public authService: AuthService) {
		this.campaignCtrl = new FormControl();
		this.isAdmin = false;
		this.campaignService.getAll()
			.subscribe(c => {
				this.campaigns = c;
				this.filteredCampaigns = this.campaignCtrl.valueChanges.pipe(
					startWith(null),
					map(input => this.filterCampaigns(input)))
			});
	}

	filterCampaigns(input: string) {
		return input ? this.campaigns.filter(c => c.name.toLowerCase().includes(input.toLowerCase()) || c.id.toString().includes(input))
			: this.campaigns;
	}

	tagFilterCampaigns(input: string) {
		this.campaignCtrl.setValue(input);
	}

	checkForm(startDate: Date, endDate: Date): boolean {
		if (!startDate || !endDate) {
			this.errorMsg = 'Please Select a Start and End Date';
			return false;
		}
		if (endDate < startDate) {
			this.errorMsg = 'End Date Must Be Later Than Start Date';
			return false;
		}
		return true
	}

	openSnackBar(message: string) {
		this.snackBar.open(message, 'No Data', {
			duration: 4000,
		});
	}

	getDetailCampaignInfo(c: Campaign) {
		this.ngOnInit();
		const formValid: boolean = this.checkForm(this._startDate, this._endDate);

		if (formValid) {
			const startDate: string = this.util.dateToIsoString(this._startDate);
			const endDate: string = this.util.dateToIsoString(this._endDate);
			const campaignId: number = c.id;
			this.campaignService.getDetailedCampaignData(startDate, endDate, campaignId).subscribe(
				res => {
					this.selectedCampaign = res;
					this.selectedCampaign.name = c.name;
					this.selectedCampaign.id = c.id;
					this.selectedCampaign.currentEndDate = endDate;
					this.selectedCampaign.currentStartDate = startDate;
				},
				err => {
					this.openSnackBar(`No session data found for ${c.name}`);
				});
		}
	}

	ngOnInit() {
		this.errorMsg = '';
		this.isAdmin = this.authService.isAdmin();
	}
}

