import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AppConfigService} from './app-config.service';

@Injectable()
export class EmailService {
	private token = this.authService.getToken();
	private baseUrl: string;


	constructor(private http: HttpClient, private authService: AuthService, private environment: AppConfigService) {
		this.baseUrl = environment.config.apiBaseUrl;
	}

	findUserIdByEmail(emailString: string): Observable<any> {
		const endpoint = `${this.environment.config.apiBaseUrl}/api/user/find_by_email?email=`;

		return this.http.get<any>(endpoint + emailString, this.authService.getOptions());
	}

	changeEmail(email: string, userId: number): Observable<string> {
		const endpoint = `${this.environment.config.apiBaseUrl}/api/user/${userId}/email`;

		return this.http.put(endpoint, email, {
			headers:
				new HttpHeaders({'content-type': 'application/json', 'Authorization': this.token}), responseType: 'text'
		});
	}
}

